import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {  withStyles  } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { TextField, Fade, InputAdornment } from '@material-ui/core';

const styles = theme => ({
    root: {
        paddingRight: theme.spacing.unit
    },
    highlight: theme.palette.type === 'light' ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
    } : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
    },
    spacer: {
        flex: 1,
    },
    actions: {
        color: theme.palette.text.secondary,
        display: 'inherit'
    },
    toolbar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    title: {
        flex: '0 0 auto',
    }
})

class DataBrowserToolbar extends Component {
    state = {
        isSearching: false
    }

    handleClick = (event, action) => {
        const { onActionButtonClick } = this.props;

        (onActionButtonClick) && onActionButtonClick(event, action)
    }

    handleSearchClick = event => {
        const { isSearching } = this.state;

        this.setState({ isSearching: !isSearching })
    }

    // handleSearchBlur = event => {
    //     this.setState({ isSearching: false })   
    // }

    handleSearchChange = event => {
        const { onRequestSearch } = this.props;
        const criteria = event.target.value;

        this.setState({ criteria })
        if (onRequestSearch) {
            onRequestSearch(criteria)
        }
    }

    render() {
        const { classes, title, selected, actions, onAddButtonClick, onRequestSearch } = this.props;
        const { isSearching } = this.state;
        const numSelected = selected.length;

        return (
            <Toolbar
                className={classNames(classes.root, {
                    [classes.highlight]: numSelected > 0,
                })}
            >
                <div className={classes.title}>
                    {numSelected > 0 ? (
                    <Typography color="inherit" variant="subtitle2">
                        {numSelected} selected
                    </Typography>
                    ) : (
                    <Typography id="tableTitle" variant="h6">
                        {title}
                    </Typography>
                    )}
                </div>
                <div className={classes.spacer} />
                <div className={classes.actions}>
                    {numSelected > 0 ? (
                        actions.map((action, index) => (
                            <Tooltip key={index} title={action.label}>
                                <div>
                                    <IconButton 
                                        aria-label={action.label} 
                                        disabled={!!(action.disabled && action.disabled(selected))} 
                                        // onClick={event => action.handleClick && action.handleClick(event, selected)}
                                        onClick={event => this.handleClick(event, action)}
                                    >
                                        <Icon>{action.icon}</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        ))
                    ) : (
                        <div className={classes.toolbar}>
                            <Fade direction="left" in={isSearching} mountOnEnter unmountOnExit>
                                <TextField
                                    type="search"
                                    style={{ width: 300 }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><Icon>search</Icon></InputAdornment>,
                                    }}
                                    // onBlur={this.handleSearchBlur}
                                    onChange={this.handleSearchChange}
                                />
                            </Fade>
                            <Toolbar>
                                {(onRequestSearch) && (
                                    <Tooltip title="Search">
                                        <IconButton aria-label="Search" onClick={event => this.handleSearchClick(event)}>
                                            <Icon>filter_list</Icon>
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {(onAddButtonClick) && (
                                    <Tooltip title="New">
                                        <IconButton aria-label="New" onClick={onAddButtonClick}>
                                            <Icon>add</Icon>
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Toolbar>
                        </div>
                    )}
                </div>
            </Toolbar>
        )
    }
}
  
DataBrowserToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,   
    selected: PropTypes.arrayOf(PropTypes.string).isRequired,
    actions: PropTypes.arrayOf(PropTypes.object),
    onActionButtonClick: PropTypes.func,
    onAddButtonClick: PropTypes.func,
    onRequestSearch: PropTypes.func    
}

export default withStyles(styles)(DataBrowserToolbar);