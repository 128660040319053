import rpc from 'services/rpc';

export const REQUEST_FETCH_PROFILES  = 'REQUEST_FECTH_PROFILES';
export const RESPONSE_FETCH_PROFILES_SUCCESS = 'RESPONSE_FETCH_PROFILES_SUCCESS';
export const RESPONSE_FETCH_PROFILES_FAILURE = 'RESPONSE_FETCH_PROFILES_FAILURE';
export const REQUEST_CREATE_PROFILE  = 'REQUEST_CREATE_PROFILE';
export const RESPONSE_CREATE_PROFILE_SUCCESS = 'RESPONSE_CREATE_PROFILE_SUCCESS';
export const RESPONSE_CREATE_PROFILE_FAILURE = 'RESPONSE_CREATE_PROFILE_FAILURE';
export const REQUEST_UPDATE_PROFILE  = 'REQUEST_UPDATE_PROFILE';
export const RESPONSE_UPDATE_PROFILE_SUCCESS = 'RESPONSE_UPDATE_PROFILE_SUCCESS';
export const RESPONSE_UPDATE_PROFILE_FAILURE = 'RESPONSE_UPDATE_PROFILE_FAILURE';
export const REQUEST_DELETE_PROFILE  = 'REQUEST_DELETE_PROFILE';
export const RESPONSE_DELETE_PROFILE_SUCCESS = 'RESPONSE_DELETE_PROFILE_SUCCESS';
export const RESPONSE_DELETE_PROFILE_FAILURE = 'RESPONSE_DELETE_PROFILE_FAILURE';

const CREATE_PROFILE_SUCCESS = 'Yay! Profile was created successfully';
const UPDATE_PROFILE_SUCCESS = 'Yay! Profile was updated successfully';
const DELETE_PROFILE_SUCCESS = 'Yay! Profile was deleted successfully';

function requestFetch() {
    return {
        type: REQUEST_FETCH_PROFILES
    }
};

function responseFetchSuccess(json) {
    return {
        type: RESPONSE_FETCH_PROFILES_SUCCESS,
        profiles: json,
        receivedAt: Date.now()
    };
};

function responseFetchFailure(error) {
    return {
        type: RESPONSE_FETCH_PROFILES_FAILURE,
        error,
        receivedAt: Date.now()
    };
};

function requestCreate() {
    return {
        type: REQUEST_CREATE_PROFILE
    }
};

function responseCreateSuccess(json) {
    return {
        type: RESPONSE_CREATE_PROFILE_SUCCESS,
        profile: json,
        success: {
            message: CREATE_PROFILE_SUCCESS
        },
        receivedAt: Date.now()
    };
};

function responseCreateFailure(error) {
    return {
        type: RESPONSE_CREATE_PROFILE_FAILURE,
        error,
        receivedAt: Date.now()
    };
};

function requestUpdate() {
    return {
        type: REQUEST_UPDATE_PROFILE
    }
};

function responseUpdateSuccess(json) {
    return {
        type: RESPONSE_UPDATE_PROFILE_SUCCESS,
        profile: json,
        success: {
            message: UPDATE_PROFILE_SUCCESS
        },
        receivedAt: Date.now()
    };
};

function responseUpdateFailure(error) {
    return {
        type: RESPONSE_UPDATE_PROFILE_FAILURE,
        error,
        receivedAt: Date.now()
    };
};

function requestDelete() {
    return {
        type: REQUEST_DELETE_PROFILE
    }
};

function responseDeleteSuccess(json) {
    return {
        type: RESPONSE_DELETE_PROFILE_SUCCESS,
        profiles: json,
        success: {
            message: DELETE_PROFILE_SUCCESS
        },
        receivedAt: Date.now()
    };
};

function responseDeleteFailure(error) {
    return {
        type: RESPONSE_DELETE_PROFILE_FAILURE,
        error,
        receivedAt: Date.now()
    };
};

// Do not use catch, because that will also catch
// any errors in the dispatch and resulting render,
// causing a loop of 'Unexpected batch number' errors.
// https://github.com/facebook/react/issues/6895

export function fetchProfiles() {
    return function(dispatch) {
        dispatch(requestFetch());

        return rpc.get('profiles')
        .then(response => {
            const { data, error } = response.result()

            if (error) {
                dispatch(responseFetchFailure(error));
            }
            else {
                dispatch(responseFetchSuccess(data));
            }
        }, e => dispatch(responseFetchFailure({
            message: e.stderr ? e.stderr : e.message
        })))
    }
};

export function createProfile(profile) {
    return function(dispatch) {
        dispatch(requestCreate());

        return rpc.post('profiles', profile)
        .then(response => {
            const { data, error } = response.result()

            if (error) {
                dispatch(responseCreateFailure(error));
            }
            else {
                dispatch(responseCreateSuccess(data));
            }
        }, e => dispatch(responseCreateFailure({
            message: e.stderr ? e.stderr : e.message
        })))
    }
};

export function updateProfile(profile) {
    return function(dispatch) {
        dispatch(requestUpdate());

        return rpc.put('profiles', profile)
        .then(response => {
            const { data, error } = response.result()

            if (error) {
                dispatch(responseUpdateFailure(error));
            }
            else {
                dispatch(responseUpdateSuccess(data));
            }
        }, e => dispatch(responseUpdateFailure({
            message: e.stderr ? e.stderr : e.message
        })))
    }
};

export function deleteProfile(profiles) {
    return function(dispatch) {
        dispatch(requestDelete());

        return rpc.delete('profiles', profiles)
        .then(response => {
            const { data, error } = response.result()

            if (error) {
                dispatch(responseDeleteFailure(error));
            }
            else {
                dispatch(responseDeleteSuccess(data));
            }
        }, e => dispatch(responseDeleteFailure({
            message: e.stderr ? e.stderr : e.message
        })))
    }
};