import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {  withStyles  } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@material-ui/core';

import FileField from "components/core/FileField";

const styles = theme => ({
    dialog: {
        margin: 'auto'
    },
    dialogPaper: {
        width: '40%'
    },
    dialogActions: {
        paddingTop: theme.spacing.unit * 3,
        paddingLeft: theme.spacing.unit * 1.5,
        paddingRight: theme.spacing.unit * 1.5
    },
})

const InputLabelProps = { 
    required: false //Hide asterix on required field
}

class ProjectForm extends Component {
    state = {
        project: {}
    }

    initialState() {
        const { project } = this.props;
        
        this.setState({
            project: (project) ? {...project} : {}
        })
    }

    componentDidMount() {
        this.initialState()
    }

    componentDidValidate() {
        const { project } = this.state;

        return (project && !!project.name && !!project.asset)
    }

    handleChange = (e) => {
        const { id, value } = e.target;
        const { project } = this.state;
        
        project[id] = value;
        this.setState({ project });
    }

    handleSave = (e) => {
        const { onSave } = this.props;
        const { project } = this.state;
        
        e.preventDefault()
        if (!this.componentDidValidate()) return
        onSave(project)
    }
    render() {
        const { classes, open, onClose } = this.props;
        const { project } = this.state;
        const isNew = (project && !project._id);

        return (
            <Dialog
                classes={{
                    root: classes.dialog,
                    paper: classes.dialogPaper
                }}
                open={open}
                onClose={onClose}
                aria-labelledby="form-dialog-title"
            >
                <form autoComplete="off" onSubmit={this.handleSave}>
                    <DialogTitle>
                        {isNew ?  "New Project" : "Project"}
                    </DialogTitle>
                    <DialogContent>
                        <TextField 
                            InputLabelProps={InputLabelProps}
                            autoComplete="off"
                            autoFocus 
                            margin="dense" 
                            id="name" 
                            label="Name"
                            defaultValue={project.name} 
                            fullWidth
                            onChange={this.handleChange}
                            required={true}
                        />
                        <TextField 
                            InputLabelProps={InputLabelProps}
                            autoComplete="off"
                            margin="dense" 
                            id="description" 
                            label="Description"
                            defaultValue={project.description} 
                            fullWidth
                            onChange={this.handleChange}
                        />
                        <FileField
                            id="asset"
                            text="Choose IPA"
                            defaultValue={project.asset}
                            fullWidth
                            onChange={this.handleChange}
                        />
                        <DialogActions>
                            <Button color="primary" onClick={onClose}>
                                Cancel
                            </Button>
                            <Button type="submit" variant="contained" color="primary">
                                Save
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </form>
            </Dialog>
        )
    }
}

ProjectForm.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    project: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired
}

export default withStyles(styles)(ProjectForm)

// import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import {  withStyles  } from '@material-ui/core/styles';

// import Dialog from '@material-ui/core/Dialog';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
// import Button from '@material-ui/core/Button';
// import Badge from '@material-ui/core/Badge';

// import ProjectInfoSection from './ProjectInfoSection';
// import ProjectPlistSection from './ProjectPlistSection';
// import ProjectSettingSection from './ProjectSettingSection';
// import ProjectProfileSection from './ProjectProfileSection';
// import ProjectSigningSection from './ProjectSigningSection';

// import { PROFILES, capitalize } from './locale';

// const styles = theme => ({
//     dialog: {
//         margin: 'auto'
//     },
//     dialogPaper: {
//         minWidth: '68%'
//     },
//     dialogActions: {
//         paddingTop: theme.spacing.unit * 3,
//         paddingLeft: theme.spacing.unit * 1.5,
//         paddingRight: theme.spacing.unit * 1.5
//     },
//     name: {
//         paddingTop: theme.spacing.unit,
//         paddingBottom: theme.spacing.unit
//     },
//     item: {
//         paddingTop: theme.spacing.unit*3,
//         paddingBottom: theme.spacing.unit*3,
//         paddingLeft: theme.spacing.unit,
//         paddingRight: theme.spacing.unit
//     },
//     badge: {
//         top: -5,
//         right: -30
//     },
//     emptyText: {
//         textAlign: 'center'
//     },
//     textField: {
//         marginLeft: theme.spacing.unit,
//         marginRight: theme.spacing.unit,
//     },
//     button: {
//         margin: theme.spacing.unit
//     }
// });

// class ProjectForm extends Component {

//     state = {
//         tabIndex: 0,
//         invalidTabs: [],
//         project: {
//             name: '',
//             ipa: '',
//             props: {},
//             settings: [],
//             profiles: [],
//             signing: {
//                 mobileprovision: '',
//                 certificate: ''
//             }
//         }
//     }

//     steps = ['General', 'Info Plist', 'Settings Bundle', capitalize(PROFILES), 'Signing']
//     validator = [ProjectInfoSection.validate, ProjectPlistSection.validate, ProjectSettingSection.validate, ProjectProfileSection.validate, ProjectSigningSection.validate]

//     asObjectArray = value => ({...value})

//     initialState() {
//         const { project } = this.props;

//         this.setState({ 
//             tabIndex: 0
//         });
        
//         this.setState({
//             project: {...project, 
//                 props: {...project.props},
//                 settings: project.settings.map(this.asObjectArray),
//                 profiles: [...project.profiles],
//                 signing: {...project.signing}
//             }
//         })
//     }

//     componentDidMount() {
//         this.initialState();
//     }

//     componentWillReceiveProps(nextProps) {
//         const { archiveInfo } = this.props;
//         const nextArchiveInfo = nextProps.archiveInfo

//         if (archiveInfo !== nextArchiveInfo) {
//             const { project } = this.state;
//             const props = (nextArchiveInfo && nextArchiveInfo.infoplist) ? nextArchiveInfo.infoplist : {};
//             const settings = (nextArchiveInfo && nextArchiveInfo.bundlesettings) ? nextArchiveInfo.bundlesettings : [];

//             project.props = {...props};
//             project.settings = settings.map(this.asObjectArray);

//             this.setState({ 
//                 project
//             });
//         }
//     }

//     componentWillValidate() {
//         const { project } = this.state;  
//         const invalid = this.validator.reduce((invalid, validate, index) => {
//             if (!validate(project)) {
//                 invalid.push(index)
//             }
//             return invalid
//         }, []);

//         this.setState({ invalidTabs: invalid });

//         return (invalid.length === 0);
//     }

//     handleTabChange = (e, tabIndex) => {
//         this.setState({ tabIndex });
//     }

//     handleSave = (e) => {
//         const { onSave } = this.props;
//         const { project } = this.state;
        
//         e.preventDefault();
//         if (!this.componentWillValidate()) return;

//         onSave(project);
//     }

//     handleBuild = () => {
//         // const { onBuild } = this.props;
//         // const { project } = this.state;
        
//         // if (!this.componentWillValidate()) return;

//         // onBuild(project);
//     }
    
//     handleChange = (e) => {
//         const { id, value } = e.target;
//         const { project } = this.state;
        
//         project[id] = value;
//         this.setState({ project });
//     }

//     handleFileChange = (e) => {
//         const { onArchiveInfo } = this.props; 
//         const { id, value } = e.target;
//         const { project } = this.state;

//         project[id] = value;
//         this.setState({ project });
//         onArchiveInfo(value);
//     }

//     handlePlistChange = (e) => {
//         const { id, value } = e.target;
//         const { project } = this.state;
        
//         project.props[id] = value;
//         this.setState({ project });  
//     }

//     handleSettingChange = (e) => {
//         const { id, value } = e.target;
//         const { project } = this.state;
//         let { settings } = project;
//         let setting = settings.find(setting => setting.key === id);

//         if (setting) {
//             setting.value = value;
//         }
//         this.setState({ project });
//     }

//     handleProfileToggle = (e) => {
//         const { value, checked } = e.target;
//         const { project } = this.state;
//         let { profiles } = project;

//         if (checked) {
//             const profile = (this.props.profiles || []).find(p => p._id === value);

//             if (profile) {
//                 profiles.push(profile);
//             }
//         }
//         else {
//             profiles.splice(profiles.findIndex(p => p._id === value), 1);
//         }
//         this.setState({ project });
//     }

//     handleSigningChange = (e) => {
//         const { id, name, value } = e.target;
//         const { project } = this.state;

//         project.signing[id || name] = value;
//         this.setState({ project });
//     }

//     render() {
//         const { classes, open, profiles, mobileprovisions, lastMobileProvisionId, certificates, onClose, onMobileProvision } = this.props;
//         const { tabIndex, invalidTabs, project } = this.state;
//         const isNew = !project._id;

//         return (
//             <Dialog
//                 classes={{
//                     root: classes.dialog,
//                     paper: classes.dialogPaper
//                 }}
//                 open={open}
//                 onClose={onClose}
//                 aria-labelledby="form-dialog-title">
//                 <form autoComplete="off" onSubmit={this.handleSave}>
//                     <DialogTitle id="form-dialog-title">
//                         {isNew ?  "New Project" : "Project"}
//                     </DialogTitle>
//                     <DialogContent>
//                         <Tabs value={tabIndex} 
//                             indicatorColor="primary"
//                             centered
//                             fullWidth
//                             onChange={this.handleTabChange}
//                         >
//                             {this.steps.map((label, index) => {
//                                 return (invalidTabs.indexOf(index) === -1) ? (
//                                     <Tab key={index} value={index} label={label} />
//                                 ) : (
//                                     <Tab
//                                         key={index} value={index}
//                                         label={
//                                             <Badge classes={{ badge: classes.badge }} color="error" badgeContent="!">
//                                                 {label}
//                                             </Badge>
//                                         }
//                                     />
//                                 )
//                             })}
//                         </Tabs>
//                         {tabIndex === 0 && (
//                             <ProjectInfoSection
//                                 classes={{
//                                     item: classes.item
//                                 }}
//                                 project={project}
//                                 onFieldChange={this.handleChange}
//                                 onFileChange={this.handleFileChange}
//                             />   
//                         )}
//                         {tabIndex === 1 && (
//                             <ProjectPlistSection
//                                 classes={{
//                                     item: classes.item
//                                 }}
//                                 project={project}
//                                 onFieldChange={this.handlePlistChange}
//                             />   
//                         )}
//                         {tabIndex === 2 && (
//                             <ProjectSettingSection
//                                 classes={{
//                                     item: classes.item,
//                                     emptyText: classes.emptyText
//                                 }}
//                                 project={project}
//                                 onFieldChange={this.handleSettingChange}
//                             />   
//                         )}
//                         {tabIndex === 3 && (
//                             <ProjectProfileSection
//                                 classes={{
//                                     item: classes.item,
//                                     emptyText: classes.emptyText
//                                 }}
//                                 project={this.state.project}
//                                 profiles={profiles}
//                                 onFieldToggle={this.handleProfileToggle}
//                             />
//                         )}
//                         {tabIndex === 4 && (
//                             <ProjectSigningSection
//                                 classes={{
//                                     item: classes.item,
//                                     formControl: classes.formControl
//                                 }}
//                                 project={this.state.project}
//                                 mobileprovisions={mobileprovisions}
//                                 lastMobileProvisionId={lastMobileProvisionId}
//                                 certificates={certificates}
//                                 onFieldChange={this.handleSigningChange}
//                                 onMobileProvision={onMobileProvision}
//                             />
//                         )}
//                         <DialogActions className={classes.dialogActions}>
//                             <Button color="primary" onClick={onClose}>
//                                 Cancel
//                             </Button>
//                             <Button type="submit" color="primary">
//                                 OK
//                             </Button>
//                         </DialogActions>
//                     </DialogContent>
//                 </form>
//             </Dialog>
//         );
//     }
// };

// ProjectForm.propTypes = {
//     classes: PropTypes.object.isRequired,
//     open: PropTypes.bool.isRequired,
//     project: PropTypes.object.isRequired,
//     profiles: PropTypes.arrayOf(
//         PropTypes.object.isRequired
//     ).isRequired,
//     mobileprovisions: PropTypes.arrayOf(
//         PropTypes.object.isRequired
//     ).isRequired,
//     lastMobileProvisionId: PropTypes.string,
//     certificates: PropTypes.arrayOf(
//         PropTypes.object.isRequired
//     ).isRequired,
//     archiveInfo: PropTypes.object,
//     onArchiveInfo: PropTypes.func.isRequired,
//     onMobileProvision: PropTypes.func.isRequired,
//     onSave: PropTypes.func.isRequired,
//     onBuild: PropTypes.func.isRequired,
//     onClose: PropTypes.func.isRequired
// };

// export default withStyles(styles)(ProjectForm);