import * as Types from 'constants/ActionTypes';

const projects = (state = {
    inProgress: false,
    didInvalidate: false,
    data: []
}, action) => {
    switch (action.type) {
        case Types.REQUEST_FETCH_PROJECTS:
        case Types.REQUEST_CREATE_PROJECT:
        case Types.REQUEST_UPDATE_PROJECT:
        case Types.REQUEST_DELETE_PROJECT:
            return Object.assign({}, state, {
                inProgress: true,
                didInvalidate: false
            });
        case Types.RESPONSE_FETCH_PROJECTS_SUCCESS:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: action.projects,
                lastUpdated: action.receivedAt
            });
        case Types.RESPONSE_CREATE_PROJECT_SUCCESS:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: [...state.data, action.project],
                lastUpdated: action.receivedAt
            });
        case Types.RESPONSE_FETCH_PROJECT_SUCCESS:
            state.data = state.data.map(project => (project._id === action.project._id ? action.project : project))
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: [...state.data],
                lastUpdated: action.receivedAt
            });
        case Types.RESPONSE_UPDATE_PROJECT_SUCCESS:
            state.data = state.data.map(project => (project._id === action.project._id ? action.project : project))
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: [...state.data],
                lastUpdated: action.receivedAt
            });
        case Types.RESPONSE_DELETE_PROJECT_SUCCESS:
            action.projects.forEach(project => state.data.splice(state.data.findIndex(p => (p._id === project._id)), 1))
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: [...state.data],
                lastUpdated: action.receivedAt
            });
        case Types.RESPONSE_FETCH_PROJECTS_FAILURE:
        case Types.RESPONSE_CREATE_PROJECT_FAILURE:
        case Types.RESPONSE_FETCH_PROJECT_FAILURE:
        case Types.RESPONSE_UPDATE_PROJECT_FAILURE:
        case Types.RESPONSE_DELETE_PROJECT_FAILURE:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                lastUpdated: action.receivedAt
            });
        default:
            return state
    }
};

export default projects;