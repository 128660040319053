import React, { Component } from 'react';
import {  Switch, Link  } from 'react-router-dom';
import PropTypes from 'prop-types';
import {  withStyles  } from '@material-ui/core/styles';

import AuthRoute from 'components/AuthRoute';

import Projects from 'containers/Projects';
import Project from 'containers/Project';

const styles = theme => ({
})

class ProjectLayout extends Component {
    render() {
        return (
            <Switch>
                <AuthRoute exact path="/project" component={Projects} />
                <AuthRoute path="/project/:id/:prop(stage|build)/:item?" component={Project} />
            </Switch>
        )
    }
}

ProjectLayout.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ProjectLayout);