import rpc from 'services/rpc';
import * as Types from 'constants/ActionTypes';

const CREATE_MOBILE_PROVISION_SUCCESS = 'Yay! Provisioning Profile was imported successfully';
const DELETE_MOBILE_PROVISION_SUCCESS = 'Yay! Provisioning Profile(s) was deleted successfully';

function requestFetch() {
    return {
        type: Types.REQUEST_FETCH_MOBILE_PROVISIONS
    }
};

function responseFetchSuccess(json) {
    return {
        type: Types.RESPONSE_FETCH_MOBILE_PROVISIONS_SUCCESS,
        mobileprovisions: json,
        receivedAt: Date.now()
    };
};

function responseFetchFailure(error) {
    return {
        type: Types.RESPONSE_FETCH_MOBILE_PROVISIONS_FAILURE,
        error,
        receivedAt: Date.now()
    };
};

function requestCreate() {
    return {
        type: Types.REQUEST_CREATE_MOBILE_PROVISION
    }
};

function responseCreateSuccess(json) {
    return {
        type: Types.RESPONSE_CREATE_MOBILE_PROVISION_SUCCESS,
        mobileprovision: json,
        // success: {
        //     message: CREATE_MOBILE_PROVISION_SUCCESS
        // },
        receivedAt: Date.now()
    };
};

function responseCreateFailure(error) {
    return {
        type: Types.RESPONSE_CREATE_MOBILE_PROVISION_FAILURE,
        error,
        receivedAt: Date.now()
    };
};

function requestDelete() {
    return {
        type: Types.REQUEST_DELETE_MOBILE_PROVISION
    }
};

function responseDeleteSuccess(json) {
    return {
        type: Types.RESPONSE_DELETE_MOBILE_PROVISION_SUCCESS,
        mobileprovisions: json,
        success: {
            message: DELETE_MOBILE_PROVISION_SUCCESS
        },
        receivedAt: Date.now()
    };
};

function responseDeleteFailure(error) {
    return {
        type: Types.RESPONSE_DELETE_MOBILE_PROVISION_FAILURE,
        error,
        receivedAt: Date.now()
    };
};

// Do not use catch, because that will also catch
// any errors in the dispatch and resulting render,
// causing a loop of 'Unexpected batch number' errors.
// https://github.com/facebook/react/issues/6895

export function fetchMobileProvisions() {
    return function(dispatch) {
        dispatch(requestFetch());

        return rpc.get('mobileprovisions')
        .then(response => {
            const { data, error } = response.result()

            if (error) {
                dispatch(responseFetchFailure(error));
            }
            else {
                dispatch(responseFetchSuccess(data));
            }
        }, e => dispatch(responseFetchFailure({
            message: e.stderr ? e.stderr : e.message
        })))
    }
};

export function createMobileProvision(file) {
    return function(dispatch) {
        dispatch(requestCreate());

        return rpc.post('mobileprovisions', file)
        .then(response => {
            const { data, error } = response.result()

            if (error) {
                dispatch(responseCreateFailure(error));
            }
            else {
                dispatch(responseCreateSuccess(data));
            }
        }, e => dispatch(responseCreateFailure({
            message: e.stderr ? e.stderr : e.message
        })))
    }
};

export function deleteMobileProvision(mobileprovisions) {
    return function(dispatch) {
        dispatch(requestDelete());

        return rpc.delete('mobileprovisions', mobileprovisions)
        .then(response => {
            const { data, error } = response.result()

            if (error) {
                dispatch(responseDeleteFailure(error));
            }
            else {
                dispatch(responseDeleteSuccess(data));
            }
        }, e => dispatch(responseDeleteFailure({
            message: e.stderr ? e.stderr : e.message
        })))
    }
};