import rpc from 'services/rpc';
import * as Types from 'constants/ActionTypes';

const CREATE_STAGE_SUCCESS = 'Yay! Stage was created successfully';
const UPDATE_STAGE_SUCCESS = 'Yay! Stage was updated successfully';
const DELETE_STAGE_SUCCESS = 'Yay! Stage(s) was deleted successfully';

function requestFetch() {
    return {
        type: Types.REQUEST_FETCH_STAGES
    }
};

function responseFetchSuccess(json) {
    return {
        type: Types.RESPONSE_FETCH_STAGES_SUCCESS,
        stages: json,
        receivedAt: Date.now()
    };
};

function responseFetchFailure(error) {
    return {
        type: Types.RESPONSE_FETCH_STAGES_FAILURE,
        error,
        receivedAt: Date.now()
    };
};

function requestCreate() {
    return {
        type: Types.REQUEST_CREATE_STAGE
    }
};

function responseCreateSuccess(json) {
    return {
        type: Types.RESPONSE_CREATE_STAGE_SUCCESS,
        stage: json,
        success: {
            message: CREATE_STAGE_SUCCESS
        },
        receivedAt: Date.now()
    };
};

function responseCreateFailure(error) {
    return {
        type: Types.RESPONSE_CREATE_STAGE_FAILURE,
        error,
        receivedAt: Date.now()
    };
};

function requestUpdate() {
    return {
        type: Types.REQUEST_UPDATE_STAGE
    }
};

function responseUpdateSuccess(json) {
    return {
        type: Types.RESPONSE_UPDATE_STAGE_SUCCESS,
        stage: json,
        success: {
            message: UPDATE_STAGE_SUCCESS
        },
        receivedAt: Date.now()
    };
};

function responseUpdateFailure(error) {
    return {
        type: Types.RESPONSE_UPDATE_STAGE_FAILURE,
        error,
        receivedAt: Date.now()
    };
};

function requestDelete() {
    return {
        type: Types.REQUEST_DELETE_STAGE
    }
};

function responseDeleteSuccess(json) {
    return {
        type: Types.RESPONSE_DELETE_STAGE_SUCCESS,
        stages: json,
        success: {
            message: DELETE_STAGE_SUCCESS
        },
        receivedAt: Date.now()
    };
};

function responseDeleteFailure(error) {
    return {
        type: Types.RESPONSE_DELETE_STAGE_FAILURE,
        error,
        receivedAt: Date.now()
    };
};

// Do not use catch, because that will also catch
// any errors in the dispatch and resulting render,
// causing a loop of 'Unexpected batch number' errors.
// https://github.com/facebook/react/issues/6895

export function fetchStages() {
    return function(dispatch) {
        dispatch(requestFetch());

        return rpc.get('stages')
        .then(response => {
            const { data, error } = response.result()

            if (error) {
                dispatch(responseFetchFailure(error));
            }
            else {
                dispatch(responseFetchSuccess(data));
            }
        }, e => dispatch(responseFetchFailure({
            message: e.stderr ? e.stderr : e.message
        })))
    }
};

export function createStage(stage) {
    return function(dispatch) {
        dispatch(requestCreate());

        return rpc.post('stages', stage)
        .then(response => {
            const { data, error } = response.result()

            if (error) {
                dispatch(responseCreateFailure(error));
            }
            else {
                dispatch(responseCreateSuccess(data));
            }
        }, e => dispatch(responseCreateFailure({
            message: e.stderr ? e.stderr : e.message
        })))
    }
};

export function updateStage(stage) {
    return function(dispatch) {
        dispatch(requestUpdate());

        return rpc.put('stages', stage)
        .then(response => {
            const { data, error } = response.result()

            if (error) {
                dispatch(responseUpdateFailure(error));
            }
            else {
                dispatch(responseUpdateSuccess(data));
            }
        }, e => dispatch(responseUpdateFailure({
            message: e.stderr ? e.stderr : e.message
        })))
    }
};

export function deleteStage(stages) {
    return function(dispatch) {
        dispatch(requestDelete());

        return rpc.delete('stages', stages)
        .then(response => {
            const { data, error } = response.result()

            if (error) {
                dispatch(responseDeleteFailure(error));
            }
            else {
                dispatch(responseDeleteSuccess(data));
            }
        }, e => dispatch(responseDeleteFailure({
            message: e.stderr ? e.stderr : e.message
        })))
    }
};