import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {  withStyles  } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Typography from '@material-ui/core/Typography';
// import Avatar from '@material-ui/core/Avatar';
// import Icon from '@material-ui/core/Icon';

// import Card from '@material-ui/core/Card';
// import CardHeader from '@material-ui/core/CardHeader';
// import CardMedia from '@material-ui/core/CardMedia';
// import CardContent from '@material-ui/core/CardContent';
// import CardActions from '@material-ui/core/CardActions';

// import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';

// import FileField from './FileField';
import AttachmentListItem from './AttachmentListItem';

const styles = theme => ({
    flex: {
        flex: 1
    },
    dialog: {
        margin: 'auto',
    },
    dialogPaper: {
        minHeight: '35%',
        minWidth: '30%'  
    },
    projectTab: {
        paddingLeft: theme.spacing.unit * 1.5,
        paddingRight: theme.spacing.unit * 1.5
    },
    list: {
        overflowY: 'auto',
        width: '100%'
    },
    dialogActions: {
        paddingLeft: theme.spacing.unit * 1.5,
        paddingRight: theme.spacing.unit * 1.5
    },
    button: {
        margin: theme.spacing.unit
    }
});

class ArchiveInfo extends Component {

    state = {
        tabIndex: 0,
        archive: {
            project: {}
        }
    }

    initialState() {
        const { archive } = this.props;

        this.setState({ 
            archive: {...archive}
        });   
    }

    componentDidMount() {
        this.initialState();
    }

    handleTabChange = (event, tabIndex) => {
        this.setState({ tabIndex });
    }

    handleAttachmentDownload = (attachment) => {
        const { onAttachmentDownload } = this.props;

        onAttachmentDownload(attachment)
    }

    handleDelete = () => {
        const { onDelete } = this.props;
        const { archive } = this.state;

        onDelete(archive)
    }

    handleDownload = () => {
        const { onDownload } = this.props;
        const { archive } = this.state;

        onDownload(archive)
    }

    render() {
        const { classes, open, onClose } = this.props;
        const { archive, tabIndex } = this.state;

        return (
            <Dialog
                classes={{
                    root: classes.dialog,
                    paper: classes.dialogPaper
                }}
                open={open}
                onClose={onClose}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    Archive
                </DialogTitle>
                <DialogContent>
                    <Tabs
                        centered
                        value={tabIndex}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={this.handleTabChange}>
                        <Tab label="Project" />
                        <Tab label="Assets" />
                    </Tabs>
                    {(tabIndex === 0) ? (
                        <div style={{ padding: '44px 24px' }}>
                            <Typography variant="subheading" component="p">{archive.project.name}</Typography>
                            <Typography variant="body1" color="textSecondary" component="p">{(new Date(archive.project.createdAt)).toString()}</Typography>  
                        </div>
                    ) : null}
                    {(tabIndex === 1) ? (
                        <div style={{ padding: '24px 0px' }}>
                            {(archive.attachments) ? (
                                <List className={classes.list}>
                                    {archive.attachments.map((attachment, index) => 
                                        <AttachmentListItem 
                                            key={index} 
                                            {...attachment}
                                            onDownload={() => this.handleAttachmentDownload(attachment)}
                                        />
                                    )}
                                </List>
                            ) : null } 
                        </div>
                    ) : null }
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button color="primary" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={this.handleDelete}>
                        Delete
                    </Button>
                    <Button color="primary" onClick={this.handleDownload}>
                        Download
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
};

ArchiveInfo.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    archive: PropTypes.object.isRequired,
    onAttachmentDownload: PropTypes.func.isRequired,
    onDownload: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
};

export default withStyles(styles)(ArchiveInfo);