import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {  withStyles  } from '@material-ui/core/styles';

import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Checkbox from '@material-ui/core/Checkbox';
// import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ArchiveListItem from './ArchiveListItem';
import AttachmentListItem from './AttachmentListItem';
import ArchiveInfo from './ArchiveInfo';
import ConfirmDialog from './ConfirmDialog';

const drawerWidth = 400;

const styles = theme => ({
    fab: {
        position: 'absolute',
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 2
    },
    singleTitle: {
        marginTop: 15,
        marginLeft: 25
    },
    title: {
        flexGrow: 1
    },
    toolbar: {
    },
    selected: {
        backgroundColor: theme.palette.action.selected
    },
    list: {
        overflowY: 'auto',
        width: '100%'
    },
    emptyText: {
        margin: 0,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    },
    details: {
    },
    drawerPaper: {
        backgroundColor: theme.palette.background.default,
        marginTop: 64,
        width: drawerWidth,
    },
});

class ArchiveList extends Component {

    state = {
        selection: [],
        selectedIndex: -1,
        archive: null,
        open: false,
        confirm: {
            open: false
        }
    }

    componentDidMount() {
        const { onRefresh } = this.props;

        onRefresh();
    }

    handleSelectionChange = (item, event) => {
        const { checked } = event.target;
        const { selection } = this.state;
        const currentIndex = selection.indexOf(item);
        const newSelection = [...selection];

        if (checked && currentIndex === -1) {
            newSelection.push(item);
        } 
        else if (!checked) {
            newSelection.splice(currentIndex, 1);
        }
        this.setState({ selection: newSelection });
    }

    handleItem = (archive, index) => {
        const { selectedIndex } = this.state;

        this.setState({ 
            selectedIndex: (selectedIndex !== index) ? index : -1,
            archive: (selectedIndex !== index) ? archive : null
        });
    }

    handleDownload = (archive) => {
        const { selection } = this.state;
        const { onDownload } = this.props;

        const attachments = ((archive) ? [archive] : selection).reduce((attachments, archive) => attachments.concat(archive.attachments), [])
        this.setState({
            selection: [],
            archive: null
        });
        onDownload(attachments)
    }

    handleInfo = (archive) => {
        this.setState({ 
            open: true,
            archive
        });   
    }

    handleDelete = (archive) => {
        this.setState({ 
            confirm: {
                open: true
            },
            selectedIndex: -1,
            archive
        });
    }

    handleAttachmentDownload = (attachment) => {
        const { onDownload } = this.props;

        onDownload([attachment])
    }

    handleDeleteConfirm = () => {
        const { onDelete } = this.props;
        const { archive, selection } = this.state;

        this.handleDeleteClose();
        onDelete((archive) ? [archive] : selection);
    }

    handleDeleteClose = () => {
        this.setState({
            selection: [],
            archive: null, 
            confirm: {
                open: false
            }
        });
    }

    handleClose = () => {
        this.setState({
            archive: null,
            open: false
        });
    }

    render() {
        const { classes, theme, archives } = this.props;
        const { selectedIndex, selection, archive } = this.state;
        // const itemSelected = (selectedIndex !== -1 && archive);

        return (
            <div>
                <Toolbar className={classes.toolbar}>
                    <Typography className={classes.title} variant="headline">Archives</Typography>
                    {(selection.length > 0) ? (
                        <div>
                            <Tooltip title="Download">
                                <IconButton aria-label="Download" onClick={() => this.handleDownload()}>
                                    <Icon>get_app</Icon>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <IconButton aria-label="Delete" onClick={() => this.handleDelete()}>
                                    <Icon>delete</Icon>
                                </IconButton>
                            </Tooltip>
                        </div>
                    ) : null}
                </Toolbar>
                {(archives.length === 0) ? (
                    <div className={classes.emptyText}>
                        <Typography  variant="caption">No archive(s) found</Typography>
                    </div>
                ) : (
                    <List 
                        // style={(itemSelected) ? {marginRight: drawerWidth} : {}}
                        className={classes.list}
                    >
                        {archives.map((archive, index) => 
                            <ArchiveListItem 
                                // className={(selectedIndex === index) ? classes.selected : ""} 
                                key={index} 
                                {...archive}
                                checked={selection.length > 0 && selection.findIndex(a => a._id === archive._id) !== -1}
                                onSelect={event => this.handleSelectionChange(archive, event)} 
                                onClick={() => this.handleItem(archive, index)}
                                onDownload={() => this.handleDownload(archive)}
                                onInfo={() => this.handleInfo(archive)}
                                onDelete={() => this.handleDelete(archive)}
                            />
                        )}
                    </List>
                )}
                {/* {(itemSelected) ? (
                        <Drawer 
                            variant="permanent" 
                            classes={{  paper: classes.drawerPaper  }} 
                            anchor="right"
                        >
                            <Typography className={classes.singleTitle} variant="headline">Assets</Typography>
                            <List className={classes.details}>
                                {archive.attachments.map((attachment, index) => 
                                    <AttachmentListItem 
                                        key={index} 
                                        {...attachment}
                                        onDownload={() => this.handleAttachmentDownload(attachment)}
                                    />
                                )}
                            </List>  
                        </Drawer>
                    ) : null
                } */}
                { (this.state.open === true && this.state.archive) ? (
                        <ArchiveInfo 
                            archive={archive}
                            open={this.state.open} 
                            onAttachmentDownload={this.handleAttachmentDownload}
                            onDownload={this.handleDownload}
                            onDelete={this.handleDelete}
                            onClose={this.handleClose}
                        />
                    ) : null
                }
                { (this.state.confirm.open === true) ? (
                        <ConfirmDialog
                            title="Confirm"
                            description="Are you sure you want to delete this item?"
                            open={this.state.confirm.open}
                            onConfirm={this.handleDeleteConfirm}
                            onClose={this.handleDeleteClose}
                        />
                    ) : null
                }
            </div>
        );
    }
};

ArchiveList.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    archives: PropTypes.arrayOf(
        PropTypes.object.isRequired
    ).isRequired,
    onRefresh: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onDownload: PropTypes.func.isRequired
};

export default withStyles(styles, { withTheme: true })(ArchiveList);