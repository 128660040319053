import * as Types from 'constants/ActionTypes';

const stages = (state = {
    inProgress: false,
    didInvalidate: false,
    data: []
}, action) => {
    switch (action.type) {
        case Types.REQUEST_DELETE_PROJECT:

        case Types.REQUEST_FETCH_STAGES:
        case Types.REQUEST_CREATE_STAGE:
        case Types.REQUEST_UPDATE_STAGE:
        case Types.REQUEST_DELETE_STAGE:
            return Object.assign({}, state, {
                inProgress: true,
                didInvalidate: false
            });
        case Types.RESPONSE_FETCH_STAGES_SUCCESS:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: action.stages,
                lastUpdated: action.receivedAt
            });
        case Types.RESPONSE_CREATE_STAGE_SUCCESS:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: [...state.data, action.stage],
                lastUpdated: action.receivedAt
            });
        case Types.RESPONSE_UPDATE_STAGE_SUCCESS:
            state.data = state.data.map(stage => (stage._id === action.stage._id ? action.stage : stage))
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: [...state.data],
                lastUpdated: action.receivedAt
            });
        case Types.RESPONSE_DELETE_PROJECT_SUCCESS:
            action.projects
            .reduce((data, project) => data.concat(state.data.filter(stage => stage.project_id === project._id)), [])
            .forEach(stage => state.data.splice(state.data.findIndex(s => (s._id === stage._id)), 1))
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: [...state.data],
                lastUpdated: action.receivedAt
            });
        case Types.RESPONSE_DELETE_STAGE_SUCCESS:
            action.stages.forEach(stage => state.data.splice(state.data.findIndex(s => (s._id === stage._id)), 1))
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: [...state.data],
                lastUpdated: action.receivedAt
            });
        case Types.RESPONSE_FETCH_STAGES_FAILURE:
        case Types.RESPONSE_CREATE_STAGE_FAILURE:
        case Types.RESPONSE_UPDATE_STAGE_FAILURE:
        case Types.RESPONSE_DELETE_STAGE_FAILURE:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                lastUpdated: action.receivedAt
            });
        default:
            return state
    }
};

export default stages;