import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {  withStyles  } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

// import Input from '@material-ui/core/Input';
// import InputLabel from '@material-ui/core/InputLabel';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';

// import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
// import AddCircleIcon from '@material-ui/icons/AddCircle';
// import InfoIcon from '@material-ui/icons/Info';

import FileField from './core/FileField';
import { PROFILE, capitalize } from './locale';

const styles = theme => ({
    flex: {
        flex: 1
    },
    dialog: {
        margin: 'auto',
        minWidth: 275,
    },
    dialogActions: {
        paddingLeft: theme.spacing.unit * 1.5,
        paddingRight: theme.spacing.unit * 1.5
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        // width: 250,
    },
    button: {
        margin: theme.spacing.unit
    },
    // selectInfo: {
    //     width: `calc(100% - ${theme.spacing.unit * 13}px)`, 
    //     marginRight: theme.spacing.unit * 2
    // },
    // trigger: {
    //     backgroundColor: 'transparent', 
    //     padding: theme.spacing.unit * 0.5, 
    //     minWidth: theme.spacing.unit * 5   
    // },
    // fileInput: {
    //     //Instead of display: none to prevent Chrome of required hidden fields
    //     opacity: 0,
    //     height: 1,
    //     width: 1
    // },
});

const InputLabelProps = { 
    required: false //Hide asterix on required field
};

class ProfileForm extends Component {

    state = {
        profile: {},
        anchorEl: null,
        info: {
            open: false
        }
    }

    initialState() {
        const { profile } = this.props;

        this.setState({ 
            profile: {...profile}
        });
    }

    componentDidMount() {
        this.initialState();
    }

    handleSubmit = () => {
        const { onSubmit } = this.props;
        
        onSubmit(this.state.profile);
    }
    
    handleChange = (e) => {
        const { id, value } = e.target;
        let { profile } = this.state;

        profile[id] = value;
        this.setState({ profile });
    }

    // handleMobileProvisionChange = (e) => {
    //     const { name, value } = e.target;
        

    //     if (name && value) {
    //         const { mobileprovisions } = this.props;
    //         const mobileprovision = mobileprovisions.find(mobileprovision => mobileprovision._id == value);

    //         if (mobileprovision) {
    //             let { profile } = this.state;

    //             profile[name] = mobileprovision;
    //             this.setState({ profile });
    //         }
    //     }
    // }

    // handleFileChange = (e) => {
    //     const { onMobileProvision } = this.props;
    //     const { files } = e.target;
    //     const file = files[0];

    //     if (file) {
    //         const  reader = new FileReader();
            
    //         reader.onload = () => {
    //             const value = {
    //                 name: file.name,
    //                 encoding: "base64",
    //                 mimeType: file.type,
    //                 content: reader.result
    //             };
    //             onMobileProvision(value);
    //         }
    //         reader.readAsDataURL(file);
    //     }
    // }

    // handleMobileProvisionInfo = (e) => {
    //     this.setState({ 
    //         anchorEl: e.currentTarget,
    //         info: { open:  true }
    //     });
    // }

    // handleMobileProvisionInfoClose = (e) => {
    //     this.setState({ 
    //         anchorEl: null,
    //         info: { open:  false }
    //     });
    // }

    render() {
        const { classes, open, onClose } = this.props;
        const { profile } = this.state;
        // const { mobileprovisions, classes, open, onClose } = this.props;
        // const { profile, anchorEl } = this.state;

        return (
            <Dialog
                className={classes.dialog}
                open={open}
                onClose={onClose}
                aria-labelledby="form-dialog-title">
                <form style={{ width: 400 }} autoComplete="off" onSubmit={this.handleSubmit}>
                    <DialogTitle id="form-dialog-title">
                        {!profile._id ?  `New ${capitalize(PROFILE)}` : `${capitalize(PROFILE)}`}
                    </DialogTitle>
                    <DialogContent>
                        <TextField 
                            InputLabelProps={InputLabelProps}
                            autoComplete="off"
                            autoFocus 
                            margin="dense" 
                            id="name" 
                            label="Name"
                            defaultValue={profile.name} 
                            fullWidth
                            onChange={this.handleChange}
                            required={true}
                        />
                        {/* <FormControl className={classes.formControl}
                            margin="dense" 
                            fullWidth
                        >
                            <div>
                                <InputLabel required={false} htmlFor="mobileprovision">Provisioning Profile</InputLabel>
                                <Select
                                    // id="mobileprovision"
                                    required={true}
                                    className={classes.selectInfo}
                                    value={profile.mobileprovision ? profile.mobileprovision._id : ''}
                                    // input={<Input id="mobileprovision"/>}
                                    inputProps={{
                                        name: 'mobileprovision',
                                        id: 'mobileprovision',
                                    }}
                                    onChange={this.handleMobileProvisionChange}
                                >
                                {mobileprovisions.map((mobileprovision, index) =>
                                    <MenuItem key={index} value={mobileprovision._id}>{mobileprovision.metadata.appIDName}</MenuItem>
                                )}
                                </Select>
                                <Input
                                    type="file"
                                    id="addMobileProvision"
                                    name="addMobileProvision"
                                    className={classes.fileInput}
                                    accept="*" //TODO
                                    onChange={this.handleFileChange}
                                />
                                <label htmlFor="addMobileProvision">
                                    <Button color="primary" className={classes.trigger} component="span"><AddCircleIcon/></Button>
                                </label>
                                <Button color="primary"
                                    aria-owns={anchorEl ? 'fileInfo' : null}
                                    aria-haspopup="true" 
                                    className={classes.trigger} 
                                    onClick={this.handleMobileProvisionInfo}
                                >
                                    <InfoIcon/>
                                </Button>
                            </div>
                        </FormControl> */}
                        <TextField 
                            InputLabelProps={InputLabelProps}
                            margin="dense" 
                            id="key" 
                            label="Key (key)"
                            defaultValue={profile.key} 
                            fullWidth
                            onChange={this.handleChange}
                            required={true}
                        />
                        <TextField 
                            InputLabelProps={InputLabelProps}
                            margin="dense" 
                            id="identifier" 
                            label="Identifier (identifier)"
                            defaultValue={profile.identifier} 
                            fullWidth
                            onChange={this.handleChange}
                            required={true}
                        />
                        <FileField
                            margin="dense" 
                            id="image"
                            text="Photo"
                            accept="image/*"
                            defaultValue={profile.image} 
                            onChange={this.handleChange}
                        />
                        {/* {(this.state.info.open && anchorEl) ? (
                            <Menu
                                id="fileInfo"
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={this.handleMobileProvisionInfoClose}
                            >
                                HTML   
                            </Menu>
                        ) : null} */}
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Button color="primary" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button type="submit" color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
};

ProfileForm.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    profile: PropTypes.object.isRequired,
    // mobileprovisions: PropTypes.arrayOf(
    //     PropTypes.object.isRequired
    // ).isRequired,
    // certificates: PropTypes.arrayOf(
    //     PropTypes.object
    // ),
    // onMobileProvision: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
};

export default withStyles(styles)(ProfileForm);