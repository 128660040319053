import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {  withStyles  } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ExpansionPanel, ExpansionPanelSummary, Typography, ExpansionPanelDetails, Divider, ExpansionPanelActions, Button, TextField, FormControlLabel, Checkbox, Avatar, Icon } from '@material-ui/core';

const styles = theme => ({
    title: {
        marginLeft: theme.spacing.unit * 2
    },
    icon: {
        backgroundColor: theme.palette.text.secondary
    },
    disabledIcon: {},
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    actions: {
        paddingLeft: theme.spacing.unit * 3,
        paddingRight: theme.spacing.unit * 3
    },
    spacer: {
        flexGrow: 1
    }
})

const InputLabelProps = { 
    required: false //Hide asterix on required field
}

const cloneArrayObject = items => items.map(item => ({...item}))

class SettingTaskForm extends Component {
    state = {
        stage: {},
        task: {},
        dirty: false
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const nextStage = nextProps.stage;
        const { stage } = prevState;
        
        if (nextStage && nextStage._id !== stage._id) {
            const nextTask = nextProps.task;
            const { settings, ...others } = nextTask;

            return {
                stage: {...nextStage},  
                task: (settings) ? { settings: cloneArrayObject(settings), ...others} : {},
                dirty: false
            }
        }
        return null;
    }

    handlePanelChange = (event, expanded) => {
        const { dirty } = this.state;

        if (!expanded && dirty) {
            const { task } = this.props;
            const { settings, ...others } = task;
            
            this.setState({
                task: (settings) ? { settings: cloneArrayObject(settings), ...others} : {},
                dirty: false
            })
        }
    }

    handleChange = event => {
        const { id, value } = event.target;
        const { task } = this.state;
        const index = task.settings.findIndex(s => s.key === id);

        if (index > -1) {
            task.settings[index].value = value;
            this.setState({ task, dirty: true })
        }
    }

    handleDisabledChange = event => {
        const { task } = this.state;
        const { onSave } = this.props;

        task.disabled = event.target.checked 
        event.preventDefault();
        if (onSave) {
            onSave(task)
        }
    }

    handleDelete = event => {
        const { task } = this.state;
        const { onDelete } = this.props;

        event.preventDefault();
        if (onDelete) {
            onDelete(task)
        }
    }

    handleSave = event => {
        const { task } = this.state;
        const { onSave } = this.props;

        event.preventDefault();
        if (onSave) {
            task.invalid = false
            onSave(task)
            .then(() => this.setState({ dirty: false }))
        }
    }

    render() {
        const { classes } = this.props;
        const { task, dirty } = this.state;

        return (
            <ExpansionPanel onChange={this.handlePanelChange}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Avatar className={(task.disabled) ? classes.disabledIcon : classes.icon}>
                        <Icon>{task.icon}</Icon>
                    </Avatar>
                    <div className={classes.title}>
                        <Typography className={classes.heading} color={(task.disabled) ? "textSecondary" : "default"}>{task.name}</Typography>
                        <Typography variant="caption" color="textSecondary">{task.description}</Typography>
                    </div>
                </ExpansionPanelSummary>
                {/* <Divider /> */}
                <ExpansionPanelDetails>
                    <div>
                        {(task.settings) && task.settings.map(setting => (
                            <TextField
                                InputLabelProps={InputLabelProps}
                                autoComplete="off"
                                key={setting.key} 
                                id={setting.key}
                                margin="dense" 
                                label={setting.title}
                                value={setting.value} 
                                fullWidth
                                onChange={this.handleChange}
                                required={true}
                            />
                        ))}
                    </div>
                </ExpansionPanelDetails>
                <ExpansionPanelActions className={classes.actions}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={!!task.disabled}
                                onChange={this.handleDisabledChange}
                            />
                        }
                        label="Disabled"
                    />
                    <div className={classes.spacer}/>
                    <Button 
                        size="small"
                        onClick={this.handleDelete}
                    >
                        Delete
                    </Button>
                    <Button 
                        size="small" 
                        variant="contained" 
                        color="primary" 
                        disabled={!dirty && !task.invalid}
                        onClick={this.handleSave}
                    >
                        Save
                    </Button>
                </ExpansionPanelActions>
            </ExpansionPanel>
        )
    }
}

SettingTaskForm.propTypes = {
    classes: PropTypes.object.isRequired,
    project: PropTypes.object,
    task: PropTypes.object,
    onSave: PropTypes.func,
    onDelete: PropTypes.func
}

export default withStyles(styles)(SettingTaskForm)