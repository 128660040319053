import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {  withStyles  } from '@material-ui/core/styles';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Toolbar, Typography, Tooltip, IconButton, Icon, ExpansionPanel, ExpansionPanelSummary, Avatar, ExpansionPanelDetails, ExpansionPanelActions, Button } from '@material-ui/core';

import { fileSize } from 'helpers';

const drawerWidth = 850;

const styles = theme => ({
    spacer: {
        flexGrow: 1
    },
    details: {
        display:'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        width: drawerWidth,
        borderLeft: `1px solid ${theme.palette.divider}`
    },
    toolbar: {
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    items: {
        flexGrow: 1,
        overflowY: 'scroll',
        padding: theme.spacing.unit * 2,
        backgroundColor: theme.palette.background.default
    },

    itemTitle: {
        marginLeft: theme.spacing.unit * 2
    },
    itemIcon: {
        backgroundColor: theme.palette.text.secondary
    },
    itemHeading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    itemDetails: {
        width: '100%'
    },
    itemDetailRow: {
        padding: theme.spacing.unit / 2
    },
    itemActions: {
        paddingLeft: theme.spacing.unit * 3,
        paddingRight: theme.spacing.unit * 3
    }
})

class BuildAssets extends Component {
    state = {
        build: {}
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const nextBuild = nextProps.build;
        const { build } = prevState;

        if (nextBuild && build._id != nextBuild._id) {
          return {  build: nextBuild  }
        }
        return null;
    }

    initialState() {
        const { build } = this.props;

        this.setState({
            build: {...build}
        })
    }

    componentDidMount() {
        this.initialState()
    }

    handleDownload = (event) => {
        const { assets, onDownload } = this.props;

        onDownload(assets)
    }

    handleDownloadAsset = (event, asset) => {
        const { onDownload } = this.props;

        onDownload([asset])
    }

    render() {
        const { classes, project, assets } = this.props;
        const { build } = this.state;
        // const openMenu = Boolean(anchorEl);

        return (
            <div
                className={classes.details}    
            >
                {/* <Paper square elevation={2}> */}
                    <Toolbar
                        className={classes.toolbar}
                    >
                        <div className={classes.title}>
                            <Typography id="tableTitle" variant="h6">
                                {build.name}
                            </Typography>
                        </div>
                        <div className={classes.spacer} />
                        <div className={classes.actions}>
                            <Toolbar>
                                <Tooltip title="Download all">
                                    <IconButton
                                        onClick={event => this.handleDownload(event)}
                                    >
                                        <Icon>save_alt</Icon>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Filter list">
                                    <IconButton aria-label="Filter list">
                                        <FilterListIcon />
                                    </IconButton>
                                </Tooltip>
                            </Toolbar>
                        </div>
                    </Toolbar>
                {/* </Paper> */}
                <div className={classes.items}>
                    {(assets.length > 0) ? assets.map((asset, key) =>
                        <ExpansionPanel key={key}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Avatar className={classes.itemIcon}>
                                    <Icon></Icon>
                                </Avatar>
                                <div className={classes.itemTitle}>
                                    <Typography className={classes.itemHeading}>{asset.name}</Typography>
                                    <Typography variant="caption" color="textSecondary">{fileSize(asset.bytes)}</Typography>
                                </div>
                            </ExpansionPanelSummary>
                            {/* <Divider /> */}
                            <ExpansionPanelDetails>
                                <div className={classes.itemDetails}>
                                    <div className={classes.itemDetailRow}>
                                        <Typography variant="caption" color="textSecondary">Identifier</Typography>
                                        <Typography>{asset.props.CFBundleIdentifier}</Typography>
                                    </div>
                                    <div className={classes.itemDetailRow}>
                                        <Typography variant="caption" color="textSecondary">Team</Typography>
                                        <Typography>{asset.signing.mobileprovision.metadata.teamName}</Typography>
                                    </div>
                                    <div className={classes.itemDetailRow}>
                                        <Typography variant="caption" color="textSecondary">Provisioning Profile</Typography>
                                        <Typography>{asset.signing.mobileprovision.metadata.name}</Typography>
                                    </div> 
                                    <div className={classes.itemDetailRow}>
                                        <Typography variant="caption" color="textSecondary">Version</Typography>
                                        <Typography>{asset.props.CFBundleShortVersionString}</Typography>
                                    </div>      
                                </div>
                            </ExpansionPanelDetails>
                            <ExpansionPanelActions className={classes.itemActions}>
                                <div className={classes.spacer}/>
                                {/* <Button 
                                    size="small" 
                                    // variant="contained" 
                                    // color="primary"
                                >
                                    Delete
                                </Button> */}
                                <Button 
                                    size="small" 
                                    // variant="contained" 
                                    // color="primary"
                                    onClick={event => this.handleDownloadAsset(event, asset)}
                                >
                                    Download
                                </Button>
                            </ExpansionPanelActions>
                        </ExpansionPanel>
                    ) : (
                        <Typography  variant="caption">0 asset(s)</Typography>
                    )}
                </div>
            </div>
        )
    }
}

BuildAssets.propTypes = {
    classes: PropTypes.object.isRequired,
    project: PropTypes.shape({
        _id: PropTypes.string.isRequired
    }),
    build: PropTypes.object,
    assets: PropTypes.arrayOf(PropTypes.object).isRequired,
    onDownload: PropTypes.func.isRequired
}

export default withStyles(styles)(BuildAssets)