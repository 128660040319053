import rpc from 'services/rpc';

export const REQUEST_FETCH_CERTIFICATES  = 'REQUEST_FECTH_CERTIFICATES';
export const RESPONSE_FETCH_CERTIFICATES_SUCCESS = 'RESPONSE_FETCH_CERTIFICATES_SUCCESS';
export const RESPONSE_FETCH_CERTIFICATES_FAILURE = 'RESPONSE_FETCH_CERTIFICATES_FAILURE';

function requestFetchCerts() {
    return {
        type: REQUEST_FETCH_CERTIFICATES
    }
};

function responseFetchCertsSuccess(json) {
    return {
        type: RESPONSE_FETCH_CERTIFICATES_SUCCESS,
        certificates: json,
        receivedAt: Date.now()
    };
};

function responseFetchCertsFailure(error) {
    return {
        type: RESPONSE_FETCH_CERTIFICATES_FAILURE,
        error,
        receivedAt: Date.now()
    };
};

// Do not use catch, because that will also catch
// any errors in the dispatch and resulting render,
// causing a loop of 'Unexpected batch number' errors.
// https://github.com/facebook/react/issues/6895

export function fetchCertificates() {
    return function(dispatch) {
        dispatch(requestFetchCerts());

        return rpc.get('certificates')
        .then(response => {
            const { data, error } = response.result()

            if (error) {
                dispatch(responseFetchCertsFailure(error));
            }
            else {
                dispatch(responseFetchCertsSuccess(data));
            }
        }, e => dispatch(responseFetchCertsFailure({
            message: e.stderr ? e.stderr : e.message
        })))

        // return rpc.get('certificates')
        // .then(
        //     response => response.json(),
        //     error => console.log('An error occurred.', error)
        // )
        // .then(json => dispatch(responseFetchCerts(json)));
    }
};