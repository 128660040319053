import { connect } from 'react-redux';
import SecurityCodeForm from 'components/SecurityCodeForm';
import { resendCode, verifyEmail } from 'actions';

const mapStateToProps = state => ({
    inProgress: state.user.inProgress,
    user: state.user.data,
    error: state.user.error
});

const mapDispatchToProps = (dispatch, props) => ({
    onResend: (email) => dispatch(resendCode(email)),
	onSubmit: (email, code) => {
        const { history } = props;
        
        dispatch(verifyEmail(email, code, history));
    }
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SecurityCodeForm);