import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {  withStyles  } from '@material-ui/core/styles';

import FilterListIcon from '@material-ui/icons/FilterList';
import { Toolbar, Typography, Tooltip, IconButton, Icon, Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';

import InfoPlistTaskForm from 'components/InfoPlistTaskForm';
import SettingTaskForm from 'components/SettingTaskForm';
import SigningTask from 'containers/SigningTask';

const componentTypes = {
    props: InfoPlistTaskForm,
    settings: SettingTaskForm,
    resign: SigningTask
};

const taskRuler = {
    rules: [
        { name: 'Unique task', fn: (tasks, newTask) => (tasks.findIndex(t => t.type === newTask.type) === -1) }
    ],
    props: { 
        rules: [
            { name: 'Always before resign task', fn: tasks => (tasks.findIndex(t => t.type === 'resign') === -1) }
        ] 
    },
    settings: { rules: [] },
    resign: { rules: [] }   
};

const drawerWidth = 850;
const MENU_ITEM_HEIGHT = 48;

const styles = theme => ({
    spacer: {
        flexGrow: 1
    },
    details: {
        display:'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        width: drawerWidth,
        borderLeft: `1px solid ${theme.palette.divider}`
    },
    toolbar: {
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    items: {
        flexGrow: 1,
        overflowY: 'scroll',
        padding: theme.spacing.unit * 2,
        backgroundColor: theme.palette.background.default
    }
})

class StageTasks extends Component {
    state = {
        stage: { tasks: [] },
        anchorEl: null
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const nextStage = nextProps.stage;
        const { stage } = prevState;

        if (nextStage && stage._id != nextStage._id) {
          return {  stage: nextStage  }
        }
        return null;
    }

    initialState() {
        const { stage } = this.props;

        this.setState({
            stage: {...stage}
        })
    }

    componentDidMount() {
        this.initialState()
    }

    handleMenuButtonClick = event => {
        event.stopPropagation()
        this.setState({ anchorEl: event.currentTarget })
    }

    handleMenuClose = () => this.setState({ anchorEl: null })

    handleMenuItemClick = (event, action) => {
        const { project, onUpdate } = this.props;
        const { stage } = this.state;
        let newTask = { type: action.type, description: action.description, icon: action.icon, name: action.label, disabled: false };

        event.stopPropagation()
        this.handleMenuClose()

        if (taskRuler.rules.concat(taskRuler[newTask.type].rules).every(rule => rule.fn(stage.tasks, newTask))) {
            switch (newTask.type) {
                case 'props':
                case 'settings': 
                    newTask = Object.assign(newTask, { [newTask.type]: project.asset[newTask.type] })
                    break
                case 'resign':
                    newTask = Object.assign(newTask, { signing: { certificate: null, mobileprovision: null }})
                    break 
                default: break
            }
            stage.tasks.push(newTask)
            return onUpdate(stage)
        }
    }

    handleTaskDelete = task => {
        const { onUpdate } = this.props;
        const { stage } = this.state;
        
        stage.tasks.splice(stage.tasks.findIndex(t => (t.type === task.type)), 1)
        return onUpdate(stage)
    }

    handleTaskSave = task => {
        const { onUpdate } = this.props;
        const { stage } = this.state;
        const index = stage.tasks.findIndex(t => t.type === task.type)

        if (index > -1) stage.tasks[index] = task
        return onUpdate(stage)
    }

    render() {
        const { classes, project } = this.props;
        const { stage, anchorEl } = this.state;
        const openMenu = Boolean(anchorEl);

        return (
            <div
                className={classes.details}    
            >
                {/* <Paper square elevation={2}> */}
                    <Toolbar
                        className={classes.toolbar}
                    >
                        <div className={classes.title}>
                            <Typography id="tableTitle" variant="h6">
                                {stage.name}
                            </Typography>
                        </div>
                        <div className={classes.spacer} />
                        <div className={classes.actions}>
                            <Toolbar>
                                <Tooltip title="New task">
                                    <IconButton
                                        aria-owns={openMenu ? 'new-menu' : undefined}
                                        aria-haspopup="true" 
                                        aria-label="New task" 
                                        onClick={event => this.handleMenuButtonClick(event)}
                                    >
                                        <Icon>add</Icon>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Filter list">
                                    <IconButton aria-label="Filter list">
                                        <FilterListIcon />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    id="new-menu"
                                    anchorEl={anchorEl}
                                    open={openMenu}
                                    onClose={this.handleMenuClose}
                                    PaperProps={{
                                        style: {
                                            maxHeight: MENU_ITEM_HEIGHT * 4.5
                                        }
                                    }}
                                >
                                    {[
                                        { label: 'Bundle Properties', type: 'props', description: 'Rebrand', icon: 'list' },
                                        { label: 'App Settings', type: 'settings', description: 'Configuration', icon: 'settings_applications', disabled: () => (!project.asset.settings) },
                                        { label: 'Certificate & Profile', type: 'resign', description: 'Resign', icon: 'fingerprint' }
                                    ]
                                    .map((action, index) => (
                                        <MenuItem 
                                            className={classes.menuItem}
                                            key={index} 
                                            onClick={event => this.handleMenuItemClick(event, action)} 
                                            disabled={action.disabled && action.disabled()}
                                        >
                                            <ListItemIcon className={classes.icon}>
                                                <Icon>{action.icon}</Icon>
                                            </ListItemIcon>
                                            <ListItemText classes={{ primary: classes.primary }} inset primary={action.label} />
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Toolbar>
                        </div>
                    </Toolbar>
                {/* </Paper> */}
                <div className={classes.items}>
                    {(stage.tasks.length > 0) ? stage.tasks.map((task, key) => 
                        componentTypes[task.type] ? (React.createElement(componentTypes[task.type], { 
                            key,
                            project,
                            stage, 
                            task,
                            onDelete: this.handleTaskDelete,
                            onSave: this.handleTaskSave 
                        })) : null
                    ) : (
                        <Typography  variant="caption">0 task(s)</Typography>
                    )}
                </div>
            </div>
        )
    }
}

StageTasks.propTypes = {
    classes: PropTypes.object.isRequired,
    project: PropTypes.shape({
        _id: PropTypes.string.isRequired
    }),
    stage: PropTypes.object,
    onUpdate: PropTypes.func.isRequired
}

export default withStyles(styles)(StageTasks)