import * as Types from 'constants/ActionTypes';

const user = (state = {
    inProgress: false,
    didInvalidate: false,
    data: null,
}, action) => {
    switch (action.type) {
        case Types.REQUEST_AUTH:
        case Types.REQUEST_LOGIN:
        case Types.REQUEST_LOGOUT:
        case Types.REQUEST_REGISTER:
        case Types.REQUEST_VERIFY_EMAIL:
        case Types.REQUEST_RESEND:
        case Types.REQUEST_FORGOT_PWD:
        case Types.REQUEST_RESET_PWD:
            return Object.assign({}, state, {
                inProgress: true,
                didInvalidate: false
            });
        case Types.RESPONSE_AUTH_SUCCESS:
        case Types.RESPONSE_LOGIN_SUCCESS:
        case Types.RESPONSE_REGISTER_SUCCESS:
        case Types.RESPONSE_VERIFY_EMAIL_SUCCESS:
        case Types.RESPONSE_RESEND_SUCCESS:
        case Types.RESPONSE_FORGOT_PWD_SUCCESS:
        case Types.RESPONSE_RESET_PWD_SUCCESS:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: action.user,
                lastUpdated: action.receivedAt
            });
        case Types.RESPONSE_LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: null,
                lastUpdated: action.receivedAt
            });
        case Types.RESPONSE_AUTH_FAILURE:
        case Types.RESPONSE_LOGIN_FAILURE:
        case Types.RESPONSE_LOGOUT_FAILURE:
        case Types.RESPONSE_REGISTER_FAILURE:
        case Types.RESPONSE_FORGOT_PWD_FAILURE:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: null,
                lastUpdated: action.receivedAt
            });
        case Types.RESPONSE_VERIFY_EMAIL_FAILURE:
        case Types.RESPONSE_RESEND_FAILURE:
        case Types.RESPONSE_RESET_PWD_FAILURE:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                lastUpdated: action.receivedAt
            });
        default:
            return state
    }
};

export default user;