const crypto = require('crypto');

class Response {
    constructor(response) {
        this.response = response;
    }
    result() {
        return this.response;
    }
    success() {
        return this.response.success;
    }
    json() {
        return this.response.data;
    }
    error() {
        return this.response.error;
    }
}

class MemoryProvider {
    get(path, data) {
        return Promise.resolve(new Response({
            success: true,
            data: []
        }));
    }
    post(path, data) {
        return Promise.resolve(new Response({
            success: true,
            data
        }));
    }
    put(path, data) {
        return Promise.resolve(new Response({
            success: true,
            data
        }));
    }
    delete(path, data) {
        return Promise.resolve(new Response({
            success: true,
            data
        }));
    }
}

class IPCProvider {
    constructor() {
        this.ipc = window.electronAPI.ipcBridge;
        this.ipc.addMessageListener(this.onMessage.bind(this));
        this.callbacks = {};
    }
    uuid() {
        return crypto.randomBytes(12).toString('hex');
    }
    onMessage(sender, message) {
        const data = message.data;
        const callbackId = message.callbackId;
        const callback = this.callbacks[callbackId];

        if (callback) {
            const response = new Response(data);

            if (response.success()) {
                callback.resolve(response);
            }
            else {
                callback.reject(response.error());
            }
            delete this.callbacks[callbackId];
        }
    }
    send(path, method, data) {
        const me = this;
        const ipc = this.ipc;
        const callbackId = this.uuid();

        return new Promise((resolve, reject) => {
            me.callbacks[callbackId] = {
                resolve, reject
            };
            ipc.sendMessage({
                callbackId,
                data: {
                    path,
                    method,
                    args: data
                }
            });
        });
    }
    // send(path, method, data) {
    //     const ipc = this.ipc;

    //     return new Promise((resolve, reject) => {
    //         const listener = (sender, message) => {
    //             const data = message.data;
    //             const response = new Response(data);
    //             const success = data.success;

    //             ipc.removeMessageListener(listener);
    //             if (response.success()) {
    //                 resolve(response);
    //             }
    //             else {
    //                 reject(response.error());
    //             }
    //         }
    //         ipc.addMessageListener(listener);
    //         ipc.sendMessage({
    //             data: {
    //                 path,
    //                 method,
    //                 args: data
    //             }
    //         });
    //     });
    // }
    get(path, data) {
        return this.send(path, 'get', data);
    }
    post(path, data) {
        return this.send(path, 'post', data);
    }
    put(path, data) {
        return this.send(path, 'put', data);
    }
    delete(path, data) {
        return this.send(path, 'delete', data);
    }
}

const RPCService = (provider) => ({
    get(path, data) {
        return provider.get(path, data);
    },
    post(path, data) {
        return provider.post(path, data);
    },
    put(path, data) {
        return provider.put(path, data);
    },
    delete(path, data) {
        return provider.delete(path, data);
    }
});

export default RPCService((window.electronAPI && window.electronAPI.ipcBridge) ? (new IPCProvider()) : (new MemoryProvider()));