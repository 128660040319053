import * as Types from 'constants/ActionTypes';

const builds = (state = {
    inProgress: false,
    didInvalidate: false,
    data: []
}, action) => {
    switch (action.type) {
        case Types.REQUEST_DELETE_PROJECT:

        case Types.REQUEST_FETCH_BUILDS:
        case Types.REQUEST_CREATE_BUILD:
        case Types.REQUEST_UPDATE_BUILD:
        case Types.REQUEST_DELETE_BUILD:
            return Object.assign({}, state, {
                inProgress: true,
                didInvalidate: false
            });
        case Types.RESPONSE_FETCH_BUILDS_SUCCESS:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: action.builds,
                lastUpdated: action.receivedAt
            });
        case Types.RESPONSE_CREATE_BUILD_SUCCESS:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: [...state.data, action.build],
                lastUpdated: action.receivedAt
            });
        case Types.RESPONSE_UPDATE_BUILD_SUCCESS:
            state.data = state.data.map(build => (build._id === action.build._id ? action.build : build))
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: [...state.data],
                lastUpdated: action.receivedAt
            });
        case Types.RESPONSE_DELETE_PROJECT_SUCCESS:
            action.projects
            .reduce((data, project) => data.concat(state.data.filter(build => build.project_id === project._id)), [])
            .forEach(build => state.data.splice(state.data.findIndex(s => (s._id === build._id)), 1))
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: [...state.data],
                lastUpdated: action.receivedAt
            });
        case Types.RESPONSE_DELETE_BUILD_SUCCESS:
            action.builds.forEach(build => state.data.splice(state.data.findIndex(s => (s._id === build._id)), 1))
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: [...state.data],
                lastUpdated: action.receivedAt
            });
        case Types.RESPONSE_FETCH_BUILDS_FAILURE:
        case Types.RESPONSE_CREATE_BUILD_FAILURE:
        case Types.RESPONSE_UPDATE_BUILD_FAILURE:
        case Types.RESPONSE_DELETE_BUILD_FAILURE:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                lastUpdated: action.receivedAt
            });
        default:
            return state
    }
};

export default builds;