import { connect } from 'react-redux';

import Layout from 'components/Layout';
import ProjectLayout from 'components/ProjectLayout';
import MobileProvisions from 'containers/MobileProvisions';
// import Setting from 'containers/Setting';

import { logout } from 'actions';

const links = [
    { key: "projects", to: "/project", component: 'ProjectLayout', icon: "inbox", text: "Projects" },
    { key: "mobileprovisions", to: "/mobileprovision", component: 'MobileProvisions', icon: "folder_open", text: "Profiles" },
    // { key: "settings", to: "/setting", component: 'Setting', icon: "settings", text: "Settings" }
];

const containers = {
    ProjectLayout: ProjectLayout,
    MobileProvisions: MobileProvisions
    // Setting: Setting
};

const mapStateToProps = (state) => {
    // let badges = {};

    // badges['archives'] = state.archives.badge
    return {
        links,
        containers,
        user: state.user.data,
        // badges 
    }
};

const mapDispatchToProps = (dispatch, props) => ({
    onLogout: () => {
        const { history } = props;
        
        dispatch(logout(history));
    }
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Layout);