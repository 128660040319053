import { connect } from 'react-redux';
import { 
    fetchProject, fetchStages, createStage, updateStage, deleteStage, createBuildByStages,
    fetchBuilds, deleteBuild, fetchAssets, downloadAssets
 } from 'actions';

import Project from 'components/Project';

const mapStateToProps = (state, ownProps) => {
    const { params } = ownProps.match;
    const projectId = params.id;
    // const projectProp = params.prop;

    return {
        inProgress: state.projects.inProgress,
        project: state.projects.data.find(project => project._id === projectId),
        // projectProp,
        stages: state.stages.data.filter(stage => stage.project_id === projectId),
        builds: state.builds.data.filter(build => build.project_id === projectId),
        assets: state.assets.data
    }
};

const mapDispatchToProps = dispatch => {
    return ({
        onProjectRead: id =>  dispatch(fetchProject(id)),
        onStagesRefresh: () => dispatch(fetchStages()),
        onStageCreate: stage => dispatch(createStage(stage)),
        onStageUpdate: stage => dispatch(updateStage(stage)),
        onStageDelete: stages => dispatch(deleteStage(stages)),
        onStageBuild: stages => dispatch(createBuildByStages(stages)).then(action => dispatch(fetchStages()).then(() => action)),
        onBuildsRefresh: () => dispatch(fetchBuilds()),
        onBuildDelete: builds => dispatch(deleteBuild(builds)).then(action => dispatch(fetchStages()).then(() => action)),
        onBuildAssetsRefresh: assets => dispatch(fetchAssets(assets)),
        onBuildAssetsDownload: assets => dispatch(downloadAssets(assets))
    })
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Project);