import { connect } from 'react-redux';
import { 
    fetchProjects, createProject, updateProject, deleteProject, 
    // fetchMobileProvisions, fetchCertificates, fetchProfiles, fecthArchiveInfo, createMobileProvision,
    createBuildByProjects } from 'actions';

import Projects from 'components/Projects';

//Test Data
// const randomStr = (prefix = '', length = 5) => {
//     const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
//     let text = "";
  
//     for (let i = 0; i < length; i++)
//         text += possible.charAt(Math.floor(Math.random() * possible.length));
  
//     return prefix + text;
// }

// const randomIcon = () => {
//     const possible = "0123456789ABCDEF";
//     const possibleTypo = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
//     const typo = possibleTypo.charAt(Math.floor(Math.random() * possibleTypo.length));
//     let backgroundColor = "#";
  
//     for (let i = 0; i < 6; i++)
//         backgroundColor += possible.charAt(Math.floor(Math.random() * possible.length));
  
//     return {
//         typo,
//         backgroundColor
//     };
// }

// const randomVersion = () => {
//     const possible = "0123456789";
//     let text = "";
  
//     for (let i = 0; i < 3; i++) {
//         text += possible.charAt(Math.floor(Math.random() * possible.length))
//         text += (text.length <= 3) ? '.' : ''
//     }
//     return text;
// }

// const randomDate = (start, end) => {
//     return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
// }

// const randomStatus = () => {
//     const possible =['succeed', 'failed']

//     return possible[Math.floor(Math.random() * possible.length)]
// }

// const mockProjects = ((count) => {
//     const startDate = new Date(2018, 0, 1);
//     const endDate = new Date();
//     let data = [];
//     let increment = 0;

//     while (count > 0) {
//         data.push({
//             _id: ++increment,
//             icon: randomIcon(),
//             name: randomStr('Project-'),
//             description: randomStr('Description-'),
//             version: randomVersion(),
//             builded: randomDate(startDate, endDate).toDateString(),
//             status: randomStatus()
//         })
//         count--
//     }
//     // console.log(data)
//     return data
// })(25)
//

// const sorter = (prop) => (a, b) => {
//     if (a[prop] < b[prop]) return 1
//     else if (a[prop] > b[prop]) return -1
//     else return 0
// }

const mapStateToProps = (state) => {
    return {
        inProgress: state.projects.inProgress,
        projects: state.projects.data
    }
};

const mapDispatchToProps = dispatch => ({
    onRefresh: () => dispatch(fetchProjects()),
    onCreate: project => dispatch(createProject(project)),
    onUpdate: project => dispatch(updateProject(project)),
    onDelete: projects => dispatch(deleteProject(projects)),
    onBuild: projects => dispatch(createBuildByProjects(projects)).then(action => dispatch(fetchProjects()).then(() => action))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Projects);