import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {  Link  } from 'react-router-dom';
import {  withStyles, createMuiTheme, MuiThemeProvider  } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import BrandPaper from 'components/BrandPaper';
import DarkTheme from 'components/themes/dark';
import green from '@material-ui/core/colors/green';

const styles = theme => ({
    form: {
        flexShrink: 0,
        width: theme.spacing.unit * 41
    },
    header: {
        textAlign: 'center',
        marginBottom: theme.spacing.unit * 4,
        '& > *': {
            padding: theme.spacing.unit / 2
        }
    },
    content: {
        flex: '1 0 auto',
    },
    textField: {
        width: theme.spacing.unit * 41
    },
    actions: {
        
    },
    buttons: {
        marginTop: theme.spacing.unit * 8
    },
    button: {
        width: theme.spacing.unit * 19.5
    },
    login: {
        marginLeft: theme.spacing.unit * 2
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -theme.spacing.unit - 4,
        marginLeft: -theme.spacing.unit - 4
    }
})

const InputLabelProps = { 
    required: false //Hide asterix on required field
}

class RegisterForm extends Component {
    state = {
        firstName: '',
        lastName: '',
        email: '',
        password: ''
    }

    handleChange = (e) => {
        const { id, value } = e.target;
        
        this.setState({ [id]: value });
    }

    handleSubmit = (e) => {
        const { firstName, lastName, email, password } = this.state;
        const { onRegister } = this.props;

        e.preventDefault();
        onRegister(firstName, lastName, email, password);
    }

    render() {
        const { classes, inProgress } = this.props

        return (
            <BrandPaper>
                <form className={classes.form} autoComplete="off" onSubmit={this.handleSubmit}>
                    <div className={classes.header}>
                        <Typography component="h5" variant="h4">
                            BulkAppResign
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                            Please enter all information to create a new account.
                        </Typography>
                    </div>
                    <div className={classes.content}>
                        <TextField
                            InputLabelProps={InputLabelProps}
                            autoFocus
                            id="firstName"
                            label="First name"
                            className={classes.textField}
                            onChange={this.handleChange}
                            margin="normal"
                            required={true}
                        />
                        <br/>
                        <TextField
                            InputLabelProps={InputLabelProps}
                            id="lastName"
                            label="Last name"
                            className={classes.textField}
                            onChange={this.handleChange}
                            margin="normal"
                            required={true}
                        />
                        <br/>
                        <TextField
                            InputLabelProps={InputLabelProps}
                            id="email"
                            label="Email"
                            className={classes.textField}
                            type="email"
                            onChange={this.handleChange}
                            margin="normal"
                            required={true}
                        />
                        <br/>
                        <TextField
                            InputLabelProps={InputLabelProps}
                            id="password"
                            label="Password"
                            className={classes.textField}
                            type="password"
                            onChange={this.handleChange}
                            autoComplete="current-password"
                            margin="normal"
                            required={true}
                        />
                    </div>
                    <div className={classes.actions}>
                        <DarkTheme>
                            <div className={classes.buttons}>
                                <Button disabled={inProgress} size="small" variant="outlined" color="primary" className={classes.button} component={Link} to="/login">Login</Button>
                                <Button disabled={inProgress} type="submit" variant="contained" color="primary" className={`${classes.button} ${classes.login}`}>
                                    Sign up
                                    {inProgress && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </Button> 
                            </div>   
                        </DarkTheme>
                    </div>
                </form>
            </BrandPaper>
        )
    }
}

RegisterForm.propTypes = {
    classes: PropTypes.object.isRequired,
    inProgress: PropTypes.bool,
    onRegister: PropTypes.func.isRequired
};

export default withStyles(styles)(RegisterForm)

// import React, { Component } from 'react';
// import {  Link  } from 'react-router-dom';
// import PropTypes from 'prop-types';
// import {  withStyles  } from '@material-ui/core/styles';

// import Card from '@material-ui/core/Card';
// import Avatar from '@material-ui/core/Avatar';
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
// import Typography from '@material-ui/core/Typography';
// import TextField from '@material-ui/core/TextField';
// import Button from '@material-ui/core/Button';

// import CircularProgress from '@material-ui/core/CircularProgress';
// import green from '@material-ui/core/colors/green';

// const styles = theme => ({
//     flex: {
//         flex: 1
//     },
//     root: {
//         margin: 0,
//         position: 'absolute',
//         top: '40%',
//         left: '50%',
//         marginRight: '-50%',
//         transform: 'translate(-50%, -50%)'
//         // display: 'flex',
//         // flexWrap: 'wrap',
//         // marginTop: theme.spacing.unit * 12
//     },
//     card: {
//         margin: 'auto',
//         minWidth: 275,
//         textAlign: 'center',
//     },
//     avatar: {
//         margin: 'auto',
//         width: 60,
//         height: 60,
//         backgroundColor: theme.palette.primary.main,
//         marginBottom: 20,
//         marginTop: 20,
//     },
//     cardActions: {
//         paddingLeft: theme.spacing.unit * 1.5,
//         paddingRight: theme.spacing.unit * 1.5
//     },
//     textField: {
//         marginLeft: theme.spacing.unit,
//         marginRight: theme.spacing.unit,
//         width: 250,
//     },
//     button: {
//         margin: theme.spacing.unit,
//     },
//     buttonProgress: {
//         color: green[500],
//         position: 'absolute',
//         top: '50%',
//         left: '50%',
//         marginTop: -12,
//         marginLeft: -12,
//     }
// });

// const InputLabelProps = { 
//     required: false //Hide asterix on required field
// }

// class RegisterForm extends Component {

//     handleChange = (e) => {
//         const { id, value } = e.target;
        
//         this.setState({ [id]: value });
//     }

//     handleSubmit = (e) => {
//         const { firstName, lastName, email, password } = this.state;
//         const { onRegister } = this.props;

//         e.preventDefault();
//         onRegister(firstName, lastName, email, password);
//     }

//     render() {
//         const { classes, inProgress } = this.props;
        
//         return (
//             <form className={classes.root} autoComplete="off" onSubmit={this.handleSubmit}>
//                 <Card className={classes.card}>
//                     <CardContent>
//                         <Avatar alt="logo" src="/images/logo-white.png" className={classes.avatar}></Avatar>
//                         <Typography variant="headline">Register with us</Typography>
//                         <TextField
//                             InputLabelProps={InputLabelProps}
//                             id="firstName"
//                             label="First name"
//                             className={classes.textField}
//                             onChange={this.handleChange}
//                             margin="normal"
//                             required={true}
//                         />
//                         <br/>
//                         <TextField
//                             InputLabelProps={InputLabelProps}
//                             id="lastName"
//                             label="Last name"
//                             className={classes.textField}
//                             onChange={this.handleChange}
//                             margin="normal"
//                             required={true}
//                         />
//                         <br/>
//                         <TextField
//                             InputLabelProps={InputLabelProps}
//                             id="email"
//                             label="Email"
//                             className={classes.textField}
//                             type="email"
//                             onChange={this.handleChange}
//                             margin="normal"
//                             required={true}
//                         />
//                         <br/>
//                         <TextField
//                             InputLabelProps={InputLabelProps}
//                             id="password"
//                             label="Password"
//                             className={classes.textField}
//                             type="password"
//                             onChange={this.handleChange}
//                             autoComplete="new-password"
//                             margin="normal"
//                             required={true}
//                         />
//                     </CardContent>
//                     <CardActions className={classes.cardActions}>
//                         <Button disabled={inProgress} size="small" className={classes.flex} component={Link} to="/login">Cancel</Button>
//                         <Button disabled={inProgress} type="submit" variant="raised" color="primary" className={[classes.button, classes.flex]}>
//                             Register
//                             {inProgress && <CircularProgress size={24} className={classes.buttonProgress} />}
//                         </Button>
//                     </CardActions>
//                 </Card>
//             </form>
//         )
//     }
// }

// RegisterForm.propTypes = {
//     classes: PropTypes.object.isRequired,
//     inProgress: PropTypes.bool,
//     onRegister: PropTypes.func.isRequired
// };

// export default withStyles(styles)(RegisterForm);