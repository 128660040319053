import { connect } from 'react-redux';

import React, { Component } from 'react';
import {  BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'typeface-roboto';

import PropTypes from 'prop-types';

import {  Login, Register, ForgotPassword, ResetPassword, SecurityCode, Main } from 'containers';
import AuthRoute from 'components/AuthRoute';
import ToastMessage from 'components/ToastMessage';

export class App extends Component {
    state = {
        success: null,
        error: null
    }

    initialState() {
        const { success, error } = this.props;

        this.setState({ 
            success, error
        });
    }

    componentDidMount() {
        this.initialState();
    }

    componentWillReceiveProps(nextProps) {
        const { success, error } = nextProps;

        this.setState({ 
            success, error
        });
    }

    handleClose = () => {
        this.setState({
            success: null,
            error: null   
        })
    }

    render() {
        const { success, error } = this.state;

        return (
            <div>
                <Router>
                    <Switch>
                        <Route path="/login" component={Login} />
                        <Route path="/register" component={Register} />
                        <Route path="/verify" component={SecurityCode} />
                        <Route path="/forgot" component={ForgotPassword} />
                        <Route path="/reset" component={ResetPassword} />
                        <AuthRoute path="/" component={Main} />
                    </Switch>
                </Router>
                {(success && success.message) ? (
                    <ToastMessage variant="success" message={success.message} onClose={this.handleClose}/>
                ): null}
                {(error && error.message) ? (
                    <ToastMessage variant="error" message={error.message} onClose={this.handleClose}/>
                ): null}
            </div>
        );
    }
}


App.propTypes = {
    success: PropTypes.object,
    error: PropTypes.object
};

const mapStateToProps = state => ({
    success: state.status.success,
    error: state.status.error
});

export default connect(
	mapStateToProps
)(App);