import * as Types from 'constants/ActionTypes';

const mobileprovisions = (state = {
    inProgress: false,
    didInvalidate: false,
    data: []
}, action) => {
    switch (action.type) {
        case Types.REQUEST_FETCH_MOBILE_PROVISIONS:
        case Types.REQUEST_CREATE_MOBILE_PROVISION:
            return Object.assign({}, state, {
                inProgress: true,
                didInvalidate: false
            });
        case Types.RESPONSE_FETCH_MOBILE_PROVISIONS_SUCCESS:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: action.mobileprovisions,
                lastUpdated: action.receivedAt
            });
        case Types.RESPONSE_CREATE_MOBILE_PROVISION_SUCCESS:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: [...state.data, action.mobileprovision],
                // lastId: action.mobileprovision._id,
                lastUpdated: action.receivedAt
            });
        case Types.RESPONSE_DELETE_MOBILE_PROVISION_SUCCESS:
            action.mobileprovisions.forEach(mobileprovision => state.data.splice(state.data.findIndex(p => (p._id === mobileprovision._id)), 1))
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: [...state.data],
                lastUpdated: action.receivedAt
            });
        case Types.RESPONSE_FETCH_MOBILE_PROVISIONS_FAILURE:
        case Types.RESPONSE_CREATE_MOBILE_PROVISION_FAILURE:
        case Types.RESPONSE_DELETE_MOBILE_PROVISION_FAILURE:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                lastUpdated: action.receivedAt
            });
        default:
            return state
    }
};

export default mobileprovisions;