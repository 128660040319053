import {
    REQUEST_FETCH_CERTIFICATES,
    RESPONSE_FETCH_CERTIFICATES_SUCCESS,
    RESPONSE_FETCH_CERTIFICATES_FAILURE
} from 'actions';

const certificates = (state = {
    inProgress: false,
    didInvalidate: false,
    data: []
}, action) => {
    switch (action.type) {
        case REQUEST_FETCH_CERTIFICATES:
            return Object.assign({}, state, {
                inProgress: true,
                didInvalidate: false
            });
        case RESPONSE_FETCH_CERTIFICATES_SUCCESS:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: action.certificates,
                lastUpdated: action.receivedAt
            });
        case RESPONSE_FETCH_CERTIFICATES_FAILURE:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                lastUpdated: action.receivedAt
            });
        default:
            return state
    }
};

export default certificates;