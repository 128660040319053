import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {  withStyles  } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';

// import InfoIcon from '@material-ui/icons/Info';

const styles = theme => ({
    input: {
        //Instead of display: none to prevent Chrome of required hidden fields
        opacity: 0,
        height: 1,
        width: 1
    },
    button: {
        // marginTop: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3
    },
    typo: {
        display: "inline-block",
        // position: "absolute",
        marginTop: theme.spacing.unit,
        marginRight: 2,
        maxWidth: 270,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },
    avatar: {
        display: "inline-block",
        // position: "absolute",
        // marginTop: 22
    }
});

class FileField extends Component {
    constructor(props) {
        super(props);
    }
    handleChange = (e) => {
        const { onChange } = this.props;
        const { id, files } = e.target;
        const file = files[0];

        return new Promise((resolve, reject) => {
            if (file) {
                const  reader = new FileReader();
                
                reader.onload = () => {
                    const value = {
                        name: file.name,
                        encoding: "base64",
                        mimeType: file.type,
                        content: reader.result
                    };
                    const event = { target: {id, value} };
                    
                    onChange(event);
                    resolve(event);
                }
                reader.onerror = reject;
                reader.readAsDataURL(file);
            }
        })
    }
    render() {
        const { classes, id, text, accept, fullWidth, margin, defaultValue } = this.props;

        return (
            <FormControl
                fullWidth={fullWidth}
                margin={margin}
            >
                <Input
                    type="file"
                    id={id}
                    name={id}
                    className={classes.input}
                    accept={accept || "*"}
                    fullWidth={fullWidth}
                    onChange={this.handleChange}
                />
                <label style={{display: 'inline-flex'}} htmlFor={id}>
                    <Button variant="contained" component="span" className={classes.button}>
                        {text}
                    </Button>
                    {!defaultValue ? (<span/>) : (/[\/](gif|jpg|jpeg|tiff|png)$/i).test(defaultValue.mimeType) ? (
                        <Avatar src={defaultValue.content} className={classes.avatar}/>
                    ) : (
                        <Typography variant="subtitle1" component="span" className={classes.typo}>
                            {defaultValue.name}
                        </Typography>
                    )}
                    {/* <Button color="primary" style={{ backgroundColor: 'transparent' }} disableRipple ><InfoIcon/></Button> */}
                </label>
            </FormControl>
        )
    }
}

FileField.defaultProps = {
}

FileField.propTypes = {
    classes: PropTypes.object.isRequired,
    text: PropTypes.string.isRequired, 
    id: PropTypes.string.isRequired,
    accept: PropTypes.string,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    fullWidth: PropTypes.bool,
    margin: PropTypes.string,
    onChange: PropTypes.func.isRequired
}

export default withStyles(styles)(FileField)