import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {  withStyles  } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    root: {
        // backgroundColor: 'rgba(0, 0, 0, .2)',
        zIndex: 10000,
        display: "block",
        position: "absolute",
        textAlign: "center",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
    },
    progress: {
        position: "absolute",
        top: "calc(50% - 25px)"
    }
});

class ProgressMask extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <CircularProgress className={classes.progress} size={50} />
            </div>
        )
    }
}

ProgressMask.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProgressMask);