import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {  withStyles  } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import { ExpansionPanel, ExpansionPanelSummary, Typography, ExpansionPanelDetails, Divider, ExpansionPanelActions, Button, TextField, Tooltip, FormControlLabel, Checkbox, 
    Avatar, Icon, Select, Input, FormControl, InputLabel, MenuItem, Popover, Card, CardContent, List, ListItem, ListItemText, ListSubheader } from '@material-ui/core';

import grey from '@material-ui/core/colors/grey';

const styles = theme => ({
    title: {
        marginLeft: theme.spacing.unit * 2
    },
    icon: {
        backgroundColor: theme.palette.text.secondary
    },
    disabledIcon: {},
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    details: {
        width: '100%'
    },
    actions: {
        paddingLeft: theme.spacing.unit * 3,
        paddingRight: theme.spacing.unit * 3
    },
    spacer: {
        flexGrow: 1
    },
    formControl: {},
    select: {
        width: `calc(100% - ${theme.spacing.unit * 10}px)`, 
        marginRight: theme.spacing.unit
    },
    trigger: {
        color: grey[600],
        backgroundColor: 'transparent', 
        padding: 0, 
        minWidth: theme.spacing.unit * 4   
    },
    fileInput: {
        //Instead of display: none to prevent Chrome of required hidden fields
        opacity: 0,
        height: 1,
        width: 1
    }
})

const InputLabelProps = { 
    required: false //Hide asterix on required field
}

const bundleMatch = (bundleId, appId) => {
    const pattern = `(${appId.replace(/\./g, '\\.').replace(/\*/g, '\\w+')})`
    return (new RegExp(pattern)).test(bundleId);
}

const FileInfo = [{
    label: 'Name',
    key: 'name',
    format: (context, value) => value
}, {
    label: 'Created at',
    key: 'creationDate',
    format: (context, value) => {
        return (new Date(Date.parse(value))).toUTCString()
    }
}, {
    label: 'Expires in',
    key: 'expirationDate',
    format: (context, value) => {
        const now = new Date();
        const expDate = new Date(Date.parse(value));
        const months = new Date(expDate - now).getMonth();
        const days = Math.round((expDate - now)/(1000*60*60*24));

        return (months > 0) ? `${months} month(s)` : `${days} day(s)`
    }
}, {
    label: 'App ID',
    key: 'entitlements',
    format: (context, value) => {
        const task = context.tasks.find(t => t.type === 'props');
        const bundleId = (task && task.props) ? task.props['CFBundleIdentifier'] : null;
        let appId = value['application-identifier'];

        if (bundleId && appId) {
            const index = appId.indexOf('.')

            appId = appId.substr(index + 1, appId.length - index)
            // return (!!bundleId.match(new RegExp(appId))) ? `${appId} matches bundle id` : `${appId} does not match bundle id`;
            return (bundleMatch(bundleId, appId)) ? `${appId} matches bundle id` : `${appId} does not match bundle id`;
        }
        return '';
    }   
}]

const filterCertificates = certificates => c1 => (certificates.findIndex(c2 => c1.key === c2.sha1Key || c1.key === c2.sha256Key) > -1)

class SigningTaskForm extends Component {
    state = {
        stage: {},
        task: {},
        dirty: false,
        anchorEl: null,
        mobileprovision: null,
        certificates: [],
        certs: []
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const nextStage = nextProps.stage;
        const { stage } = prevState;
        
        if (nextStage && nextStage._id !== stage._id) {
            const nextTask = nextProps.task;
            const { signing, ...others } = nextTask;
            const { mobileprovisions, certificates } = nextProps;
            const mobileprovision = (signing && signing.mobileprovision) ? mobileprovisions.find(mobileprovision => mobileprovision._id === signing.mobileprovision._id) : null;

            return {  
                stage: {...nextStage},
                task: (signing) ? { signing: {...signing}, ...others} : {},
                dirty: false,
                mobileprovision: (mobileprovision) ? {...mobileprovision} : null,
                certs: (mobileprovision) ? 
                    [...mobileprovision.metadata.developerCertificates.filter(filterCertificates(certificates))] : 
                    // [...certificates]
                    []
            }
        }
        return null;
    }

    componentDidMount() {
        const { onShow } = this.props;
        
        if (onShow) onShow().then(() => {
            const { task, mobileprovisions, certificates } = this.props;
            const { signing } = task;

            if (signing.mobileprovision) {
                const mobileprovision = mobileprovisions.find(mobileprovision => mobileprovision._id === signing.mobileprovision._id)

                this.setState({
                    mobileprovision: (mobileprovision) ? {...mobileprovision} : null,
                    certs: (mobileprovision) ? 
                        [...mobileprovision.metadata.developerCertificates.filter(filterCertificates(certificates))] : 
                        // [...certificates]
                        []
                })
            }
        })
    }

    mobileProvisionChanged(mobileprovision) {
        const { certificates } = this.props;
        const { task } = this.state;
        let certs;
                
        task.signing.mobileprovision = { _id: mobileprovision._id }
        certs = (mobileprovision) ? [...mobileprovision.metadata.developerCertificates.filter(filterCertificates(certificates))] : [...certificates];
        if (certs.length === 1) task.signing.certificate = certs[0]
        this.setState({ 
            task: {...task}, 
            mobileprovision: {...mobileprovision},
            dirty: true, 
            certs
        })
    }

    handleFileChange = (e) => {
        const { onMobileProvisionCreate } = this.props;
        const { files } = e.target;
        const file = files[0];

        if (file) {
            const  reader = new FileReader();
            
            reader.onload = () => {
                const value = {
                    name: file.name,
                    encoding: "base64",
                    mimeType: file.type,
                    content: reader.result
                };

                if (onMobileProvisionCreate) onMobileProvisionCreate(value).then(() => {
                    const { mobileprovisions } = this.props;
                    const mobileprovision = mobileprovisions[mobileprovisions.length - 1];

                    if (mobileprovision) {
                        this.mobileProvisionChanged(mobileprovision)
                    }
                })
            }
            reader.readAsDataURL(file)
        }
    }

    handleMobileProvisionChange = event => {
        const { name, value } = event.target;

        if (name && value) {
            const { mobileprovisions } = this.props;
            const mobileprovision = mobileprovisions.find(mobileprovision => mobileprovision._id === value);

            if (mobileprovision) {
                this.mobileProvisionChanged(mobileprovision)
            }
        }
    }

    handleFileInfo = event => {
        this.setState({ anchorEl: event.currentTarget });
    }

    handleFileInfoClose = () => {
        this.setState({ anchorEl: null });
    }
    
    handleCertificateChange = event => {
        const { name, value } = event.target;

        if (name && value) {
            const { certificates } = this.props;
            const { task } = this.state;
            const certificate = certificates.find(certificate => certificate.key === value);

            if (certificate) {
                task.signing.certificate = certificate
                this.setState({ task, dirty: true })
            }
        }   
    }

    handlePanelChange = (event, expanded) => {
        const { dirty } = this.state;

        if (!expanded && dirty) {
            this.initialState()
        }
    }

    handleDisabledChange = event => {
        const { task } = this.state;
        const { onSave } = this.props;

        task.disabled = event.target.checked 
        event.preventDefault();
        if (onSave) {
            onSave(task)
        }
    }

    handleDelete = event => {
        const { task } = this.state;
        const { onDelete } = this.props;

        event.preventDefault();
        if (onDelete) {
            onDelete(task)
        }
    }

    handleSave = event => {
        const { task } = this.state;
        const { onSave } = this.props;

        event.preventDefault();
        if (onSave) {
            task.invalid = false
            onSave(task)
            .then(() => this.setState({ dirty: false }))
        }
    }

    render() {
        const { classes, mobileprovisions } = this.props;
        const { stage, certs, task, mobileprovision, dirty, anchorEl } = this.state;
        // const mobileprovision = task.signing && task.signing.mobileprovision;
        const certificate = task.signing && task.signing.certificate;
        const open = Boolean(anchorEl);

        return (
            <ExpansionPanel onChange={this.handlePanelChange}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Avatar className={(task.disabled) ? classes.disabledIcon : classes.icon}>
                        <Icon>{task.icon}</Icon>
                    </Avatar>
                    <div className={classes.title}>
                        <Typography className={classes.heading} color={(task.disabled) ? "textSecondary" : "default"}>{task.name}</Typography>
                        <Typography variant="caption" color="textSecondary">{task.description}</Typography>
                    </div>
                </ExpansionPanelSummary>
                {/* <Divider /> */}
                <ExpansionPanelDetails>
                    <div className={classes.details}>
                        <FormControl className={classes.formControl}
                            margin="dense" 
                            fullWidth
                        >
                            <div>
                                <InputLabel required={false} htmlFor="mobileprovision">Provisioning Profile</InputLabel>
                                <Select
                                    required={true}
                                    className={classes.select}
                                    value={mobileprovision ? mobileprovision._id : ''}
                                    inputProps={{
                                        name: 'mobileprovision',
                                        id: 'mobileprovision',
                                    }}
                                    onChange={this.handleMobileProvisionChange}
                                >
                                    {(mobileprovisions && mobileprovisions.length > 0) ? 
                                        mobileprovisions.map((mobileprovision, index) =>
                                            <MenuItem key={index} value={mobileprovision._id}>{mobileprovision.metadata.name}</MenuItem>
                                        )
                                    : (
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                    )}
                                </Select>
                                <Input
                                    type="file"
                                    id="file"
                                    name="file"
                                    className={classes.fileInput}
                                    accept="*" //TODO
                                    onChange={this.handleFileChange}
                                />
                                <label htmlFor="file">
                                    <Tooltip title="Import Profile">
                                        <Button className={classes.trigger} component="span"><Icon>add</Icon></Button>
                                    </Tooltip>
                                </label>
                                <Button 
                                    aria-owns={open ? 'file-info' : null}
                                    aria-haspopup="true" 
                                    className={classes.trigger} 
                                    onClick={this.handleFileInfo}
                                    disabled={!mobileprovision}
                                >
                                    <InfoIcon/>
                                </Button>
                                {(mobileprovision && open) ? (
                                    <Popover
                                        id="file-info"
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={this.handleFileInfoClose}
                                        anchorOrigin={{
                                            vertical: 'center',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'center',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <List subheader={<ListSubheader>Provisioning Profile</ListSubheader>} className={classes.list}>
                                            {FileInfo.map((prop, index) => 
                                                <ListItem key={index}>
                                                    <ListItemText primary={prop.label} secondary={prop.format(stage, mobileprovision.metadata[prop.key])} />
                                                </ListItem>
                                            )}
                                        </List>
                                    </Popover>
                                ) : null }
                            </div>
                        </FormControl>
                        <TextField 
                            InputLabelProps={InputLabelProps}
                            InputProps={{
                                readOnly: true,
                            }}
                            autoComplete="off"
                            margin="dense"
                            id="team" 
                            label="Team"
                            value={((mobileprovision) ? mobileprovision.metadata.teamName : 'None')} 
                            fullWidth
                        />
                        {(certs && certs.length === 1) ? (
                            <TextField 
                                InputLabelProps={InputLabelProps}
                                InputProps={{
                                    readOnly: true,
                                }}
                                autoComplete="off"
                                margin="dense"
                                id="certificate" 
                                label="Signing Certificate"
                                value={certs[0].name} 
                                fullWidth
                            />
                        ) : (
                            <FormControl className={classes.formControl} margin="dense" fullWidth>
                                <InputLabel htmlFor="certificate">Signing Certificate</InputLabel>
                                <Select
                                    value={(certificate) ? certificate.key : ''}
                                    onChange={this.handleCertificateChange}
                                    inputProps={{
                                        name: 'certificate',
                                        id: 'certificate',
                                    }}>
                                    {(certs && certs.length > 0) ? 
                                        certs.map((cert, index) => 
                                            <MenuItem key={index} value={cert.key}>
                                                {cert.name}
                                            </MenuItem>
                                        )
                                    : (
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        )}
                    </div>
                </ExpansionPanelDetails>
                <ExpansionPanelActions className={classes.actions}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={!!task.disabled}
                                onChange={this.handleDisabledChange}
                            />
                        }
                        label="Disabled"
                    />
                    <div className={classes.spacer}/>
                    <Button 
                        size="small"
                        onClick={this.handleDelete}
                    >
                        Delete
                    </Button>
                    <Button 
                        size="small" 
                        variant="contained" 
                        color="primary" 
                        disabled={!dirty && !task.invalid}
                        onClick={this.handleSave}
                    >
                        Save
                    </Button>
                </ExpansionPanelActions>
            </ExpansionPanel>
        )
    }
}

SigningTaskForm.propTypes = {
    classes: PropTypes.object.isRequired,
    mobileprovisions: PropTypes.arrayOf(PropTypes.object).isRequired,
    certificates: PropTypes.arrayOf(PropTypes.object).isRequired,
    project: PropTypes.object,
    stage: PropTypes.object,
    task: PropTypes.object,
    onShow: PropTypes.func,
    onSave: PropTypes.func,
    onDelete: PropTypes.func,
    onMobileProvisionCreate: PropTypes.func
}

export default withStyles(styles)(SigningTaskForm)

// import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import _ from 'lodash';
// import {  withStyles  } from '@material-ui/core/styles';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import InfoIcon from '@material-ui/icons/Info';
// import { ExpansionPanel, ExpansionPanelSummary, Typography, ExpansionPanelDetails, Divider, ExpansionPanelActions, Button, TextField, Tooltip, FormControlLabel, Checkbox, 
//     Avatar, Icon, Select, Input, FormControl, InputLabel, MenuItem, Popover, Card, CardContent, List, ListItem, ListItemText, ListSubheader } from '@material-ui/core';

// import grey from '@material-ui/core/colors/grey';

// const styles = theme => ({
//     title: {
//         marginLeft: theme.spacing.unit * 2
//     },
//     icon: {
//         backgroundColor: theme.palette.text.secondary
//     },
//     disabledIcon: {},
//     heading: {
//         fontSize: theme.typography.pxToRem(15),
//         fontWeight: theme.typography.fontWeightRegular,
//     },
//     details: {
//         width: '100%'
//     },
//     actions: {
//         paddingLeft: theme.spacing.unit * 3,
//         paddingRight: theme.spacing.unit * 3
//     },
//     spacer: {
//         flexGrow: 1
//     },
//     formControl: {},
//     select: {
//         width: `calc(100% - ${theme.spacing.unit * 10}px)`, 
//         marginRight: theme.spacing.unit
//     },
//     trigger: {
//         color: grey[600],
//         backgroundColor: 'transparent', 
//         padding: 0, 
//         minWidth: theme.spacing.unit * 4   
//     },
//     fileInput: {
//         //Instead of display: none to prevent Chrome of required hidden fields
//         opacity: 0,
//         height: 1,
//         width: 1
//     }
// })

// const InputLabelProps = { 
//     required: false //Hide asterix on required field
// }

// const bundleMatch = (bundleId, appId) => {
//     const pattern = `(${appId.replace(/\./g, '\\.').replace(/\*/g, '\\w+')})`
//     return (new RegExp(pattern)).test(bundleId);
// }

// const FileInfo = [{
//     label: 'Name',
//     key: 'name',
//     format: (context, value) => value
// }, {
//     label: 'Created at',
//     key: 'creationDate',
//     format: (context, value) => {
//         return (new Date(Date.parse(value))).toUTCString()
//     }
// }, {
//     label: 'Expires in',
//     key: 'expirationDate',
//     format: (context, value) => {
//         const now = new Date();
//         const expDate = new Date(Date.parse(value));
//         const months = new Date(expDate - now).getMonth();

//         return `${months} month(s)`
//     }
// }, {
//     label: 'App ID',
//     key: 'entitlements',
//     format: (project, value) => {
//         const bundleId = (project.asset && project.asset.props) ? project.asset.props['CFBundleIdentifier'] : null;
//         let appId = value['application-identifier'];

//         if (bundleId && appId) {
//             const index = appId.indexOf('.')

//             appId = appId.substr(index + 1, appId.length - index)
//             // return (!!bundleId.match(new RegExp(appId))) ? `${appId} matches bundle id` : `${appId} does not match bundle id`;
//             return (bundleMatch(bundleId, appId)) ? `${appId} matches bundle id` : `${appId} does not match bundle id`;
//         }
//         return '';
//     }   
// }]

// const filterCertificates = certificates => c1 => (certificates.findIndex(c2 => c1.key === c2.key) > -1)

// class SigningTaskForm extends Component {
//     state = {
//         stage: {},
//         task: {},
//         dirty: false,
//         anchorEl: null,
//         mobileprovision: null,
//         certificates: [],
//         certs: []
//     }

//     static getDerivedStateFromProps(nextProps, prevState) {
//         const nextStage = nextProps.stage;
//         const nextCertificates = nextProps.certificates;
//         const { stage, certificates } = prevState;
        
//         if ((nextStage && nextStage._id !== stage._id) || (nextCertificates && !_.isEqual(nextCertificates, certificates))) {
//             const nextTask = nextProps.task;
//             const { signing, ...others } = nextTask;

//             return {  
//                 stage: {...nextStage},
//                 task: (signing) ? { signing: {...signing}, ...others} : {},
//                 dirty: false, 
//                 certificates: nextCertificates,
//                 certs: (signing && signing.mobileprovision) ? 
//                     [...signing.mobileprovision.metadata.developerCertificates.filter(filterCertificates(nextCertificates))] : 
//                     [...nextCertificates]  
//             }
//         }
//         return null;
//     }

//     componentDidMount() {
//         const { onShow } = this.props;
        
//         if (onShow) onShow()
//     }

//     handleFileChange = (e) => {
//         const { onMobileProvisionCreate } = this.props;
//         const { files } = e.target;
//         const file = files[0];

//         if (file) {
//             const  reader = new FileReader();
            
//             reader.onload = () => {
//                 const value = {
//                     name: file.name,
//                     encoding: "base64",
//                     mimeType: file.type,
//                     content: reader.result
//                 };

//                 if (onMobileProvisionCreate) onMobileProvisionCreate(value)
//             }
//             reader.readAsDataURL(file)
//         }
//     }

//     handleMobileProvisionChange = event => {
//         const { name, value } = event.target;

//         if (name && value) {
//             const { mobileprovisions, certificates } = this.props;
//             const { task } = this.state;
//             const mobileprovision = mobileprovisions.find(mobileprovision => mobileprovision._id === value);

//             if (mobileprovision) {
//                 let certs;
                
//                 task.signing.mobileprovision = mobileprovision
//                 certs = (mobileprovision) ? [...mobileprovision.metadata.developerCertificates.filter(filterCertificates(certificates))] : [];
//                 if (certs.length === 1) task.signing.certificate = certs[0]
//                 this.setState({ 
//                     task: {...task}, 
//                     dirty: true, 
//                     certs
//                 })   
//             }
//         }
//     }

//     handleFileInfo = event => {
//         this.setState({ anchorEl: event.currentTarget });
//     }

//     handleFileInfoClose = () => {
//         this.setState({ anchorEl: null });
//     }
    
//     handleCertificateChange = event => {
//         const { name, value } = event.target;

//         if (name && value) {
//             const { certificates } = this.props;
//             const { task } = this.state;
//             const certificate = certificates.find(certificate => certificate.key === value);

//             if (certificate) {
//                 task.signing.certificate = certificate
//                 this.setState({ task, dirty: true })
//             }
//         }   
//     }

//     handlePanelChange = (event, expanded) => {
//         const { dirty } = this.state;

//         if (!expanded && dirty) {
//             this.initialState()
//         }
//     }

//     handleDisabledChange = event => {
//         const { task } = this.state;
//         const { onSave } = this.props;

//         task.disabled = event.target.checked 
//         event.preventDefault();
//         if (onSave) {
//             onSave(task)
//         }
//     }

//     handleDelete = event => {
//         const { task } = this.state;
//         const { onDelete } = this.props;

//         event.preventDefault();
//         if (onDelete) {
//             onDelete(task)
//         }
//     }

//     handleSave = event => {
//         const { task } = this.state;
//         const { onSave } = this.props;

//         event.preventDefault();
//         if (onSave) {
//             onSave(task)
//             .then(() => this.setState({ dirty: false }))
//         }
//     }

//     render() {
//         const { classes, project, mobileprovisions } = this.props;
//         const { certs, task, dirty, anchorEl } = this.state;
//         const mobileprovision = task.signing && task.signing.mobileprovision;
//         const certificate = task.signing && task.signing.certificate;
//         const open = Boolean(anchorEl);

//         return (
//             <ExpansionPanel onChange={this.handlePanelChange}>
//                 <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
//                     <Avatar className={(task.disabled) ? classes.disabledIcon : classes.icon}>
//                         <Icon>{task.icon}</Icon>
//                     </Avatar>
//                     <div className={classes.title}>
//                         <Typography className={classes.heading} color={(task.disabled) ? "textSecondary" : "default"}>{task.name}</Typography>
//                         <Typography variant="caption" color="textSecondary">{task.description}</Typography>
//                     </div>
//                 </ExpansionPanelSummary>
//                 {/* <Divider /> */}
//                 <ExpansionPanelDetails>
//                     <div className={classes.details}>
//                         <FormControl className={classes.formControl}
//                             margin="dense" 
//                             fullWidth
//                         >
//                             <div>
//                                 <InputLabel required={false} htmlFor="mobileprovision">Provisioning Profile</InputLabel>
//                                 <Select
//                                     required={true}
//                                     className={classes.select}
//                                     value={mobileprovision ? mobileprovision._id : ''}
//                                     inputProps={{
//                                         name: 'mobileprovision',
//                                         id: 'mobileprovision',
//                                     }}
//                                     onChange={this.handleMobileProvisionChange}
//                                 >
//                                     {(mobileprovisions && mobileprovisions.length > 0) ? 
//                                         mobileprovisions.map((mobileprovision, index) =>
//                                             <MenuItem key={index} value={mobileprovision._id}>{mobileprovision.metadata.name}</MenuItem>
//                                         )
//                                     : (
//                                         <MenuItem value="">
//                                             <em>None</em>
//                                         </MenuItem>
//                                     )}
//                                 </Select>
//                                 <Input
//                                     type="file"
//                                     id="file"
//                                     name="file"
//                                     className={classes.fileInput}
//                                     accept="*" //TODO
//                                     onChange={this.handleFileChange}
//                                 />
//                                 <label htmlFor="file">
//                                     <Tooltip title="Import Profile">
//                                         <Button className={classes.trigger} component="span"><Icon>add</Icon></Button>
//                                     </Tooltip>
//                                 </label>
//                                 <Button 
//                                     aria-owns={open ? 'file-info' : null}
//                                     aria-haspopup="true" 
//                                     className={classes.trigger} 
//                                     onClick={this.handleFileInfo}
//                                     disabled={!mobileprovision}
//                                 >
//                                     <InfoIcon/>
//                                 </Button>
//                                 {(mobileprovision && open) ? (
//                                     <Popover
//                                         id="file-info"
//                                         open={open}
//                                         anchorEl={anchorEl}
//                                         onClose={this.handleFileInfoClose}
//                                         anchorOrigin={{
//                                             vertical: 'center',
//                                             horizontal: 'right',
//                                         }}
//                                         transformOrigin={{
//                                             vertical: 'center',
//                                             horizontal: 'left',
//                                         }}
//                                     >
//                                         <List subheader={<ListSubheader>Provisioning Profile</ListSubheader>} className={classes.list}>
//                                             {FileInfo.map((prop, index) => 
//                                                 <ListItem key={index}>
//                                                     <ListItemText primary={prop.label} secondary={prop.format(project, mobileprovision.metadata[prop.key])} />
//                                                 </ListItem>
//                                             )}
//                                         </List>
//                                     </Popover>
//                                 ) : null }
//                             </div>
//                         </FormControl>
//                         <TextField 
//                             InputLabelProps={InputLabelProps}
//                             InputProps={{
//                                 readOnly: true,
//                             }}
//                             autoComplete="off"
//                             margin="dense"
//                             id="team" 
//                             label="Team"
//                             value={((mobileprovision) ? mobileprovision.metadata.teamName : 'None')} 
//                             fullWidth
//                         />
//                         {(certs && certs.length === 1) ? (
//                             <TextField 
//                                 InputLabelProps={InputLabelProps}
//                                 InputProps={{
//                                     readOnly: true,
//                                 }}
//                                 autoComplete="off"
//                                 margin="dense"
//                                 id="certificate" 
//                                 label="Signing Certificate"
//                                 value={certs[0].name} 
//                                 fullWidth
//                             />
//                         ) : (
//                             <FormControl className={classes.formControl} margin="dense" fullWidth>
//                                 <InputLabel htmlFor="certificate">Signing Certificate</InputLabel>
//                                 <Select
//                                     value={(certificate) ? certificate.key : ''}
//                                     onChange={this.handleCertificateChange}
//                                     inputProps={{
//                                         name: 'certificate',
//                                         id: 'certificate',
//                                     }}>
//                                     {(certs && certs.length > 0) ? 
//                                         certs.map((cert, index) => 
//                                             <MenuItem key={index} value={cert.key}>
//                                                 {cert.name}
//                                             </MenuItem>
//                                         )
//                                     : (
//                                         <MenuItem value="">
//                                             <em>None</em>
//                                         </MenuItem>
//                                     )}
//                                 </Select>
//                             </FormControl>
//                         )}
//                     </div>
//                 </ExpansionPanelDetails>
//                 <ExpansionPanelActions className={classes.actions}>
//                     <FormControlLabel
//                         control={
//                             <Checkbox
//                                 checked={!!task.disabled}
//                                 onChange={this.handleDisabledChange}
//                             />
//                         }
//                         label="Disabled"
//                     />
//                     <div className={classes.spacer}/>
//                     <Button 
//                         size="small"
//                         onClick={this.handleDelete}
//                     >
//                         Delete
//                     </Button>
//                     <Button 
//                         size="small" 
//                         variant="contained" 
//                         color="primary" 
//                         disabled={!dirty}
//                         onClick={this.handleSave}
//                     >
//                         Save
//                     </Button>
//                 </ExpansionPanelActions>
//             </ExpansionPanel>
//         )
//     }
// }

// SigningTaskForm.propTypes = {
//     classes: PropTypes.object.isRequired,
//     mobileprovisions: PropTypes.arrayOf(PropTypes.object).isRequired,
//     certificates: PropTypes.arrayOf(PropTypes.object).isRequired,
//     project: PropTypes.object,
//     stage: PropTypes.object,
//     task: PropTypes.object,
//     onShow: PropTypes.func,
//     onSave: PropTypes.func,
//     onDelete: PropTypes.func,
//     onMobileProvisionCreate: PropTypes.func
// }

// export default withStyles(styles)(SigningTaskForm)