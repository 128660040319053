import rpc from 'services/rpc';
import * as Types from 'constants/ActionTypes';

const DOWNLOAD_ASSETS_SUCCESS = 'Yay! Asset(s) downloaded successfully';

function requestFetchAssets() {
    return {
        type: Types.REQUEST_FETCH_ASSETS
    }
};

function responseFetchAssetsSuccess(json) {
    return {
        type: Types.RESPONSE_FETCH_ASSETS_SUCCESS,
        assets: json,
        receivedAt: Date.now()
    };
};

function responseFetchAssetsFailure(error) {
    return {
        type: Types.RESPONSE_FETCH_ASSETS_FAILURE,
        error,
        receivedAt: Date.now()
    };
};

function requestDownload() {
    return {
        type: Types.REQUEST_DOWNLOAD_ASSETS
    }
};

function responseDownloadSuccess(json) {
    return {
        type: Types.RESPONSE_DOWNLOAD_ASSETS_SUCCESS,
        assets: json,
        success: {
            message: DOWNLOAD_ASSETS_SUCCESS
        },
        receivedAt: Date.now()
    };
};

function responseDownloadFailure(error) {
    return {
        type: Types.RESPONSE_DOWNLOAD_ASSETS_FAILURE,
        error,
        receivedAt: Date.now()
    };
}

// Do not use catch, because that will also catch
// any errors in the dispatch and resulting render,
// causing a loop of 'Unexpected batch number' errors.
// https://github.com/facebook/react/issues/6895

export function fetchAssets(ids) {
    return function(dispatch) {
        dispatch(requestFetchAssets());

        return rpc.get('assets', { _id: { $in: ids }})
        .then(response => {
            const { data, error } = response.result()

            if (error) {
                dispatch(responseFetchAssetsFailure(error));
            }
            else {
                dispatch(responseFetchAssetsSuccess(data));
            }
        }, e => dispatch(responseFetchAssetsFailure({
            message: e.stderr ? e.stderr : e.message
        })))
    }
};

export function downloadAssets(assets) {
    return function(dispatch) {
        dispatch(requestDownload());

        return rpc.get('assets/download', assets)
        .then(response => {
            const { data, error } = response.result()

            if (error) {
                dispatch(responseDownloadFailure(error));
            }
            else {
                dispatch(responseDownloadSuccess(data));
            }
        }, e => dispatch(responseDownloadFailure({
            message: e.stderr ? e.stderr : e.message
        })))
    }
}