import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {  withStyles  } from '@material-ui/core/styles';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
    ///
    // fixed: {
    //     display: 'table',
    //     width: '100%',
    //     tableLayout: 'fixed'
    // }
    tableCell: {
        paddingTop: theme.spacing.unit / 2,
        paddingBottom: theme.spacing.unit / 2,
        paddingLeft: theme.spacing.unit + 4,
        paddingRight: theme.spacing.unit + 4
    }
});

class DataBrowserHead extends Component {
    createSortHandler = property => event => {
        const { onRequestSort } = this.props

        onRequestSort(event, property)
    }
  
    render() {
        const { classes, columns, onSelectAllClick, orderBy, numSelected, rowCount } = this.props;
  
        return (
            <TableHead className={classes.fixed}>
                <TableRow>
                    <TableCell style={{ width: 48 }} className={classes.tableCell} padding="checkbox">
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                        />
                    </TableCell>
                    {columns.map(column => {
                        const style = {};

                        if (column.width) style['width'] = column.width;
                        return (
                            <TableCell
                                style={style}
                                className={classes.tableCell}
                                key={column.id}
                                numeric={column.numeric}
                                padding={column.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy.property === column.id ? orderBy.direction : false}
                            >
                                <Tooltip
                                    title="Sort"
                                    placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy.property === column.id}
                                        direction={orderBy.direction}
                                        onClick={this.createSortHandler(column.id)}
                                    >
                                        {column.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        )
                    }, this)}
                    <TableCell  style={{ width: 48 }} className={classes.tableCell}/>   
                </TableRow>
            </TableHead>
        )
    }
}

DataBrowserHead.propTypes = {
    classes: PropTypes.object.isRequired,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    rowCount: PropTypes.number.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    orderBy: PropTypes.object.isRequired
}

export default withStyles(styles)(DataBrowserHead);