import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {  Link  } from 'react-router-dom';
import {  withStyles, createMuiTheme, MuiThemeProvider  } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import BrandPaper from 'components/BrandPaper';
import DarkTheme from 'components/themes/dark';
import green from '@material-ui/core/colors/green';

const styles = theme => ({
    form: {
        flexShrink: 0,
        width: theme.spacing.unit * 41
    },
    header: {
        textAlign: 'center',
        marginBottom: theme.spacing.unit * 4,
        '& > *': {
            padding: theme.spacing.unit / 2
        }
    },
    content: {
        flex: '1 0 auto',
    },
    textField: {
        width: theme.spacing.unit * 41
    },
    actions: {
        
    },
    inline: {
        display: 'inline-block',
        width: '100%'
    },
    link: {
        fontSize: '12px',
        display: 'block',
        textDecoration: 'none !important',
        float: 'right',
        marginTop: theme.spacing.unit * 2.5
    },
    buttons: {
        marginTop: theme.spacing.unit * 5
    },
    button: {
        width: theme.spacing.unit * 19.5
    },
    login: {
        marginLeft: theme.spacing.unit * 2
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -theme.spacing.unit - 4,
        marginLeft: -theme.spacing.unit - 4
    }
})

const InputLabelProps = { 
    required: false //Hide asterix on required field
}

class ResetPasswordForm extends Component {
    state = {
        isResend: false,
        isSubmit: false
    }

    initialState() {
        const { user } = this.props;
        
        this.setState({ 
            email: (user) ? user.local.email : null
        });
    }

    componentDidMount() {
        this.initialState();
    }

    handleChange = (e) => {
        const { id, value } = e.target;
        
        this.setState({ [id]: value });
    }

    handleResend = (e) => {
        const { user, onResend } = this.props;

        e.preventDefault();
        this.setState({
            isResend: true,
            isSubmit: false
        })
        if (user && user.local) {
            onResend(user.local.email);
        }
    }

    handleSubmit = (e) => {
        const { code, password } = this.state;
        const { user, onSubmit } = this.props;

        e.preventDefault();
        this.setState({
            isResend: false,
            isSubmit: true
        })
        if (user && user.local && code && password) {
            onSubmit(user.local.email, code, password);
        }
    }

    render() {
        const { classes, inProgress } = this.props
        const { isResend, isSubmit } = this.state

        return (
            <BrandPaper>
                <form className={classes.form} autoComplete="off" onSubmit={this.handleSubmit}>
                    <div className={classes.header}>
                        <Typography component="h5" variant="h4">
                            BulkAppResign
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                            Welcome! Please login to your account.
                        </Typography>
                    </div>
                    <div className={classes.content}>
                        <TextField
                            InputLabelProps={InputLabelProps}
                            autoFocus
                            id="email"
                            label="Email"
                            className={classes.textField}
                            type="email"
                            value={this.state.email || ''}
                            onChange={this.handleChange}
                            margin="normal"
                            required={true}
                            disabled
                        />
                        <br/>
                        <TextField
                            InputLabelProps={InputLabelProps}
                            id="code"
                            label="Code"
                            className={classes.textField}
                            onChange={this.handleChange}
                            margin="normal"
                            required={true}
                        />
                        <br/>
                        <TextField
                            InputLabelProps={InputLabelProps}
                            id="password"
                            label="Password"
                            className={classes.textField}
                            type="password"
                            onChange={this.handleChange}
                            autoComplete="current-password"
                            margin="normal"
                            required={true}
                        />
                    </div>
                    <div className={classes.actions}>
                        <DarkTheme>
                            <div className={classes.inline}>
                                <Button disabled={inProgress} className={classes.link} size="small" onClick={this.handleResend}>
                                    Resend code
                                    {inProgress && isResend && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </Button>
                            </div>
                            <div className={classes.buttons}>
                                <Button disabled={inProgress} size="small" variant="outlined" color="primary" className={classes.button} component={Link} to="/login">Cancel</Button>
                                <Button disabled={inProgress} type="submit" variant="contained" color="primary" className={`${classes.button} ${classes.login}`}>
                                    Send
                                    {inProgress && isSubmit && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </Button>   
                            </div> 
                        </DarkTheme>
                    </div>
                </form>
            </BrandPaper>
        )
    }
}

ResetPasswordForm.propTypes = {
    classes: PropTypes.object.isRequired,
    user: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        local: PropTypes.shape({
            email: PropTypes.string.isRequired    
        }).isRequired
    }).isRequired,
    inProgress: PropTypes.bool,
    onResend: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default withStyles(styles)(ResetPasswordForm);

// import React, { Component } from 'react';
// import {  Link  } from 'react-router-dom';
// import PropTypes from 'prop-types';
// import {  withStyles  } from '@material-ui/core/styles';

// import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
// import Typography from '@material-ui/core/Typography';
// import TextField from '@material-ui/core/TextField';
// import Button from '@material-ui/core/Button';

// import CircularProgress from '@material-ui/core/CircularProgress';
// import green from '@material-ui/core/colors/green';

// const styles = theme => ({
//     flex: {
//         flex: 1
//     },
//     root: {
//         margin: 0,
//         position: 'absolute',
//         top: '40%',
//         left: '50%',
//         marginRight: '-50%',
//         transform: 'translate(-50%, -50%)'
//     },
//     card: {
//         margin: 'auto',
//         maxWidth: 380,
//     },
//     cardActions: {
//         paddingLeft: theme.spacing.unit * 1.5,
//         paddingRight: theme.spacing.unit * 1.5
//     },
//     instruction: {
//         paddingTop: theme.spacing.unit * 1.5,
//         paddingBottom: theme.spacing.unit,
//         marginLeft: theme.spacing.unit,
//         marginRight: theme.spacing.unit
//     },
//     textField: {
//         marginLeft: theme.spacing.unit,
//         marginRight: theme.spacing.unit,
//         // width: 250,
//         width: '95%'
//     },
//     button: {
//         margin: theme.spacing.unit,
//     },
//     buttonProgress: {
//         color: green[500],
//         position: 'absolute',
//         top: '50%',
//         left: '50%',
//         marginTop: -12,
//         marginLeft: -12,
//     }
// });

// const InputLabelProps = { 
//     required: false //Hide asterix on required field
// }

// class ResetPasswordForm extends Component {

//     state = {
//         isResend: false,
//         isSubmit: false
//     }

//     initialState() {
//         const { user } = this.props;
        
//         this.setState({ 
//             email: (user) ? user.local.email : null
//         });
//     }

//     componentDidMount() {
//         this.initialState();
//     }

//     handleChange = (e) => {
//         const { id, value } = e.target;
        
//         this.setState({ [id]: value });
//     }

//     handleResend = (e) => {
//         const { user, onResend } = this.props;

//         e.preventDefault();
//         this.setState({
//             isResend: true,
//             isSubmit: false
//         })
//         onResend(user.local.email);
//     }

//     handleSubmit = (e) => {
//         const { code, password } = this.state;
//         const { user, onSubmit } = this.props;

//         e.preventDefault();
//         this.setState({
//             isResend: false,
//             isSubmit: true
//         })
//         onSubmit(user.local.email, code, password);
//     }

//     render() {
//         const { classes, inProgress } = this.props;
//         const { isResend, isSubmit } = this.state;

//         return (
//             <form className={classes.root} autoComplete="off" onSubmit={this.handleSubmit}>
//                 <Card className={classes.card}>
//                     <CardContent>
//                         <Typography gutterBottom variant="headline" component="h2">Reset Password</Typography>
//                         <div className={classes.instruction}>
//                             <Typography color="textSecondary" component="p">Enter the code received in your email and the new password</Typography>
//                         </div>
//                         <TextField
//                             InputLabelProps={InputLabelProps}
//                             autoFocus
//                             id="email"
//                             label="Email"
//                             className={classes.textField}
//                             type="email"
//                             value={this.state.email || ''}
//                             // onChange={this.handleChange}
//                             margin="normal"
//                             required={true}
//                             disabled
//                         />
//                         <TextField
//                             InputLabelProps={InputLabelProps}
//                             id="code"
//                             label="Code"
//                             className={classes.textField}
//                             onChange={this.handleChange}
//                             margin="normal"
//                             required={true}
//                         />
//                         <TextField
//                             InputLabelProps={InputLabelProps}
//                             id="password"
//                             label="New Password"
//                             className={classes.textField}
//                             type="password"
//                             onChange={this.handleChange}
//                             autoComplete="new-password"
//                             margin="normal"
//                             required={true}
//                         />
//                     </CardContent>
//                     <CardActions className={classes.cardActions}>
//                         <Button disabled={inProgress} size="small" className={classes.flex} component={Link} to="/login">Cancel</Button>
//                         <Button disabled={inProgress} size="small" className={classes.flex} onClick={this.handleResend}>
//                             Resend code
//                             {inProgress && isResend && <CircularProgress size={24} className={classes.buttonProgress} />}
//                         </Button>
//                         <Button disabled={inProgress} type="submit" variant="raised" color="primary" className={[classes.button, classes.flex]}>
//                             Send
//                             {inProgress && isSubmit && <CircularProgress size={24} className={classes.buttonProgress} />}
//                         </Button>
//                     </CardActions>
//                 </Card>
//             </form>
//         )
//     }
// }

// ResetPasswordForm.propTypes = {
//     classes: PropTypes.object.isRequired,
//     user: PropTypes.object.isRequired,
//     inProgress: PropTypes.bool,
//     onResend: PropTypes.func.isRequired,
//     onSubmit: PropTypes.func.isRequired
// };

// export default withStyles(styles)(ResetPasswordForm);