import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import {  withStyles  } from '@material-ui/core/styles';

// import Zoom from '@material-ui/core/Zoom';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Avatar  from '@material-ui/core/Avatar';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';

import { dateFormat } from 'helpers';
import DataBrowser from 'components/core/DataBrowser';
import ProjectForm from 'components/ProjectForm';
import ProgressMask from 'components/ProgressMask';
import { Button } from '@material-ui/core';

const columnsWithClasses = (classes, scope) => ([
    {   id: 'icon', width: 64, numeric: false, disablePadding: false, label: 'Icon', render: (column, item) => {
        const value = item[column.id];

        return (
            value ? 
            <Avatar style={{ backgroundColor: value.backgroundColor}} className={classes.avatar}><Typography style={{color:grey[100]}}>{value.typo}</Typography></Avatar> : 
            <Avatar/>
        )
    } },
    {   id: 'name', numeric: false, disablePadding: false, label: 'Name' },
    {   id: 'description', numeric: false, disablePadding: false, label: 'Description' },
    {   id: 'version', width: 72, numeric: false, disablePadding: false, label: 'Version' },
    {   id: 'createdAt', numeric: false, disablePadding: false, label: 'Created at', render: (column, item) => dateFormat(item[column.id], 'MMM D, YYYY [at] h:mm A') },
    {   id: 'build', numeric: false, disablePadding: false, label: 'Latest build', render: (column, item) => item[column.id] && (
        <Button className={classes[item[column.id].status]} onClick={event => scope.handleProjectBuildClick(event, item)}>{item[column.id].name}</Button>
    ) }
]);

const actions = scope => ([
    { id: 'edit', label: 'Edit', icon: 'description', handleClick: scope.handleEdit, disabled: selected => (selected.length > 1) }, 
    // { id: 'clone', label: 'Clone', icon: 'file_copy', handleClick: scope.handleClone, disabled: selected => (selected.length > 1) }, 
    { id: 'delete', label: 'Delete', icon: 'delete', handleClick: scope.handleDelete, },
    { id: 'build', label: 'Build', icon: 'play_circle_filled', handleClick: scope.handleBuild }
]);

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        flexGrow: 1,
        width: '100%'
    },
    avatar: {
        marginTop: theme.spacing.unit / 2 + 2,
        marginBottom: theme.spacing.unit / 2 + 2, 
    },
    succeed: {
        color: green[600],
    },
    failed: {
        color: theme.palette.error.dark,
    }
});

class Projects extends Component {

    state = {
        open: false,
        project: null,
        searchCriteria: { keys: [] }
    }

    componentDidMount() {
        const { onRefresh } = this.props;

        onRefresh()
    }

    filterData = (fields, keys) => {
        keys = keys.map(key => key.toLowerCase())
        return stage => (keys.length === 0 || fields.some(field => keys.every(key => stage[field].toLowerCase().indexOf(key) > -1)))
    }

    handleRowClick = (event, id) => {
        const { history } = this.props;

        history.push(`/project/${id}/stage`)
    }

    handleNewClick = () => {
        return new Promise(resolve => this.setState({ 
            open: true
        }, resolve))
    }

    handleRequestSearch = (criteria) => {
        this.setState({
            searchCriteria: criteria
        })
    }

    handleEdit = (event, selected) => {
        const { projects } = this.props;
        const id = selected[0];
        const project = projects.find(project => project._id === id);

        if (project) {
            this.setState({
                open: true,
                project
            })
        }
    }

    handleClone = (event, selected) => {
        console.log(`handleClone ${selected}`)
    }

    handleDelete = (event, selected) => {
        const { projects, onDelete } = this.props;
        const selection = selected.reduce((items, id) => {
            const item = projects.find(project => project._id === id);
            if (item) return items.concat(item)
        }, []);

        onDelete(selection);
    }

    handleBuild = (event, selected) => {
        const { projects, onBuild } = this.props;
        const selection = selected.reduce((items, id) => {
            const item = projects.find(project => project._id === id);
            if (item) return items.concat(item)
        }, []);

        onBuild(selection);
    }

    handleProjectBuildClick = (event, item) => {
        event.stopPropagation()
        if (item.build) {
            const { _id, build } = item;
            const { history } = this.props;

            history.push(`/project/${_id}/build/${build._id}`)
        }
    }

    handleClose = () => {
        this.setState({
            open: false,
            project: null
        })
    }

    handleSave = (project) => {
        const { onCreate, onUpdate } = this.props;

        this.handleClose();
        if (!project._id) {
            onCreate(project);
        }
        else {
            onUpdate(project);
        }
    }

    render() {
        const { projects, classes, theme, inProgress } = this.props;
        const { searchCriteria, open, project } = this.state;
        // const transitionDuration = {
        //     enter: theme.transitions.duration.enteringScreen,
        //     exit: theme.transitions.duration.leavingScreen
        // };

        return (
            <div className={classes.root}>
                <DataBrowser
                    title="Projects"
                    emptyText="No project(s) found"
                    columns = {columnsWithClasses(classes, this)}
                    actions = {actions(this)}
                    data={projects.filter(this.filterData(['name', 'description'], searchCriteria.keys))}
                    orderBy={{ property: 'createdAt', direction: 'desc' }}
                    selectOnRowClick={false}
                    onAddButtonClick={this.handleNewClick}
                    onRequestSearch={this.handleRequestSearch}
                    onRowClick={this.handleRowClick}
                />
                {(open === true) && (
                    <ProjectForm 
                        project={project}
                        open={open} 
                        onSave={this.handleSave}
                        onClose={this.handleClose}
                    />
                )}
                {(inProgress) ? (
                    <ProgressMask/>
                ) : null}
            </div>
        )
    }
}

Projects.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    inProgress: PropTypes.bool,
    projects: PropTypes.arrayOf(
        PropTypes.object.isRequired
    ).isRequired,
    onRefresh: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onBuild: PropTypes.func.isRequired
}

export default withRouter(withStyles(styles,  { withTheme: true })(Projects))