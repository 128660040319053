import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {  withStyles  } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@material-ui/core';

import FileField from "components/core/FileField";

const styles = theme => ({
    dialog: {
        margin: 'auto'
    },
    dialogPaper: {
        width: '40%'
    },
    dialogActions: {
        paddingTop: theme.spacing.unit * 3,
        paddingLeft: theme.spacing.unit * 1.5,
        paddingRight: theme.spacing.unit * 1.5
    },
})

const InputLabelProps = { 
    required: false //Hide asterix on required field
}

class StageForm extends Component {
    state = {
        stage: {}
    }

    initialState() {
        const { stage } = this.props;
        
        this.setState({
            stage: (stage) ? {...stage} : {}
        })
    }

    componentDidMount() {
        this.initialState()
    }

    componentDidValidate() {
        const { stage } = this.state;

        return (stage && !!stage.name)
    }

    handleChange = (e) => {
        const { id, value } = e.target;
        const { stage } = this.state;
        
        stage[id] = value;
        this.setState({ stage });
    }

    handleSave = (e) => {
        const { onSave } = this.props;
        const { stage } = this.state;
        
        e.preventDefault()
        if (!this.componentDidValidate()) return
        onSave(stage)
    }
    render() {
        const { classes, open, onClose } = this.props;
        const { stage } = this.state;
        const isNew = (stage && !stage._id);

        return (
            <Dialog
                classes={{
                    root: classes.dialog,
                    paper: classes.dialogPaper
                }}
                open={open}
                onClose={onClose}
                aria-labelledby="form-dialog-title"
            >
                <form autoComplete="off" onSubmit={this.handleSave}>
                    <DialogTitle>
                        {isNew ?  "New Stage" : "Stage"}
                    </DialogTitle>
                    <DialogContent>
                        <TextField 
                            InputLabelProps={InputLabelProps}
                            autoComplete="off"
                            autoFocus 
                            margin="dense" 
                            id="name" 
                            label="Name"
                            defaultValue={stage.name} 
                            fullWidth
                            onChange={this.handleChange}
                            required={true}
                        />
                        <FileField
                            id="icon"
                            text="Choose icon"
                            defaultValue={stage.icon}
                            fullWidth
                            onChange={this.handleChange}
                        />
                        <DialogActions>
                            <Button color="primary" onClick={onClose}>
                                Cancel
                            </Button>
                            <Button type="submit" variant="contained" color="primary">
                                Save
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </form>
            </Dialog>
        )
    }
}

StageForm.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    stage: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired
}

export default withStyles(styles)(StageForm)