import { connect } from 'react-redux';
import { 
    fetchMobileProvisions, createMobileProvision, deleteMobileProvision } from 'actions';

import MobileProvisions from 'components/MobileProvisions';

const mapStateToProps = (state) => {
    return {
        inProgress: state.mobileprovisions.inProgress,
        mobileprovisions: state.mobileprovisions.data
    }
};

const mapDispatchToProps = dispatch => ({
    onRefresh: () => dispatch(fetchMobileProvisions()),
    onCreate: mobileprovision => dispatch(createMobileProvision(mobileprovision)),
    onDelete: mobileprovisions => dispatch(deleteMobileProvision(mobileprovisions))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MobileProvisions);