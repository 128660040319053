import React from 'react';
import PropTypes from 'prop-types';
import {  withStyles  } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    root: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex'
    },
    cover: {
        display: 'inline-block',
        height: '100%',
        width: '50%',
        backgroundImage: 'linear-gradient(to right top, #673ab7, #533698, #41307a, #33295b, #26213e)'
    },
    img: {
        background: 'url(/images/bg.png)  bottom center',
        backgroundSize: 'cover',
        width: '100%',
        height: '100%'
    },
    details: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        
        height: '100%',
        width: '50%',
        minWidth: theme.spacing.unit * 41
    },
    copyRight: {
        position: 'absolute',
        bottom: 0,
        paddingBottom: theme.spacing.unit * 2
    }    
})

const BrandPaper = ({ children, classes }) => (
    <div className={classes.root}>
        <div className={classes.cover}>
            <div className={classes.img}></div>
        </div>
        <div className={classes.details}>
            {children}
            <Typography className={classes.copyRight} color="textSecondary">
                Loopthy © 2018. Term of use. Privacy policy
            </Typography>
        </div>
    </div>    
)

BrandPaper.defaultProps = {
}

BrandPaper.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(BrandPaper)