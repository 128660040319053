import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {  withStyles  } from '@material-ui/core/styles';
import classNames from 'classnames';

// import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import DataBrowserHead from 'components/core/DataBrowserHead';
import DataBrowserToolbar from 'components/core/DataBrowserToolbar';

const MENU_ITEM_HEIGHT = 48;

function desc(a, b, property) {
    if (b[property] < a[property]) {
        return -1;
    }
    if (b[property] > a[property]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);

        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting({ property, direction}) {
    return direction === 'desc' ? (a, b) => desc(a, b, property) : (a, b) => -desc(a, b, property);
}

const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    pointer: { 
        cursor: 'pointer' 
    },
    tableWrapper: {
		height: 'calc(100vh - 176px)',
        // height: 'calc(100% - 64px)',
        overflowY: 'scroll'
    },
    table: {
        ///
        // display: 'block',
        // height: '100%'
    },
    // fixed: {
    //     display: 'table',
    //     width: '100%',
    //     tableLayout: 'fixed'
    // },
    tableBody: {
        ///
        // display: 'block',
        // height: 'calc(100% - 56px)',
        // overflow: 'auto'

        // height: 'calc(100% - 64px)',
        // overflowY: 'scroll'
    },
    selected: {
        backgroundColor: theme.palette.type === 'light'
            ? 'rgba(0, 0, 0, 0.07)' // grey[200]
            : 'rgba(255, 255, 255, 0.14)'
    },
    menuItem: {

    },
})
  
class DataBrowser extends Component {
    state = {
        anchorEl: null,
        // order: 'asc',
        // orderBy: 'fieldName', //TODO:
        orderBy: {
            property: '',
            direction: 'asc'
        },
        rowId: null,
        selected: [],
        // data: [],
        page: 0,
        rowsPerPage: 5
    }

    initialState() {
        const { orderBy, rowId } = this.props;
        
        this.setState({
            orderBy: {...orderBy},
            rowId
        })
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const nextRowId = nextProps.rowId;
        const { rowId } = prevState;

        if (nextRowId && nextRowId != rowId) {
          return {  rowId: nextRowId  }
        }
        return null;
    }

    componentDidMount() {
        this.initialState();
    }

    handleRequestSearch = (event, criteria) => {

    }
  
    handleRequestSort = (event, property) => {
        const { orderBy } = this.state
        let direction = 'desc';
  
        if (orderBy.property === property && orderBy.direction === 'desc') {
            direction = 'asc';
        }
  
        this.setState({ orderBy: { property, direction } });
    }
  
    handleSelectAllClick = event => {
        const { idField, data } = this.props;

        if (event.target.checked) {
            this.setState(() => ({ selected: data.map(n => n[idField]) }));
            return;
        }
        this.setState({ selected: [] });
    }

    handleActionButtonClick = (event, action) => {
        const { selected } = this.state;

        event.stopPropagation()
        if (action.handleClick) {
            action.handleClick(event, selected)
        }
        this.setState({ selected: [] })
    }

    handleAddButtonClick = event => {
        const { onAddButtonClick } = this.props;

        (onAddButtonClick) && onAddButtonClick(event)
    }

    handleRequestSearch = criteria => {
        const { onRequestSearch } = this.props;

        (onRequestSearch) && onRequestSearch({
            criteria,
            keys: criteria.split(' ')
        })
    }

    handleRowClick = (event, id) => {
        const { selectOnRowClick, onRowClick } = this.props;
        
        event.stopPropagation()
        if (selectOnRowClick) {
            this.handleSelect(event, id)
        }
        else {
            const { rowId } = this.state;

            this.setState({ rowId: (rowId === id) ? null : id })
            if (onRowClick) onRowClick(event, id)
        }
    }
  
    handleSelect = (event, id) => {
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
  
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } 
        else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } 
        else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } 
        else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            )
        }
        this.setState({ selected: newSelected })
    }
  
    // handleChangePage = (event, page) => {
    //     this.setState({ page })
    // }
  
    // handleChangeRowsPerPage = event => {
    //     this.setState({ rowsPerPage: event.target.value })
    // }

    handleMenuItemClick = (event, action, id) => {
        event.stopPropagation()
        if (action.handleClick) {
            action.handleClick(event, [id])
        }
        this.handleMenuClose()
    }

    handleMenuButtonClick = (event, id) => {
        event.stopPropagation()
        this.setState({ anchorEl: event.currentTarget, itemId: id })
    }
    
    handleMenuClose = () => {
        this.setState({ anchorEl: null, itemId: null })
    }
  
    isSelected = id => this.state.selected.indexOf(id) !== -1
  
    render() {
        const { classes, title, columns, actions, data, idField, emptyText, selectOnRowClick, onAddButtonClick, onRequestSearch } = this.props;
        const { itemId, anchorEl, orderBy, rowId, selected, rowsPerPage, page } = this.state;
        // const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
        const openMenu = Boolean(anchorEl);
        const withActions = (actions.length > 0);

        return (
            <div className={classes.root}>
                <DataBrowserToolbar 
                    title={title} 
                    selected={selected} 
                    actions={actions}
                    
                    onActionButtonClick={this.handleActionButtonClick}
                    onAddButtonClick={onAddButtonClick && this.handleAddButtonClick}
                    onRequestSearch={this.handleRequestSearch}
                />
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby="tableTitle" style={{ tableLayout: 'fixed' }}>
                        <DataBrowserHead
                            columns={columns}
                            action={withActions}
                            rowCount={data.length}
                            numSelected={selected.length}
                            orderBy={orderBy}
                            onSelectAllClick={this.handleSelectAllClick}
                            onRequestSort={this.handleRequestSort}
                        />
                        <TableBody className={classes.tableBody}>
                            {stableSort(data, getSorting(orderBy))
                            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map(item => {
                                const isSelected = this.isSelected(item[idField])

                                return (
                                    <TableRow
                                        className={(item[idField] === rowId) ? classNames(classes.fixed, classes.selected) : classes.fixed}
                                        hover
                                        onClick={event => this.handleRowClick(event, item[idField])}
                                        role="checkbox"
                                        aria-checked={isSelected}
                                        tabIndex={-1}
                                        key={item[idField]}
                                        selected={isSelected}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox 
                                                checked={isSelected}
                                                onClick={event => event.stopPropagation()}
                                                onChange={event => this.handleSelect(event, item[idField])}
                                            />
                                        </TableCell>
                                        {columns.map((column, index) => 
                                            // return (!!item[column.id]) ? (
                                                <TableCell 
                                                    className={(!selectOnRowClick ? classes.pointer : "")}
                                                    key={index} numeric={column.numeric}
                                                >
                                                    {(column.render) ? (
                                                        column.render(column, item)    
                                                    ) : (item[column.id])}
                                                </TableCell>
                                            // ) : (
                                            //     <TableCell key={index}/>   
                                            // )
                                        )}
                                        <TableCell>
                                            <IconButton
                                                aria-label="More"
                                                aria-owns={openMenu ? 'row-menu' : undefined}
                                                aria-haspopup="true"
                                                data-id={item[idField]}
                                                onClick={event => this.handleMenuButtonClick(event, item[idField])}
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                        </TableCell>
                                        {(openMenu && withActions && itemId === item[idField]) && (
                                            <Menu
                                                id="row-menu"
                                                anchorEl={anchorEl}
                                                open={openMenu}
                                                onClose={this.handleMenuClose}
                                                PaperProps={{
                                                    style: {
                                                        maxHeight: MENU_ITEM_HEIGHT * 4.5,
                                                        width: 200
                                                    }
                                                }}
                                            >
                                                {actions.map((action, index) => (
                                                    <MenuItem key={index} className={classes.menuItem} onClick={event => this.handleMenuItemClick(event, action, item[idField])}>
                                                        <ListItemIcon className={classes.icon}>
                                                            <Icon>{action.icon}</Icon>
                                                        </ListItemIcon>
                                                        <ListItemText classes={{ primary: classes.primary }} inset primary={action.label} />
                                                    </MenuItem>
                                                ))}
                                            </Menu>
                                        )}
                                    </TableRow>
                                )
                            })}
                            {(data.length === 0) && (
                                <TableRow>
                                    <TableCell colSpan={columns.length + 2}>
                                        <Typography  variant="caption">{emptyText}</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                            {/* {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={columns.length + 1} />
                                </TableRow>
                            )} */}
                        </TableBody>
                    </Table>
                    
                </div>
                {/* <TablePagination
                    // className={classes.pagination}
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                /> */}
            </div>
        )
    }
}

DataBrowser.defaultProps = {
    emptyText: 'No data found',
    idField: '_id',
    actions: [],
    selectOnRowClick: false,
    orderBy: {
        property: '',
        direction: 'asc'
    }   
}
  
DataBrowser.propTypes = {
    classes: PropTypes.object.isRequired,
    idField: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    emptyText: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    actions: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectOnRowClick: PropTypes.bool.isRequired,
    rowId: PropTypes.string,
    orderBy: PropTypes.object,
    onAddButtonClick: PropTypes.func,
    onRequestSearch: PropTypes.func,
    onRowClick: PropTypes.func
}
  
export default withStyles(styles)(DataBrowser)