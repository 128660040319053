import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import deepPurple from '@material-ui/core/colors/deepPurple';

const theme = createMuiTheme({
    palette: {
        primary: deepPurple,
        // secondary: { main: '#53DD6C' }
    },
    typography: {
        useNextVariants: true
    }
});

const MainTheme = ({ children }) => (<MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>)

export default MainTheme