import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {  withStyles  } from '@material-ui/core/styles';

import Zoom from '@material-ui/core/Zoom';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ProfileListItem from './ProfileListItem';
import ProfileForm from './ProfileForm';
import ConfirmDialog from './ConfirmDialog';

import { PROFILE, PROFILES, capitalize } from './locale';

const styles = theme => ({
    fab: {
        position: 'absolute',
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 2
    },
    title: {
        flexGrow: 1
    },
    list: {
        overflowY: 'auto',
        width: '100%'
    },
    emptyText: {
        margin: 0,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
});

class ProfileList extends Component {

    state = {
        selection: [],
        open: false,
        confirm: {
            open: false
        }
    }

    componentDidMount() {
        const { onRefresh } = this.props;

        onRefresh();
    }

    handleNew = () => {
        // const { onNew } = this.props;

        this.setState({ 
            open: true,
            profile: {}
        });
        // onNew();
    }

    handleItem = (profile) => {
        // const { onEdit } = this.props;

        this.setState({ 
            open: true,
            profile: profile
        });
        // onEdit()
    }

    // handleClone = (profile) => {
    //     // const { onClone } = this.props;
    //     const newProfile = { ...profile };

    //     newProfile.name = `${profile.name} Copy`;
        
    //     delete newProfile._id;
    //     delete newProfile.createdAt;
    //     delete newProfile.updatedAt;

    //     this.setState({ 
    //         edit: {
    //             open: true
    //         },
    //         project: newProfile
    //     });
    //     // onClone();
    // }

    handleDelete = (profile) => {
        this.setState({ 
            confirm: {
                open: true
            },
            profile: profile
        });
    }

    handleDeleteConfirm = () => {
        const { onDelete } = this.props;
        const { profile, selection } = this.state;

        this.handleDeleteClose();
        onDelete((profile) ? [profile] : selection);
    }

    handleDeleteClose = () => {
        this.setState({
            selection: [],
            profile: null,  
            confirm: {
                open: false
            }
        });
    }

    // handleMobileProvision = (file) => {
    //     const { onMobileProvision } = this.props;

    //     onMobileProvision(file)
    // }

    handleClose = () => {
        this.setState({ open: false });
    }

    handleSave = (profile) => {
        const { onCreate, onUpdate } = this.props;

        this.handleClose();
        if (!profile._id) {
            onCreate(profile);
        }
        else {
            onUpdate(profile);
        }
    }

    handleSelectionChange = (item, event) => {
        const { checked } = event.target;
        const { selection } = this.state;
        const currentIndex = selection.indexOf(item);
        const newSelection = [...selection];

        if (checked && currentIndex === -1) {
            newSelection.push(item);
        } 
        else if (!checked) {
            newSelection.splice(currentIndex, 1);
        }
        this.setState({ selection: newSelection });
    }

    render() {
        // const { classes, theme, profiles, mobileprovisions, certificates } = this.props;
        const { classes, theme, profiles } = this.props;
        const { selection } = this.state;
        const transitionDuration = {
            enter: theme.transitions.duration.enteringScreen,
            exit: theme.transitions.duration.leavingScreen
        };
        return (
            <div>
                <Toolbar className={classes.toolbar}>
                    <Typography className={classes.title} variant="headline">{capitalize(PROFILES)}</Typography>
                    {(selection.length > 0) ? (
                        <div>
                            <Tooltip title="Delete">
                                <IconButton aria-label="Delete" onClick={() => this.handleDelete()}>
                                    <Icon>delete</Icon>
                                </IconButton>
                            </Tooltip>
                        </div>
                    ) : null}
                </Toolbar>
                {(profiles.length === 0) ? (
                    <div className={classes.emptyText}>
                        <Typography  variant="caption">{`No ${PROFILE}(s) found`}</Typography>
                    </div>
                ) : (
                    <List className={classes.list}>
                        {profiles.map((profile, index) => 
                            <ProfileListItem
                                key={index} 
                                {...profile}
                                checked={selection.length > 0 && selection.findIndex(a => a._id === profile._id) !== -1}
                                onSelect={event => this.handleSelectionChange(profile, event)}
                                onEdit={() => this.handleItem(profile)} 
                                onDelete={() => this.handleDelete(profile)}
                            />
                        )}
                    </List>
                )}
                { (this.state.open === true && this.state.profile) ? (
                        <ProfileForm 
                            profile={this.state.profile}
                            // mobileprovisions={mobileprovisions}
                            // certificates={certificates}
                            open={this.state.open} 
                            // onMobileProvision={this.handleMobileProvision} 
                            onSubmit={this.handleSave} 
                            onClose={this.handleClose}
                        />
                    ) : null
                }
                { (this.state.confirm.open === true) ? (
                        <ConfirmDialog
                            title="Confirm"
                            description="Are you sure you want to delete this item?"
                            open={this.state.confirm.open}
                            onConfirm={this.handleDeleteConfirm}
                            onClose={this.handleDeleteClose}
                        />
                    ) : null
                }
                <Zoom
                    key="primary"
                    in={true}
                    timeout={transitionDuration}
                    style={{
                        transitionDelay: transitionDuration.exit
                    }}
                    unmountOnExit>
                    <Button color="primary" variant="fab" className={classes.fab} onClick={this.handleNew}>
                        <Icon>add</Icon>
                    </Button>
                </Zoom>
            </div>
        );
    }
};

ProfileList.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    profiles: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            identifier: PropTypes.string.isRequired,
            image: PropTypes.object,
            provisioning: PropTypes.object
        }).isRequired
    ).isRequired,
    // mobileprovisions: PropTypes.arrayOf(
    //     PropTypes.object
    // ),
    // certificates: PropTypes.arrayOf(
    //     PropTypes.object
    // ),
    onRefresh: PropTypes.func.isRequired,
    // onMobileProvision: PropTypes.func.isRequired,
    // onNew: PropTypes.func.isRequired,
    // onEdit: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
};

export default withStyles(styles, { withTheme: true })(ProfileList);