import React, { Component } from 'react';
import {  Switch, Link  } from 'react-router-dom';
import PropTypes from 'prop-types';
import {  withStyles  } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Avatar from '@material-ui/core/Avatar';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import AuthRoute from 'components/AuthRoute';

const drawerWidth = 216

const styles = theme => ({
    root: {
        display: 'flex',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0
    },
    flex: {
        flex: 1
    },
    mainWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    appBar: {
        // width: `calc(100% - ${drawerWidth}px)`,
        // marginLeft: drawerWidth
    },
    toolbar: {

    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        backgroundColor: '#303133',
        width: drawerWidth
    },
    drawerToolbar: {
        backgroundColor: '#262626',
        boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
        paddingLeft: 0,
        paddingRight: 0
    },
    spacer: {
        flex: 1
    },
    avatar: {
        margin: 'auto',
        width: 200,
        borderRadius: 0,
        paddingBottom: theme.spacing.unit
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        width: 300,
        height: 200,
        textAlign: 'center'
    },
    cardContent: {
        flexGrow: 1,
        paddingTop: theme.spacing.unit * 4,
        paddingBottom: theme.spacing.unit
    },
    cardActions: {
        justifyContent: 'center'
    },
    list: {
        '& *': {
            color: 'rgba(255, 255, 255, 0.8)'
        },
        paddingTop: 0,
        paddingBottom: 0
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        // marginTop: theme.spacing.unit * 8,
        // paddingTop: theme.spacing.unit * 8,
        // position: 'relative',
        // width: '100%',
        height: '100%'
    },
    selected: {
        backgroundColor: 'rgba(0, 0, 0, 0.1)'
    }    
})

class Layout extends Component {
    state = {
        selectedIndex: 0,
        anchorEl: null
    }

    handlePopover = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    }

    handleClose = () => {
        this.setState({ anchorEl: null });
    }

    handleItem = (index) => {
        const { selectedIndex } = this.state;

        if (selectedIndex !== index) {
            this.setState({ 
                selectedIndex: (selectedIndex !== index) ? index : -1
            });
        }
    }

    handleLogout = () => {
        const { onLogout } = this.props;

        this.handleClose();
        onLogout();
    }

    render() {
        const { classes, links, containers, user, badges } = this.props;
        const { selectedIndex, anchorEl } = this.state;
        const open = Boolean(anchorEl);

        return (
            <div className={classes.root}>
                <Drawer className={classes.drawer} variant="permanent" classes={{ paper: classes.drawerPaper }} anchor="left">
                    <Toolbar className={classes.drawerToolbar}>
                        <Avatar alt="logo" src="/images/logo-name.png" className={classes.avatar}></Avatar>
                    </Toolbar>
                    <List className={classes.list}>
                        {links.map((item, index) => (
                            <ListItem 
                                className={(selectedIndex === index) ? classes.selected : ""} 
                                key={index} 
                                component={Link} 
                                to={item.to} 
                                button 
                                onClick={() => this.handleItem(index)}
                            >
                                <ListItemIcon>
                                    <Icon className={(selectedIndex === index) ? classes.icon : ""}>{item.icon}</Icon>
                                </ListItemIcon>
                                <ListItemText primary={item.text} />
                                {/* {badges[item.key] ? (
                                    <Badge classes={{ badge: classes.badge }} badgeContent={badges[item.key]} color="primary"><span/></Badge> 
                                ) : null} */}
                            </ListItem>
                        ))}
                    </List>
                </Drawer>
                <div className={classes.mainWrapper}>
                    <AppBar position="relative" className={classes.appBar}>
                        <Toolbar className={classes.toolbar}>
                            <div className={classes.flex}></div>
                            <IconButton aria-owns={open ? "user-popper" : null} aria-haspopup="true" color="inherit" onClick={this.handlePopover}>
                                <Icon>account_circle</Icon>
                            </IconButton>
                            <Popover
                                id="user-popper"
                                open={open}
                                anchorEl={anchorEl}
                                onClose={this.handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <Card className={classes.card}>
                                    <CardContent className={classes.cardContent}>
                                        <Icon color="primary" style={{ fontSize: 55 }}>account_circle</Icon>
                                        <Typography variant="subtitle1">
                                            {user.lastName}, {user.firstName}
                                        </Typography>
                                        <Typography color="textSecondary">
                                            {user.local.email}
                                        </Typography>
                                    </CardContent>
                                    <CardActions className={classes.cardActions}>
                                        <Button onClick={this.handleLogout} color="primary" autoFocus>Logout</Button>
                                    </CardActions>
                                </Card>
                            </Popover>
                        </Toolbar>
                    </AppBar>
                    <main className={classes.content}>
                        <Switch>
                            {links.map((item, index) => (
                                (containers && containers[item.component]) ? <AuthRoute key={index} path={item.to} component={containers[item.component]} /> : null
                            ))}
                        </Switch>
                    </main>
                </div>
            </div>
        )
    }
}

Layout.propTypes = {
    classes: PropTypes.object.isRequired,
    links: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired,
        to: PropTypes.string.isRequired,
        component: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired
    })).isRequired,
    containers: PropTypes.object.isRequired,
    user: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        local: PropTypes.shape({
            email: PropTypes.string.isRequired    
        }).isRequired
    }).isRequired,
    onLogout: PropTypes.func.isRequired
};

export default withStyles(styles)(Layout);