import React, { Component } from 'react';

class Setting extends Component {
	render() {
        return (
        	<div>Setting</div>
        );
    }
}

export default Setting;