import rpc from 'services/rpc';

export const REQUEST_FETCH_ARCHIVES  = 'REQUEST_FECTH_ARCHIVES';
export const RESPONSE_FETCH_ARCHIVES_SUCCESS = 'RESPONSE_FETCH_ARCHIVES_SUCCESS';
export const RESPONSE_FETCH_ARCHIVES_FAILURE = 'RESPONSE_FETCH_ARCHIVES_FAILURE';
export const REQUEST_CREATE_ARCHIVE  = 'REQUEST_CREATE_ARCHIVE';
export const RESPONSE_CREATE_ARCHIVE_SUCCESS = 'RESPONSE_CREATE_ARCHIVE_SUCCESS';
export const RESPONSE_CREATE_ARCHIVE_FAILURE = 'RESPONSE_CREATE_ARCHIVE_FAILURE';
export const REQUEST_DELETE_ARCHIVE  = 'REQUEST_DELETE_ARCHIVE';
export const RESPONSE_DELETE_ARCHIVE_SUCCESS = 'RESPONSE_DELETE_ARCHIVE_RESPONSE';
export const RESPONSE_DELETE_ARCHIVE_FAILURE = 'RESPONSE_DELETE_ARCHIVE_FAILURE';
export const REQUEST_DOWNLOAD_ARCHIVES  = 'REQUEST_DOWNLOAD_ARCHIVES';
export const RESPONSE_DOWNLOAD_ARCHIVES_SUCCESS = 'RESPONSE_DOWNLOAD_ARCHIVES_SUCCESS';
export const RESPONSE_DOWNLOAD_ARCHIVES_FAILURE = 'RESPONSE_DOWNLOAD_ARCHIVES_FAILURE';

const CREATE_ARCHIVE_SUCCESS = 'Yay! Archive was created successfully';
const DELETE_ARCHIVE_SUCCESS = 'Yay! Archive was deleted successfully';
const DOWNLOAD_ARCHIVES_SUCCESS = 'Yay! Archive asset(s) downloaded successfully';

function requestFetch() {
    return {
        type: REQUEST_FETCH_ARCHIVES
    }
};

function responseFetchSuccess(json) {
    return {
        type: RESPONSE_FETCH_ARCHIVES_SUCCESS,
        archives: json,
        receivedAt: Date.now()
    };
};

function responseFetchFailure(error) {
    return {
        type: RESPONSE_FETCH_ARCHIVES_FAILURE,
        error,
        receivedAt: Date.now()
    };
}

function requestDelete() {
    return {
        type: REQUEST_DELETE_ARCHIVE
    }
};

function responseDeleteSuccess(json) {
    return {
        type: RESPONSE_DELETE_ARCHIVE_SUCCESS,
        archives: json,
        success: {
            message: DELETE_ARCHIVE_SUCCESS
        },
        receivedAt: Date.now()
    };
};

function responseDeleteFailure(error) {
    return {
        type: RESPONSE_DELETE_ARCHIVE_FAILURE,
        error,
        receivedAt: Date.now()
    };
}

function requestDownload() {
    return {
        type: REQUEST_DOWNLOAD_ARCHIVES
    }
};

function responseDownloadSuccess(json) {
    return {
        type: RESPONSE_DOWNLOAD_ARCHIVES_SUCCESS,
        archive: json,
        success: {
            message: DOWNLOAD_ARCHIVES_SUCCESS
        },
        receivedAt: Date.now()
    };
};

function responseDownloadFailure(error) {
    return {
        type: RESPONSE_DOWNLOAD_ARCHIVES_FAILURE,
        error,
        receivedAt: Date.now()
    };
}

function requestCreate() {
    return {
        type: REQUEST_CREATE_ARCHIVE
    }
};

function responseCreateSuccess(json) {
    return {
        type: RESPONSE_CREATE_ARCHIVE_SUCCESS,
        archives: json,
        success: {
            message: CREATE_ARCHIVE_SUCCESS
        },
        receivedAt: Date.now()
    };
};

function responseCreateFailure(error) {
    return {
        type: RESPONSE_CREATE_ARCHIVE_FAILURE,
        error,
        receivedAt: Date.now()
    };
}

export function fetchArchives() {
    return function(dispatch) {
        dispatch(requestFetch());

        return rpc.get('archives')
        .then(response => {
            const { data, error } = response.result()

            if (error) {
                dispatch(responseFetchFailure(error));
            }
            else {
                dispatch(responseFetchSuccess(data));
            }
        }, e => dispatch(responseFetchFailure({
            message: e.stderr ? e.stderr : e.message
        })))
    }
};

export function createArchive(projects) {
    return function(dispatch) {
        dispatch(requestCreate());
        
        return rpc.post('archives', projects)
        .then(response => {
            const { data, error } = response.result()

            if (error) {
                dispatch(responseCreateFailure(error));
            }
            else {
                dispatch(responseCreateSuccess(data));
            }
        }, e => dispatch(responseCreateFailure({
            message: e.stderr ? e.stderr : e.message
        })))
    }
}

export function deleteArchive(archives) {
    return function(dispatch) {
        dispatch(requestDelete());

        return rpc.delete('archives', archives)
        .then(response => {
            const { data, error } = response.result()

            if (error) {
                dispatch(responseDeleteFailure(error));
            }
            else {
                dispatch(responseDeleteSuccess(data));
            }
        }, e => dispatch(responseDeleteFailure({
            message: e.stderr ? e.stderr : e.message
        })))
    }
}

export function downloadArchives(archives) {
    return function(dispatch) {
        dispatch(requestDownload());

        return rpc.get('archives/download', archives)
        .then(response => {
            const { data, error } = response.result()

            if (error) {
                dispatch(responseDownloadFailure(error));
            }
            else {
                dispatch(responseDownloadSuccess(data));
            }
        }, e => dispatch(responseDownloadFailure({
            message: e.stderr ? e.stderr : e.message
        })))
    }
}