import { connect } from 'react-redux';
// import { fetchProfiles, fetchCertificates, fetchMobileProvisions, createProfile, updateProfile, deleteProfile, createMobileProvision } from 'actions';
import { fetchProfiles, createProfile, updateProfile, deleteProfile } from 'actions';
import ProfileList from 'components/ProfileList';

const mapStateToProps = (state) => {
    return {
        profiles: state.profiles.data,
        // certificates: state.certificates.data,
        // mobileprovisions: state.mobileprovisions.data
    }
};

const mapDispatchToProps = dispatch => ({
    onRefresh: () => dispatch(fetchProfiles()),
    onCreate: profile => dispatch(createProfile(profile)),
    onUpdate: profile => dispatch(updateProfile(profile)),
    onDelete: profile => dispatch(deleteProfile(profile)),
    // onNew: () => Promise.all([
    //     dispatch(fetchCertificates()),
    //     dispatch(fetchMobileProvisions())
    // ]),
    // onEdit: () => Promise.all([
    //     dispatch(fetchCertificates()),
    //     dispatch(fetchMobileProvisions())
    // ]),
    // onMobileProvision: file => dispatch(createMobileProvision(file))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ProfileList);