import { combineReducers } from 'redux';

import status from './status';
import user from './users';
import projects from './projects';
import stages from './stages';
import builds from './builds';
import assets from './assets';
import certificates from './certificates';
import mobileprovisions from './mobileprovisions';
//deprecated
// import profiles from './profiles';
// import archives from './archives';
// import archive from './archive';

export default combineReducers({
    status,
    user,
    projects,
    stages,
    builds,
    assets,
    certificates,
    mobileprovisions,
    //deprecated
    // profiles,
    // archives,
    // archiveInfo: archive
});