import rpc from 'services/rpc';
import * as Types from 'constants/ActionTypes';

const CREATE_BUILD_SUCCESS = 'Yay! Build was created successfully';
const UPDATE_BUILD_SUCCESS = 'Yay! Build was updated successfully';
const DELETE_BUILD_SUCCESS = 'Yay! Build(s) was deleted successfully';

function requestFetch() {
    return {
        type: Types.REQUEST_FETCH_BUILDS
    }
};

function responseFetchSuccess(json) {
    return {
        type: Types.RESPONSE_FETCH_BUILDS_SUCCESS,
        builds: json,
        receivedAt: Date.now()
    };
};

function responseFetchFailure(error) {
    return {
        type: Types.RESPONSE_FETCH_BUILDS_FAILURE,
        error,
        receivedAt: Date.now()
    };
};

function requestCreate() {
    return {
        type: Types.REQUEST_CREATE_BUILD
    }
};

function responseCreateSuccess(json) {
    return {
        type: Types.RESPONSE_CREATE_BUILD_SUCCESS,
        build: json,
        success: {
            message: CREATE_BUILD_SUCCESS
        },
        receivedAt: Date.now()
    };
};

function responseCreateFailure(error) {
    return {
        type: Types.RESPONSE_CREATE_BUILD_FAILURE,
        error,
        receivedAt: Date.now()
    };
};

function requestUpdate() {
    return {
        type: Types.REQUEST_UPDATE_BUILD
    }
};

function responseUpdateSuccess(json) {
    return {
        type: Types.RESPONSE_UPDATE_BUILD_SUCCESS,
        build: json,
        success: {
            message: UPDATE_BUILD_SUCCESS
        },
        receivedAt: Date.now()
    };
};

function responseUpdateFailure(error) {
    return {
        type: Types.RESPONSE_UPDATE_BUILD_FAILURE,
        error,
        receivedAt: Date.now()
    };
};

function requestDelete() {
    return {
        type: Types.REQUEST_DELETE_BUILD
    }
};

function responseDeleteSuccess(json) {
    return {
        type: Types.RESPONSE_DELETE_BUILD_SUCCESS,
        builds: json,
        success: {
            message: DELETE_BUILD_SUCCESS
        },
        receivedAt: Date.now()
    };
};

function responseDeleteFailure(error) {
    return {
        type: Types.RESPONSE_DELETE_BUILD_FAILURE,
        error,
        receivedAt: Date.now()
    };
};

// Do not use catch, because that will also catch
// any errors in the dispatch and resulting render,
// causing a loop of 'Unexpected batch number' errors.
// https://github.com/facebook/react/issues/6895

export function fetchBuilds() {
    return function(dispatch) {
        dispatch(requestFetch());

        return rpc.get('builds')
        .then(response => {
            const { data, error } = response.result()

            if (error) {
                dispatch(responseFetchFailure(error));
            }
            else {
                dispatch(responseFetchSuccess(data));
            }
        }, e => dispatch(responseFetchFailure({
            message: e.stderr ? e.stderr : e.message
        })))
    }
};

export function createBuildByProjects(projects) {
    return function(dispatch) {
        dispatch(requestCreate());

        return rpc.post('builds/project', projects)
        .then(response => {
            const { data, error } = response.result()

            if (error) {
                dispatch(responseCreateFailure(error));
            }
            else {
                dispatch(responseCreateSuccess(data));
            }
        }, e => dispatch(responseCreateFailure({
            message: e.stderr ? e.stderr : e.message
        })))
    }
};

export function createBuildByStages(stages) {
    return function(dispatch) {
        dispatch(requestCreate());

        return rpc.post('builds/stage', stages)
        .then(response => {
            const { data, error } = response.result()

            if (error) {
                dispatch(responseCreateFailure(error));
            }
            else {
                dispatch(responseCreateSuccess(data));
            }
        }, e => dispatch(responseCreateFailure({
            message: e.stderr ? e.stderr : e.message
        })))
    }
};

export function updateBuild(build) {
    return function(dispatch) {
        dispatch(requestUpdate());

        return rpc.put('builds', build)
        .then(response => {
            const { data, error } = response.result()

            if (error) {
                dispatch(responseUpdateFailure(error));
            }
            else {
                dispatch(responseUpdateSuccess(data));
            }
        }, e => dispatch(responseUpdateFailure({
            message: e.stderr ? e.stderr : e.message
        })))
    }
};

export function deleteBuild(builds) {
    return function(dispatch) {
        dispatch(requestDelete());

        return rpc.delete('builds', builds)
        .then(response => {
            const { data, error } = response.result()

            if (error) {
                dispatch(responseDeleteFailure(error));
            }
            else {
                dispatch(responseDeleteSuccess(data));
            }
        }, e => dispatch(responseDeleteFailure({
            message: e.stderr ? e.stderr : e.message
        })))
    }
};