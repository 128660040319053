import rpc from 'services/rpc';
import * as Types from 'constants/ActionTypes';

const CREATE_PROJECT_SUCCESS = 'Yay! Project was created successfully';
const UPDATE_PROJECT_SUCCESS = 'Yay! Project was updated successfully';
const DELETE_PROJECT_SUCCESS = 'Yay! Project(s) was deleted successfully';

function requestFetch() {
    return {
        type: Types.REQUEST_FETCH_PROJECTS
    }
};

function responseFetchSuccess(json) {
    return {
        type: Types.RESPONSE_FETCH_PROJECTS_SUCCESS,
        projects: json,
        receivedAt: Date.now()
    };
};

function responseFetchFailure(error) {
    return {
        type: Types.RESPONSE_FETCH_PROJECTS_FAILURE,
        error,
        receivedAt: Date.now()
    };
};

function requestCreate() {
    return {
        type: Types.REQUEST_CREATE_PROJECT
    }
};

function responseCreateSuccess(json) {
    return {
        type: Types.RESPONSE_CREATE_PROJECT_SUCCESS,
        project: json,
        success: {
            message: CREATE_PROJECT_SUCCESS
        },
        receivedAt: Date.now()
    };
};

function responseCreateFailure(error) {
    return {
        type: Types.RESPONSE_CREATE_PROJECT_FAILURE,
        error,
        receivedAt: Date.now()
    };
};

function requestSingleFetch() {
    return {
        type: Types.REQUEST_FETCH_PROJECT
    }
};

function responseSingleFetchSuccess(json) {
    return {
        type: Types.RESPONSE_FETCH_PROJECT_SUCCESS,
        project: json,
        receivedAt: Date.now()
    };
};

function responseSingleFetchFailure(error) {
    return {
        type: Types.RESPONSE_FETCH_PROJECT_FAILURE,
        error,
        receivedAt: Date.now()
    };
};

function requestUpdate() {
    return {
        type: Types.REQUEST_UPDATE_PROJECT
    }
};

function responseUpdateSuccess(json) {
    return {
        type: Types.RESPONSE_UPDATE_PROJECT_SUCCESS,
        project: json,
        success: {
            message: UPDATE_PROJECT_SUCCESS
        },
        receivedAt: Date.now()
    };
};

function responseUpdateFailure(error) {
    return {
        type: Types.RESPONSE_UPDATE_PROJECT_FAILURE,
        error,
        receivedAt: Date.now()
    };
};

function requestDelete() {
    return {
        type: Types.REQUEST_DELETE_PROJECT
    }
};

function responseDeleteSuccess(json) {
    return {
        type: Types.RESPONSE_DELETE_PROJECT_SUCCESS,
        projects: json,
        success: {
            message: DELETE_PROJECT_SUCCESS
        },
        receivedAt: Date.now()
    };
};

function responseDeleteFailure(error) {
    return {
        type: Types.RESPONSE_DELETE_PROJECT_FAILURE,
        error,
        receivedAt: Date.now()
    };
};

// Do not use catch, because that will also catch
// any errors in the dispatch and resulting render,
// causing a loop of 'Unexpected batch number' errors.
// https://github.com/facebook/react/issues/6895

export function fetchProjects() {
    return function(dispatch) {
        dispatch(requestFetch());

        return rpc.get('projects')
        .then(response => {
            const { data, error } = response.result()

            if (error) {
                dispatch(responseFetchFailure(error));
            }
            else {
                dispatch(responseFetchSuccess(data));
            }
        }, e => dispatch(responseFetchFailure({
            message: e.stderr ? e.stderr : e.message
        })))
    }
};

export function createProject(project) {
    return function(dispatch) {
        dispatch(requestCreate());

        return rpc.post('projects', project)
        .then(response => {
            const { data, error } = response.result()

            if (error) {
                dispatch(responseCreateFailure(error));
            }
            else {
                dispatch(responseCreateSuccess(data));
            }
        }, e => dispatch(responseCreateFailure({
            message: e.stderr ? e.stderr : e.message
        })))
    }
};

export function fetchProject(id) {
    return function(dispatch) {
        dispatch(requestSingleFetch());

        return rpc.get('projects', { _id: id })
        .then(response => {
            const { data, error } = response.result()

            if (error) {
                dispatch(responseSingleFetchFailure(error));
            }
            else {
                dispatch(responseSingleFetchSuccess(data[0]));
            }
        }, e => dispatch(responseSingleFetchFailure({
            message: e.stderr ? e.stderr : e.message
        })))
    }
};

export function updateProject(project) {
    return function(dispatch) {
        dispatch(requestUpdate());

        return rpc.put('projects', project)
        .then(response => {
            const { data, error } = response.result()

            if (error) {
                dispatch(responseUpdateFailure(error));
            }
            else {
                dispatch(responseUpdateSuccess(data));
            }
        }, e => dispatch(responseUpdateFailure({
            message: e.stderr ? e.stderr : e.message
        })))
    }
};

export function deleteProject(projects) {
    return function(dispatch) {
        dispatch(requestDelete());

        return rpc.delete('projects', projects)
        .then(response => {
            const { data, error } = response.result()

            if (error) {
                dispatch(responseDeleteFailure(error));
            }
            else {
                dispatch(responseDeleteSuccess(data));
            }
        }, e => dispatch(responseDeleteFailure({
            message: e.stderr ? e.stderr : e.message
        })))
    }
};