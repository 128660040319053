import { 
    RESPONSE_LOGIN_SUCCESS,
    RESPONSE_LOGOUT_SUCCESS,
    RESPONSE_REGISTER_SUCCESS,
    RESPONSE_VERIFY_EMAIL_SUCCESS,
    RESPONSE_RESEND_SUCCESS,
    RESPONSE_FORGOT_PWD_SUCCESS,
    RESPONSE_RESET_PWD_SUCCESS,
    RESPONSE_AUTH_SUCCESS,
    RESPONSE_AUTH_FAILURE,
    RESPONSE_REGISTER_FAILURE,
    RESPONSE_VERIFY_EMAIL_FAILURE,
    RESPONSE_RESEND_FAILURE,
    RESPONSE_FORGOT_PWD_FAILURE,
    RESPONSE_RESET_PWD_FAILURE,
    RESPONSE_LOGIN_FAILURE,
    RESPONSE_LOGOUT_FAILURE,

    RESPONSE_CREATE_PROJECT_SUCCESS,
    RESPONSE_UPDATE_PROJECT_SUCCESS,
    RESPONSE_DELETE_PROJECT_SUCCESS,
    RESPONSE_FETCH_PROJECTS_FAILURE,
    RESPONSE_CREATE_PROJECT_FAILURE,
    RESPONSE_FETCH_PROJECT_FAILURE,
    RESPONSE_UPDATE_PROJECT_FAILURE,
    RESPONSE_DELETE_PROJECT_FAILURE,

    RESPONSE_CREATE_STAGE_SUCCESS,
    RESPONSE_UPDATE_STAGE_SUCCESS,
    RESPONSE_DELETE_STAGE_SUCCESS,
    RESPONSE_FETCH_STAGES_FAILURE,
    RESPONSE_CREATE_STAGE_FAILURE,
    RESPONSE_UPDATE_STAGE_FAILURE,
    RESPONSE_DELETE_STAGE_FAILURE,

    RESPONSE_CREATE_BUILD_SUCCESS,
    RESPONSE_UPDATE_BUILD_SUCCESS,
    RESPONSE_DELETE_BUILD_SUCCESS,
    RESPONSE_FETCH_BUILDS_FAILURE,
    RESPONSE_CREATE_BUILD_FAILURE,
    RESPONSE_UPDATE_BUILD_FAILURE,
    RESPONSE_DELETE_BUILD_FAILURE,

    RESPONSE_CREATE_MOBILE_PROVISION_SUCCESS,
    RESPONSE_DELETE_MOBILE_PROVISION_SUCCESS,
    RESPONSE_CREATE_MOBILE_PROVISION_FAILURE,
    RESPONSE_DELETE_MOBILE_PROVISION_FAILURE,

    RESPONSE_FETCH_ASSETS_FAILURE
} from 'constants/ActionTypes';

import { 
    // RESPONSE_CREATE_ARCHIVE_SUCCESS,
    // RESPONSE_DELETE_ARCHIVE_SUCCESS,
    // RESPONSE_DOWNLOAD_ARCHIVES_SUCCESS,
    // RESPONSE_FETCH_ARCHIVES_FAILURE,
    // RESPONSE_CREATE_ARCHIVE_FAILURE,
    // RESPONSE_DELETE_ARCHIVE_FAILURE,
    // RESPONSE_DOWNLOAD_ARCHIVES_FAILURE,

    // RESPONSE_CREATE_PROFILE_SUCCESS,
    // RESPONSE_UPDATE_PROFILE_SUCCESS,
    // RESPONSE_DELETE_PROFILE_SUCCESS,
    // RESPONSE_FETCH_PROFILES_FAILURE,
    // RESPONSE_CREATE_PROFILE_FAILURE,
    // RESPONSE_UPDATE_PROFILE_FAILURE,
    // RESPONSE_DELETE_PROFILE_FAILURE,

    // RESPONSE_FETCH_ARCHIVE_INFO_FAILURE,

    // RESPONSE_FETCH_CERTIFICATES_FAILURE
} from 'actions';

const status = (state = {
    success: null,
    error: null
}, action) => {
    switch (action.type) {
        case RESPONSE_AUTH_SUCCESS:
        case RESPONSE_LOGIN_SUCCESS:
        case RESPONSE_REGISTER_SUCCESS:
        case RESPONSE_VERIFY_EMAIL_SUCCESS:
        case RESPONSE_RESEND_SUCCESS:
        case RESPONSE_FORGOT_PWD_SUCCESS:
        case RESPONSE_RESET_PWD_SUCCESS:
        case RESPONSE_LOGOUT_SUCCESS:
        // case RESPONSE_CREATE_ARCHIVE_SUCCESS:
        // case RESPONSE_DELETE_ARCHIVE_SUCCESS:
        // case RESPONSE_DOWNLOAD_ARCHIVES_SUCCESS:
        case RESPONSE_CREATE_PROJECT_SUCCESS:
        case RESPONSE_UPDATE_PROJECT_SUCCESS:
        case RESPONSE_DELETE_PROJECT_SUCCESS:
        case RESPONSE_CREATE_STAGE_SUCCESS:
        case RESPONSE_UPDATE_STAGE_SUCCESS:
        case RESPONSE_DELETE_STAGE_SUCCESS:
        case RESPONSE_CREATE_BUILD_SUCCESS:
        case RESPONSE_UPDATE_BUILD_SUCCESS:
        case RESPONSE_DELETE_BUILD_SUCCESS:
        case RESPONSE_CREATE_MOBILE_PROVISION_SUCCESS:
        case RESPONSE_DELETE_MOBILE_PROVISION_SUCCESS:

        // case RESPONSE_CREATE_PROFILE_SUCCESS:
        // case RESPONSE_UPDATE_PROFILE_SUCCESS:
        // case RESPONSE_DELETE_PROFILE_SUCCESS:
            return Object.assign({}, state, {
                success: action.success,
                error: null
            });
        case RESPONSE_AUTH_FAILURE:
        case RESPONSE_LOGIN_FAILURE:
        case RESPONSE_LOGOUT_FAILURE:
        case RESPONSE_REGISTER_FAILURE:
        case RESPONSE_VERIFY_EMAIL_FAILURE:
        case RESPONSE_RESEND_FAILURE:
        case RESPONSE_FORGOT_PWD_FAILURE:
        case RESPONSE_RESET_PWD_FAILURE:
        // case RESPONSE_FETCH_ARCHIVES_FAILURE:
        // case RESPONSE_CREATE_ARCHIVE_FAILURE:
        // case RESPONSE_DELETE_ARCHIVE_FAILURE:
        // case RESPONSE_DOWNLOAD_ARCHIVES_FAILURE:
        case RESPONSE_FETCH_PROJECTS_FAILURE:
        case RESPONSE_CREATE_PROJECT_FAILURE:
        case RESPONSE_FETCH_PROJECT_FAILURE:
        case RESPONSE_UPDATE_PROJECT_FAILURE:
        case RESPONSE_DELETE_PROJECT_FAILURE:
        case RESPONSE_FETCH_STAGES_FAILURE:
        case RESPONSE_CREATE_STAGE_FAILURE:
        case RESPONSE_UPDATE_STAGE_FAILURE:
        case RESPONSE_DELETE_STAGE_FAILURE:
        case RESPONSE_FETCH_BUILDS_FAILURE:
        case RESPONSE_CREATE_BUILD_FAILURE:
        case RESPONSE_UPDATE_BUILD_FAILURE:
        case RESPONSE_DELETE_BUILD_FAILURE:
        case RESPONSE_FETCH_ASSETS_FAILURE:
        case RESPONSE_CREATE_MOBILE_PROVISION_FAILURE:
        case RESPONSE_DELETE_MOBILE_PROVISION_FAILURE:

        // case RESPONSE_FETCH_PROFILES_FAILURE:
        // case RESPONSE_CREATE_PROFILE_FAILURE:
        // case RESPONSE_UPDATE_PROFILE_FAILURE:
        // case RESPONSE_DELETE_PROFILE_FAILURE:
        // case RESPONSE_FETCH_ARCHIVE_INFO_FAILURE:
        // case RESPONSE_FETCH_CERTIFICATES_FAILURE:
            if (action.error.code && action.error.code === 401) {
                return state;
            }
            return Object.assign({}, state, {
                success: null,
                error: action.error
            });
        default:
            return state
    }
};

export default status;