import React from 'react';
import PropTypes from 'prop-types';
import {  withStyles  } from '@material-ui/core/styles';

import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { fileSize } from 'helpers';

const styles = theme => ({
    ellipsis: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis" //
    }
});

const AttachmentListItem = ({ classes, src, profile, onDownload }) => (
    <ListItem button>
        <ListItemText 
            classes={{  primary: classes.ellipsis, secondary: classes.ellipsis  }}  
            primary={src.name} 
            secondary={`${profile.name}, ${fileSize(src.bytes)}`} 
        />
        <ListItemSecondaryAction>
            <Tooltip title="Download">
                <IconButton aria-label="Download" onClick={onDownload}>
                    <Icon>get_app</Icon>
                </IconButton>
            </Tooltip>
        </ListItemSecondaryAction>
    </ListItem>
);

AttachmentListItem.propTypes = {
    src: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    onDownload: PropTypes.func.isRequired
};

export default withStyles(styles, { withTheme: true })(AttachmentListItem);