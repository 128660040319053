import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import LoginForm from 'components/LoginForm';
import { login } from 'actions';

const mapStateToProps = state => {
    return {
        inProgress: state.user.inProgress,
        user: state.user.data
    };
}

const mapDispatchToProps = (dispatch, props) => ({
    onLogin: (email, password) => {
        const { history } = props;
        
        dispatch(login(email, password, history));
    }
});

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(LoginForm));