import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: grey[900],
            dark: '#000'
        }
    },
    typography: {
        useNextVariants: true
    }
})

const DarkTheme = ({ children }) => (<MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>)

export default DarkTheme