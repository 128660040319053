import React from 'react';
import PropTypes from 'prop-types';
import {  withStyles  } from '@material-ui/core/styles';

import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';
import Icon from '@material-ui/core/Icon';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
    listItem: {
        '&:hover $secondaryAction': {
            visibility: 'inherit'
        }
    },
    secondaryAction: {
        visibility: 'hidden',
        paddingRight: 20
    }
});

const ProfileListItem = ({ classes, name, identifier, image, checked, onSelect, onClick, onEdit, onDelete }) => (
    <ListItem classes={{ container: classes.listItem }} onClick={onClick} button>
        <Checkbox
            checked={checked}
            tabIndex={-1}
            onClick={event => event.stopPropagation()}
            onChange={onSelect}
        />
        <ListItemAvatar>
            {image ? (
                <Avatar src={image.content} />
            ) : (
                <Avatar>
                    <Icon>person</Icon>
                </Avatar> 
            )}
        </ListItemAvatar>
        <ListItemText primary={name} secondary={identifier} />
        <ListItemSecondaryAction className={classes.secondaryAction}>
            <Tooltip title="Edit">
                <IconButton aria-label="Edit" onClick={onEdit}>
                    <Icon>description</Icon>
                </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
                <IconButton aria-label="Delete" onClick={onDelete}>
                    <Icon>delete</Icon>
                </IconButton>
            </Tooltip>
        </ListItemSecondaryAction>
    </ListItem>
);

ProfileListItem.defaultProps = {
    checked: false
};

ProfileListItem.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    identifier: PropTypes.string.isRequired,
    image: PropTypes.object,
    checked: PropTypes.bool,
    onSelect: PropTypes.func,
    onClick: PropTypes.func,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func
};

export default withStyles(styles, { withTheme: true })(ProfileListItem);