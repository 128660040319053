import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import {  withStyles  } from '@material-ui/core/styles';

import { dateFormat } from 'helpers';
import DataBrowser from 'components/core/DataBrowser';
import ProgressMask from 'components/ProgressMask';

const columnsWithClasses = (classes, scope) => ([
    {   id: 'name', numeric: false, disablePadding: false, label: 'Name' },
    {   id: 'metadata', numeric: false, disablePadding: false, label: 'Expires', render: (column, item) => (item[column.id] && dateFormat(item[column.id].expirationDate, 'MMM D, YYYY [at] h:mm A')) },
    {   id: 'createdAt', numeric: false, disablePadding: false, label: 'Created at', render: (column, item) => dateFormat(item[column.id], 'MMM D, YYYY [at] h:mm A') }
]);

const actions = scope => ([
    { id: 'delete', label: 'Delete', icon: 'delete', handleClick: scope.handleDelete, },
]);

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        flexGrow: 1,
        width: '100%'
    }
});

class MobileProvisions extends Component {

    state = {
        open: false,
        searchCriteria: { keys: [] }
    }

    componentDidMount() {
        const { onRefresh } = this.props;

        onRefresh()
    }

    filterData = (fields, keys) => {
        keys = keys.map(key => key.toLowerCase())
        return stage => (keys.length === 0 || fields.some(field => keys.every(key => stage[field].toLowerCase().indexOf(key) > -1)))
    }

    handleNewClick = () => {
        const { onCreate } = this.props;
        let input = document.createElement('input');
        
        input.type = "file"
        input.style.display = "none"
        input.onchange = e => {        
            const { files } = e.target;
            const file = files[0];

            if (file) {
                const  reader = new FileReader();
                
                reader.onload = () => {
                    const value = {
                        name: file.name,
                        encoding: "base64",
                        mimeType: file.type,
                        content: reader.result
                    };

                    document.body.removeChild(input)
                    if (onCreate) onCreate(value)
                }
                reader.readAsDataURL(file)
            }
        }
        document.body.appendChild(input)
        input.click()
    }

    handleRequestSearch = (criteria) => {
        this.setState({
            searchCriteria: criteria
        })
    }

    handleDelete = (event, selected) => {
        const { mobileprovisions, onDelete } = this.props;
        const selection = selected.reduce((items, id) => {
            const item = mobileprovisions.find(mobileprovision => mobileprovision._id === id);
            if (item) return items.concat(item)
        }, []);

        onDelete(selection);
    }

    handleSave = (mobileprovision) => {
        const { onCreate } = this.props;

        this.handleClose();
        if (!mobileprovision._id) {
            onCreate(mobileprovision);
        }
    }

    render() {
        const { mobileprovisions, classes, inProgress } = this.props;
        const { searchCriteria } = this.state;

        return (
            <div className={classes.root}>
                <DataBrowser
                    title="Provisioning Profiles"
                    emptyText="No provisioning profile(s) found"
                    columns = {columnsWithClasses(classes, this)}
                    actions = {actions(this)}
                    data={mobileprovisions.filter(this.filterData(['name'], searchCriteria.keys))}
                    orderBy={{ property: 'createdAt', direction: 'desc' }}
                    selectOnRowClick={false}
                    onAddButtonClick={this.handleNewClick}
                    onRequestSearch={this.handleRequestSearch}
                />
                {(inProgress) ? (
                    <ProgressMask/>
                ) : null}
            </div>
        )
    }
}

MobileProvisions.propTypes = {
    classes: PropTypes.object.isRequired,
    mobileprovisions: PropTypes.arrayOf(PropTypes.object).isRequired,
    onRefresh: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
}

export default withRouter(withStyles(styles,  { withTheme: true })(MobileProvisions))