import axios from 'axios';

import * as Types from 'constants/ActionTypes';

const request = axios.create({
    baseURL: 'https://api.bulkappresign.loopthy.com/auth',
    // baseURL: 'http://localhost:8081/auth',
    withCredentials: true,
    // timeout: 1000
});

function requestLogin() {
    return {
        type: Types.REQUEST_LOGIN
    }
};

function responseLoginSuccess(user) {
    return {
        type: Types.RESPONSE_LOGIN_SUCCESS,
        user,
        receivedAt: Date.now()
    };
};

function responseLoginFailure(error) {
    return {
        type: Types.RESPONSE_LOGIN_FAILURE,
        error,
        receivedAt: Date.now()
    };
};

function requestLogout() {
    return {
        type: Types.REQUEST_LOGOUT
    }
};

function responseLogoutSuccess() {
    return {
        type: Types.RESPONSE_LOGOUT_SUCCESS,
        receivedAt: Date.now()
    };
};

function responseLogoutFailure(error) {
    return {
        type: Types.RESPONSE_LOGOUT_FAILURE,
        error,
        receivedAt: Date.now()
    };
};

function requestRegister() {
    return {
        type: Types.REQUEST_REGISTER
    }
};

function responseRegisterSuccess(user) {
    return {
        type: Types.RESPONSE_REGISTER_SUCCESS,
        user,
        receivedAt: Date.now()
    };
};

function responseRegisterFailure(error) {
    return {
        type: Types.RESPONSE_REGISTER_FAILURE,
        error,
        receivedAt: Date.now()
    };
};

function requestAuth() {
    return {
        type: Types.REQUEST_AUTH
    }
};

function responseAuthSuccess(user) {
    return {
        type: Types.RESPONSE_AUTH_SUCCESS,
        user,
        receivedAt: Date.now()
    };
};

function responseAuthFailure(error) {
    return {
        type: Types.RESPONSE_AUTH_FAILURE,
        error,
        receivedAt: Date.now()
    };
};

function requestVerifyEmail() {
    return {
        type: Types.REQUEST_VERIFY_EMAIL
    }
};

function responseVerifyEmailSuccess(user) {
    return {
        type: Types.RESPONSE_VERIFY_EMAIL_SUCCESS,
        user,
        receivedAt: Date.now()
    };
};

function responseVerifyEmailFailure(error) {
    return {
        type: Types.RESPONSE_VERIFY_EMAIL_FAILURE,
        error,
        receivedAt: Date.now()
    };
};

function requestResend() {
    return {
        type: Types.REQUEST_RESEND
    }
};

function responseResendSuccess(user) {
    return {
        type: Types.RESPONSE_RESEND_SUCCESS,
        user,
        receivedAt: Date.now()
    };
};

function responseResendFailure(error) {
    return {
        type: Types.RESPONSE_RESEND_FAILURE,
        error,
        receivedAt: Date.now()
    };
};

function requestForgot() {
    return {
        type: Types.REQUEST_FORGOT_PWD
    }
};

function responseForgotSuccess(user) {
    return {
        type: Types.RESPONSE_FORGOT_PWD_SUCCESS,
        user, 
        receivedAt: Date.now()
    };
};

function responseForgotFailure(error) {
    return {
        type: Types.RESPONSE_FORGOT_PWD_FAILURE,
        error,
        receivedAt: Date.now()
    };
};

function requestReset() {
    return {
        type: Types.REQUEST_RESET_PWD
    }
};

function responseResetSuccess(user) {
    return {
        type: Types.RESPONSE_RESET_PWD_SUCCESS,
        user,
        receivedAt: Date.now()
    };
};

function responseResetFailure(error) {
    return {
        type: Types.RESPONSE_RESET_PWD_FAILURE,
        error,
        receivedAt: Date.now()
    };
};

// Do not use catch, because that will also catch
// any errors in the dispatch and resulting render,
// causing a loop of 'Unexpected batch number' errors.
// https://github.com/facebook/react/issues/6895

export function login(email, password, history) {
    return function(dispatch) {
        dispatch(requestLogin());
        return request.post('/login', {
            email,
            password
        })
        .then(response => {
            const { data } = response

            if (data.error) {
                dispatch(responseLoginFailure(data.error));
            }
            else {
                dispatch(responseLoginSuccess(data.result));
                history.push('/project');  
            }
        }, e => {
            const response = e.response;

            dispatch(responseLoginFailure({
                message: response.statusText
            }))
        })
    }
};

export function logout(history) {
    return function(dispatch) {
        dispatch(requestLogout());
        return request.post('/logout')
        .then(response => {
            const { data } = response

            if (data.error) {
                dispatch(responseLogoutFailure(data.error));
            }
            else {
                dispatch(responseLogoutSuccess(data.result));
                // history.push('/');  
            }
        }, e => dispatch(responseLogoutFailure({
            message: e.message
        })))
    }
};

export function register(firstName, lastName, email, password, history) {
    return function(dispatch) {
        dispatch(requestRegister());
        return request.post('/register', {
            firstName,
            lastName,
            email,
            password
        })
        .then(response => {
            const { data } = response

            if (data.error) {
                dispatch(responseRegisterFailure(data.error));
            }
            else {
                dispatch(responseRegisterSuccess(data.result));
                history.push('/verify');  
            }
        }, e => dispatch(responseRegisterFailure({
            message: e.message
        })))
    }
};

export function authenticate(history) {
    return function(dispatch) {
        dispatch(requestAuth());
        return request.get('/')
        .then(response => {
            const { data } = response

            if (data.error) {
                dispatch(responseAuthFailure(data.error));
            }
            else {
                dispatch(responseAuthSuccess(data.result));
            }
        }, e => dispatch(responseAuthFailure({
            message: e.message
        })))
    }
};

export function resendCode(email) {
    return function(dispatch) {
        dispatch(requestResend());
        return request.post('/resend-code', {
            email
        })
        .then(response => {
            const { data } = response

            if (data.error) {
                dispatch(responseResendFailure(data.error));
            }
            else {
                dispatch(responseResendSuccess(data.result));
            }
        }, e => dispatch(responseResendFailure({
            message: e.message
        })))
    }
};

export function verifyEmail(email, code, history) {
    return function(dispatch) {
        dispatch(requestVerifyEmail());
        return request.post('/verify-email', {
            email,
            code
        })
        .then(response => {
            const { data } = response

            if (data.error) {
                dispatch(responseVerifyEmailFailure(data.error));
            }
            else {
                dispatch(responseVerifyEmailSuccess(data.result));
                history.push('/project');  
            }
        }, e => dispatch(responseVerifyEmailFailure({
            message: e.message
        })))
    }
};

export function forgotPassword(email, history) {
    return function(dispatch) {
        dispatch(requestForgot());
        return request.post('/forgot-pwd', {
            email
        })
        .then(response => {
            const { data } = response

            if (data.error) {
                dispatch(responseForgotFailure(data.error));
            }
            else {
                dispatch(responseForgotSuccess(data.result));
                history.push('/reset');  
            }
        }, e => dispatch(responseForgotFailure({
            message: e.message
        })))
    }
};

export function resetPassword(email, code, password, history) {
    return function(dispatch) {
        dispatch(requestReset());
        return request.post('/reset-pwd', {
            email, code, password
        })
        .then(response => {
            const { data } = response

            if (data.error) {
                dispatch(responseResetFailure(data.error));
            }
            else {
                dispatch(responseResetSuccess(data.result));
                history.push('/project');  
            }
        }, e => dispatch(responseResetFailure({
            message: e.message
        })))
    }
};