import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {  withStyles  } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const styles = theme => ({
    flex: {
        flex: 1
    },
    dialog: {
        margin: 'auto',
        minWidth: 275,
    },
    dialogActions: {
        paddingLeft: theme.spacing.unit * 1.5,
        paddingRight: theme.spacing.unit * 1.5
    },
    button: {
        margin: theme.spacing.unit
    }
});

class ProfileForm extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { classes, open, onConfirm, onClose, title, description } = this.props;
        
        return (
            <Dialog
                className={classes.dialog}
                open={open}
                onClose={onClose}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description">
                <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-dialog-description">
                        {description}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button color="primary" onClick={onClose}>
                        No
                    </Button>
                    <Button color="primary" onClick={onConfirm}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
};

ProfileForm.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    description: PropTypes.string
};

export default withStyles(styles)(ProfileForm);