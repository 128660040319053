import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {  Route, Redirect  } from 'react-router-dom';

const AuthRoute = ({component: Component, ...args}) => {
    const { isAuth } = args;
    
    return (
        <Route {...args} render={    
            props => isAuth ? (
                <Component {...Object.assign(props, args)} />
            ) : (
                <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
            )
        }/>
    );
};

const mapStateToProps = (state) => {
    return {
        isAuth: !!state.user.data
    }
};
  
AuthRoute.propTypes = {
    isAuth: PropTypes.bool.isRequired,
};

export default connect(
	mapStateToProps
)(AuthRoute);