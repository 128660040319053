import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import {  withStyles  } from '@material-ui/core/styles';

import Icon  from '@material-ui/core/Icon';
import Avatar  from '@material-ui/core/Avatar';
import green from '@material-ui/core/colors/green';

import { dateFormat } from 'helpers';
import DataBrowser from 'components/core/DataBrowser';
import StageTasks from 'components/StageTasks';
import BuildAssets from 'components/BuildAssets';
import StageForm from 'components/StageForm';
import ProgressMask from 'components/ProgressMask';
import { Tabs, Tab, AppBar, Button, Slide } from '@material-ui/core';

const stageColumnsWithClasses = (classes, scope) => ([
    {   id: 'icon', width: 64, numeric: false, disablePadding: false, label: 'Icon', render: (column, item) => {
        return (
            <Avatar></Avatar> 
        )
    } },
    {   id: 'name', numeric: false, disablePadding: false, label: 'Name' },
    {   id: 'tasks', numeric: false, disablePadding: false, label: 'Tasks', render: (column, item) => (item[column.id].length) },
    {   id: 'createdAt', numeric: false, disablePadding: false, label: 'Created at', render: (column, item) => dateFormat(item[column.id], 'MMM D, YYYY [at] h:mm A') },
    {   id: 'build', numeric: false, disablePadding: false, label: 'Latest build', render: (column, item) => item[column.id] && (
        <Button className={classes[item[column.id].status]} onClick={event => scope.handleStageBuildClick(event, item)}>{item[column.id].name}</Button>
    ) }
]);

const stageActions = (scope) => ([
    { id: 'edit', label: 'Edit', icon: 'description', handleClick: scope.handleStageEdit, disabled: selected => (selected.length > 1) }, 
    // { id: 'clone', label: 'Clone', icon: 'file_copy', handleClick: scope.handleStageClone, disabled: selected => (selected.length > 1) }, 
    { id: 'delete', label: 'Delete', icon: 'delete', handleClick: scope.handleStageDelete, },
    { id: 'build', label: 'Build', icon: 'play_circle_filled', handleClick: scope.handleStageBuild }
]);

const buildColumnsWithClasses = classes => ([
    {   id: 'status', width: 56, numeric: false, disablePadding: false, label: 'Status', render: (column, item) => {
        const icons = { succeed: 'check_circle_outline',  failed: 'highlight_off' }
        return (
            <Icon className={classes[item[column.id]]}>{icons[item[column.id]]}</Icon>
        )
    } },
    {   id: 'name', numeric: false, disablePadding: false, label: 'Name' },
    {   id: 'createdAt', numeric: false, disablePadding: false, label: 'Builded at', render: (column, item) => dateFormat(item[column.id], 'MMM D, YYYY [at] h:mm A') }
]);

const buildActions = (scope) => ([
    { id: 'delete', label: 'Delete', icon: 'delete', handleClick: scope.handleBuildDelete, },
]);

const drawerWidth = 850;

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        width: '100%',
        flexGrow: 1
    },
    spacer: {
        flexGrow: 1
    },
    content: {
        display:'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        flexGrow: 1
    },
    avatar: {
        marginTop: theme.spacing.unit / 2 + 2,
        marginBottom: theme.spacing.unit / 2 + 2, 
    },
    succeed: {
        color: green[600],
    },
    failed: {
        color: theme.palette.error.dark,
    },
    details: {
        display:'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        width: drawerWidth,
        borderLeft: `1px solid ${theme.palette.divider}`
    },
    toolbar: {
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    items: {
        flexGrow: 1,
        overflowY: 'scroll',
        padding: theme.spacing.unit * 2,
        backgroundColor: theme.palette.background.default
    },
    appBar: {
        zIndex: 0
    }
});

class Project extends Component {

    state = {
        tabIndex: 0,
        open: false,
        currentStage: null,
        currentBuild: null,
        stage: null,
        stagesCriteria: { keys:[] },
        buildsCriteria: { keys:[] }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { stages } = nextProps;
        const { currentStage } = prevState;
        const newStage = (currentStage) ? stages.find(stage => stage._id === currentStage._id) : null;

        if (newStage && currentStage != newStage) {
          return {  currentStage: newStage  }
        }
        return null;
    }

    componentDidMount() {
        const me = this;
        const { match, onProjectRead, onStagesRefresh, onBuildsRefresh } = me.props;
        const { params } = match;

        if (params) {
            const { id, prop, item } = params;

            onProjectRead(id)
            onStagesRefresh()
            onBuildsRefresh()
            .then(() => {
                const { builds, onBuildAssetsRefresh } = me.props;
                const currentBuild = builds.find(b => b._id === item);

                me.setState({ currentBuild })
                if (currentBuild) {
                    onBuildAssetsRefresh(currentBuild.assets)
                }
            })
            me.setState({ tabIndex: (prop === 'stage') ? 0 : 1 })
        }
    }

    filterData = (fields, keys) => {
        keys = keys.map(key => key.toLowerCase())
        return stage => (keys.length === 0 || fields.some(field => keys.every(key => stage[field].toLowerCase().indexOf(key) > -1)))
    }

    handleTabChange = (event, tabIndex) => {
        const { currentStage, currentBuild } = this.state;

        this.setState({ 
            tabIndex, 
            currentStage: (tabIndex === 1) ? null : currentStage, 
            currentBuild: (tabIndex === 0) ? null : currentBuild 
        })
    } 

    handleStageRowClick = (event, id) => {
        const { stages } = this.props;
        const { currentStage } = this.state;

        this.setState({
            currentStage: (currentStage && currentStage._id === id) ? null : stages.find(stage => stage._id === id)
        })
    }

    handleNewClick = () => {
        return new Promise(resolve => this.setState({ 
            open: true
        }, resolve))
    }

    handleStageEdit = (event, selected) => {
        const { stages } = this.props;
        const id = selected[0];
        const stage = stages.find(stage => stage._id === id);

        if (stage) {
            this.setState({
                open: true,
                stage
            })
        }
    }

    handleStageClone = (event, selected) => {
        console.log(`handleStageClone ${selected}`)
    }

    handleStageDelete = (event, selected) => {
        const me = this;
        const { stages, onStageDelete } = this.props;
        const { currentStage } = this.state;
        const selection = selected.reduce((items, id) => {
            const item = stages.find(stage => stage._id === id);
            if (item) return items.concat(item)
        }, []);

        onStageDelete(selection)
        .then(() => {
            me.setState({ currentStage: selected.indexOf(currentStage._id) === -1 ? currentStage : null })   
        })
    }

    handleStageBuild = (event, selected) => {
        const { stages, onStageBuild } = this.props;
        const selection = selected.reduce((items, id) => {
            const item = stages.find(stage => stage._id === id);
            if (item) return items.concat(item)
        }, []);

        onStageBuild(selection)
    }

    handleStageBuildClick = (event, item) => {
        const { builds } = this.props;

        event.stopPropagation()
        if (item.build) {
            const currentBuild = builds.find(b => b._id === item.build._id);

            if (currentBuild){
                const  { onBuildAssetsRefresh } = this.props;

                this.setState({
                    tabIndex: 1,
                    currentBuild    
                })
                onBuildAssetsRefresh(currentBuild.assets)
            }
        }
    }

    handleStageFormClose = () => {
        this.setState({
            open: false,
            stage: null
        })
    }

    handleStageSave = stage => {
        const { project, onStageCreate, onStageUpdate } = this.props;

        this.handleStageFormClose()
        stage.project_id = project._id
        if (!stage._id) {
            onStageCreate(stage)
        }
        else {
            onStageUpdate(stage)
        }
    }

    handleStageTasksUpdate = currentStage => {
        const { onStageUpdate } = this.props;  
        
        return onStageUpdate(currentStage)
    }

    handleStageRequestSearch = (criteria) => {
        this.setState({
            stagesCriteria: criteria
        })
    }

    handleBuildRowClick = (event, id) => {
        const { builds } = this.props;
        const { currentBuild } = this.state;
        const build = (currentBuild && currentBuild._id === id) ? null : builds.find(build => build._id === id);

        this.setState({
            currentBuild: build
        })
        if (build) {
            const  { onBuildAssetsRefresh } = this.props;

            onBuildAssetsRefresh(build.assets)
        }
    }

    handleBuildDelete = (event, selected) => {
        const me = this;
        const { builds, onBuildDelete } = this.props;
        const { currentBuild } = this.state;
        const selection = selected.reduce((items, id) => {
            const item = builds.find(build => build._id === id);
            if (item) return items.concat(item)
        }, []);

        onBuildDelete(selection)
        .then(() => {
            me.setState({ currentBuild: (currentBuild && selected.indexOf(currentBuild._id) === -1) ? currentBuild : null })   
        })
    }

    handleBuildAssetsDownload = (assets) => {
        const { onBuildAssetsDownload } = this.props;

        onBuildAssetsDownload(assets)
    }

    handleBuildRequestSearch = (criteria) => {
        this.setState({
            buildsCriteria: criteria
        })
    }

    render() {
        const { classes, inProgress, project, assets, stages, builds } = this.props;
        const { tabIndex, open, stage, currentStage, currentBuild, stagesCriteria, buildsCriteria } = this.state;

        return (
            <div className={classes.root}>
                <AppBar className={classes.appBar} position="static">
                    <Tabs value={tabIndex} onChange={this.handleTabChange}>
                        <Tab label="Stages" />
                        <Tab label="Builds" />
                    </Tabs>
                </AppBar>
                {(tabIndex === 0 && project) && (
                    <div className={classes.content}>
                        <DataBrowser
                            title={project.name}
                            emptyText="No stage(s) found"
                            columns = {stageColumnsWithClasses(classes, this)}
                            actions = {stageActions(this)}
                            data={stages.filter(this.filterData(['name'], stagesCriteria.keys))}
                            orderBy={{ property: 'createdAt', direction: 'desc' }}
                            selectOnRowClick={false}
                            onAddButtonClick={this.handleNewClick}
                            onRequestSearch={this.handleStageRequestSearch}
                            onRowClick={this.handleStageRowClick}
                        />
                        {(currentStage) && (
                            <Slide direction="left" in={true} mountOnEnter unmountOnExit>
                                <StageTasks
                                    project={project}
                                    stage={currentStage}
                                    onUpdate={this.handleStageTasksUpdate}
                                />
                            </Slide>
                        )}
                    </div>
                )}
                {(tabIndex === 1 && project) && (
                    <div className={classes.content}>
                        <DataBrowser
                            title={project.name}
                            emptyText="No build(s) found"
                            columns = {buildColumnsWithClasses(classes, this)}
                            actions = {buildActions(this)}
                            data={builds.filter(this.filterData(['name'], buildsCriteria.keys))}
                            orderBy={{ property: 'createdAt', direction: 'desc' }}
                            selectOnRowClick={false}
                            rowId={(currentBuild) ? currentBuild._id : null}
                            onRequestSearch={this.handleBuildRequestSearch}
                            onRowClick={this.handleBuildRowClick}
                        />
                        {(currentBuild) && (
                            <Slide direction="left" in={true} mountOnEnter unmountOnExit>
                                <BuildAssets
                                    project={project}
                                    build={currentBuild}
                                    assets={assets}
                                    onDownload={this.handleBuildAssetsDownload}
                                />
                            </Slide>
                        )}
                    </div>
                )}
                {(open === true) && (
                    <StageForm 
                        stage={stage}
                        open={open} 
                        onSave={this.handleStageSave}
                        onClose={this.handleStageFormClose}
                    />
                )}
                {(inProgress) ? (
                    <ProgressMask/>
                ) : null}
            </div>
        )
    }
}

Project.defaultProps = {
    projectProp: 'stage',
    stages: [],
    builds: []
}

Project.propTypes = {
    classes: PropTypes.object.isRequired,
    project: PropTypes.shape({
        _id: PropTypes.string.isRequired
    }),
    projectProp: PropTypes.string,
    stages: PropTypes.arrayOf(PropTypes.object).isRequired,
    builds: PropTypes.arrayOf(PropTypes.object).isRequired,
    assets: PropTypes.arrayOf(PropTypes.object).isRequired,
    onProjectRead: PropTypes.func.isRequired,
    onStagesRefresh: PropTypes.func.isRequired,
    onStageCreate: PropTypes.func.isRequired,
    onStageUpdate: PropTypes.func.isRequired,
    onStageDelete: PropTypes.func.isRequired,
    onStageBuild: PropTypes.func.isRequired,
    onBuildsRefresh: PropTypes.func.isRequired,
    onBuildDelete: PropTypes.func.isRequired,
    onBuildAssetsRefresh: PropTypes.func.isRequired,
    onBuildAssetsDownload: PropTypes.func.isRequired
}

export default withRouter(withStyles(styles)(Project))