import { connect } from 'react-redux';
import { 
    fetchMobileProvisions, fetchCertificates, createMobileProvision 
} from 'actions';

import SigningTaskForm from 'components/SigningTaskForm';

const mapStateToProps = state => ({
    inProgress: (state.mobileprovisions.inProgress || state.certificates.inProgress),
    mobileprovisions: state.mobileprovisions.data,
    certificates: state.certificates.data,
})

const mapDispatchToProps = dispatch => {
    return ({
        onShow: () => Promise.all([
            dispatch(fetchMobileProvisions()),
            dispatch(fetchCertificates())
        ]),
        onMobileProvisionCreate: mobileprovision => dispatch(createMobileProvision(mobileprovision))
    })
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SigningTaskForm)