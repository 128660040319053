import { connect } from 'react-redux';
import { fetchArchives, deleteArchive, downloadArchives } from 'actions';
import ArchiveList from 'components/ArchiveList';

const sorter = (prop) => (a, b) => {
    if (a[prop] < b[prop]) return 1
    else if (a[prop] > b[prop]) return -1
    else return 0
}

const mapStateToProps = state => ({
    archives: state.archives.data.sort(sorter('createdAt'))
});

const mapDispatchToProps = dispatch => ({
    onRefresh: () => dispatch(fetchArchives()),
    onDelete: archives => dispatch(deleteArchive(archives)),
    onDownload: archives => dispatch(downloadArchives(archives))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ArchiveList);