import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {  withStyles  } from '@material-ui/core/styles';

import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';
import Icon from '@material-ui/core/Icon';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
    listItem: {
        '&:hover $secondaryAction': {
            visibility: 'inherit'
        }
    },
    secondaryAction: {
        visibility: 'hidden',
        paddingRight: 20
    }
});

const ArchiveListItem = ({ classes, className, project, createdAt, checked, onSelect, onClick, onDownload, onInfo, onDelete }) => (
    <ListItem classes={{ container: classes.listItem }} className={className} button onClick={onClick}>
        <Checkbox
            checked={checked}
            tabIndex={-1}
            onClick={event => event.stopPropagation()}
            onChange={onSelect}
        />
        <ListItemAvatar>
            <Avatar>
                <Icon>file_copy</Icon>
            </Avatar>
        </ListItemAvatar>
        <ListItemText primary={project.name} secondary={(new Date(createdAt)).toString()} />
        <ListItemSecondaryAction className={classes.secondaryAction}>
            <Tooltip title="Download">
                <IconButton aria-label="Download" onClick={onDownload}>
                    <Icon>get_app</Icon>
                </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
                <IconButton aria-label="Delete" onClick={onDelete}>
                    <Icon>delete</Icon>
                </IconButton>
            </Tooltip>
            <Tooltip title="Info">
                <IconButton aria-label="Info" onClick={onInfo}>
                    <Icon>info</Icon>
                </IconButton>
            </Tooltip>
        </ListItemSecondaryAction>
    </ListItem>
);

ArchiveListItem.defaultProps = {
    checked: false
};

ArchiveListItem.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    className: PropTypes.string,
    project: PropTypes.object.isRequired,
    createdAt: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    onDownload: PropTypes.func.isRequired,
    onInfo: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
};

export default withStyles(styles, { withTheme: true })(ArchiveListItem);